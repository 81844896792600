import styled from "@emotion/styled";

export const ProfileBoxContainer = styled("div")`
  //position:relative;
  //display: block;
 
  width:249px;
  height:195px;
  top:-60px;
  left:-24px;

`;
export const ProfileBg = styled("div")`
  position:relative;
  display:block;
  background-image: url("${(props => props.theme.themePath)}/large/user-details/user-details-bg.png");
  background-size: 82px 80px;
  width:82px;
  height:80px;
  opacity:1;
  z-index:0;
`;

export const ProfileBlock = styled("div")`
position:relative;
top:-104px;
left:49px;
display: flex;
flex-direction:column;
opacity:1.0;
align-items:center;
z-index:10;
font-size:14px;
width:82px;
visibility: hidden;
`;

export const ButtonDiv = styled("div")`
z-index:0;
`
export const ProfileImg = styled("img")`
top:40px;
left:-40px;
position:relative;
width:56px;
height:56px;
border-radius:28px;
z-index:100;
`
export const ProfileLink = styled("div")`
width:66px;
height:66px;
display:block;
`

