import React, { useState, useRef, useReducer, useCallback, useMemo} from "react";
//import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
//import { css, keyframes } from "@emotion/react";
import * as THREE from "three";
//import { Vector3 } from 'three'

import {  Canvas, extend, useThree, useFrame, useRender } from '@react-three/fiber';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import LinearImageAnimationElement from "../LinearImageAnimationElement";
import { useMyCombinatorData } from "../MyCombinator/useMyCombinatorData";
//import { SuspenseImg } from "../SuspenseImage";
//import * as dat from 'dat.gui'
import "./thePortalAnimation.css";
//import { appViewState, appDebugState } from "../../config/state/uiState";
import {
  PortalContainer,
  Portal,
  PortalBg,
  ThePortalLeft,
  ThePortalRight,
  ThePortalBackground,
  ThePortalBubble1,
  ThePortalBubble2,
  ThePortalTopExpander,
  ThePortalBottomExpander,
  ThePortalForeground,
  ThePortalBgImg,
  ThePortalCenter,
  ThePortalCover,
  PortalSwitch,
  Drip,
  Steam,
} from "./ThePortalStyle.js";

import { getPortalOffset } from "../../utils/PortalOffset";
//import { fadeOut } from "../../uix/animation/keyframes";
import { topExpanderConfig } from "../../config/data/topExpanderConfig";
import { bottomExpanderConfig } from "../../config/data/bottomExpanderConfig";
import { useWindowDimensions } from "../../app/WindowDimensionsProvider";
import { theme } from "../../themes/greyscaleTheme";
import { hudState, hudData} from "../../config/state/uiState";
import TheHud from "../TheHud";
//import { DocumentReference } from "firebase/firestore";

export default function ThePortal(props) {
  console.log('load The Portal');
  
  //Fetch data for the ThreeJS scene
  const MyCombinatorReducer = useCallback((state, action) => {
    switch (action.type) {
    case "STACK_MYCOMBINATOR":
      return { ...state, combinators: state.combinators.concat(action.combinators) };
    case "FETCHING_MYCOMBINATOR":
      return { ...state, fetching: action.fetching };
    default:
      return state;
    }
  },[]);

  const [combinatorData, combinatorDispatch] = useReducer(MyCombinatorReducer, {
    combinators: [],
    fetching: true,
  });
    
  useMyCombinatorData(combinatorDispatch);

  const { width, height } = useWindowDimensions();
  const { canvasWidth, canvasHeight, centerWidth } = getPortalOffset(
    width,
    height
  );
  const { size } = props;
  const sizes = { width: canvasWidth, height: canvasHeight };
  const expander = useRef("expand");
  const [expandPortal, setExpandPortal] = useState({ value: "expand" });
  const switchOff = useRef({ current: false });
  const transforming = useRef({ current: false });
  const boxMesh = useRef(null);
  const portalSwitch = useRef();
  const centerUI = useRef();
  const topUI = useRef();
  const bottomUI = useRef();
  const portalBackground = useRef();
  const portalCanvas = useRef();
  const portalCover = useRef();
  const portalBG = useRef();
  const portalFG = useRef();
  const bubble1 = useRef();
  const bubble2 = useRef();
  const currentWidth = useRef();
  const dripRef = useRef();
  const steamRef = useRef();
  const path = theme.themePath + "/large/three-portal/";
  const starPositions = useRef([]); 
  const stars = useRef([]);
  const intervalUp = useRef();
  const intervalDown = useRef();
  //const pointerDiv = useRef();
  const [pointerLayerStyle, setPointerLayerStyle] = useState({});
  const [hudStateValue, setHudStateValue] = useRecoilState(hudState);
  const [hudDataValue, setHudDataValue] = useRecoilState(hudData);
  const hudDataRef = useRef();


  const handleContract = useCallback(() => {
    console.log("handleContract Portal");
    try {
      portalBackground.current.style.opacity = 0;
      portalBackground.current.style.zIndex = 0;
      intervalUp.current = setInterval(() => {
        transforming.current = true;

        currentWidth.current = centerUI.current.style.width.substr(
          0,
          centerUI.current.style.width.length - 2
        );

        let modifier = Number(centerWidth * 0.1);

        let delta = Number(currentWidth.current) - modifier;

        if (currentWidth.current < modifier) {
          delta = currentWidth.current % modifier;
        }

        if (currentWidth.current > modifier) {
          steamRef.current.style.visibility = "visible";
          dripRef.current.style.visibility = "hidden";
          centerUI.current.style.width = delta + "px";
        } else {
          steamRef.current.style.visibility = "hidden";
          dripRef.current.style.visibility = "visible";
          
          centerUI.current.style.width = "0px";

          transforming.current = false;
          clearInterval(intervalUp.current);
          return;
        }
      }, 30);
    } catch (e) {
      console.log("handleContract: ThePortal");
    }
  },[centerWidth]);

  const handleExpand = useCallback(() => {
    console.log("handleExpand Portal");
    try {
      intervalDown.current = setInterval(() => {
        transforming.current = true;
        currentWidth.current = Number(
          centerUI.current.style.width.substr(
            0,
            centerUI.current.style.width.length - 2
          )
        );

        let modifier = Number(centerWidth * 0.1);

        let delta = Number(currentWidth.current) + modifier;

        if (currentWidth.current < Number(centerWidth)) {
          steamRef.current.style.visibility = "visible";
          dripRef.current.style.visibility = "hidden";
          centerUI.current.style.width = delta + "px";
        } else {
          steamRef.current.style.visibility = "hidden";
          dripRef.current.style.visibility = "visible";
          centerUI.current.style.width = Number(centerWidth) + "px";
          portalBackground.current.style.opacity = 1;
          portalBackground.current.style.zIndex = 500;
          portalBackground.current.style.height = sizes.height;
          portalBackground.current.style.width = sizes.width;
          transforming.current = false;
          clearInterval(intervalDown.current);
          return;
        }
      }, 30);
    } catch (e) {
      console.log("handleExpand: ThePortal");
    }
  },[centerWidth,sizes.height,sizes.width]);
    
  const togglePortal = useCallback( (portalAction) => {
    switch (portalAction) {
      case "contract":
        handleContract();

        break;
      case "expand":
        handleExpand();
        break;
      default:
        break;
    }
  } ,[handleContract, handleExpand]);

  const toggleSwitch = useCallback(() => {
    if (transforming.current === true) {
      return;
    }
    clearInterval(intervalUp.current);
    clearInterval(intervalDown.current);

    //if we want to switch off
    if (switchOff.current === false) {
      //set img to show off position
      portalSwitch.current.src =
        process.env.PUBLIC_URL + theme.themePath + theme.media.PortalImages.switch_off;
      //contract portal
      //setExpandPortal("contract");
      expander.current = "contract";
      togglePortal("contract");
      //setHudStateValue({hudVisible:false});
      //switchOff = false
      switchOff.current = true;
    } else {
      portalSwitch.current.src =
        process.env.PUBLIC_URL + theme.themePath + theme.media.PortalImages.switch_on;

      //setExpandPortal("expand");
      expander.current = "expand";
      togglePortal("expand");
      //setHudStateValue({hudVisible:true});
      switchOff.current = false;
    }
  },[togglePortal]);


const initStars = useCallback(() => {
  if(combinatorData.fetching === false){
    if((combinatorData.combinators[0].hits) && (stars.current.length < combinatorData.combinators[0].hits.length)){
        for (let i=0; i < combinatorData.combinators[0].hits.length; i++){
          stars.current.push(combinatorData.combinators[0].hits[i]);
        }
    }
  }
  console.log("We have " + stars.current.length + " stars processed.");
},[combinatorData.combinators,combinatorData.fetching]);



useMemo( () =>{ initStars()},[initStars]);


extend({ OrbitControls });


const CameraControls = () => {
  console.log('camera controls');
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement }
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={false}
      enableZoom={true}
    />
  );
};

const MyCube = () => {
  console.log('my cube');
  const { scene } = useThree();
  
  //conditional to prevent multiple objects 
  if(boxMesh.current === null){  
  /* SPINNING CUBE */
    const textureLoader = new THREE.TextureLoader();
    textureLoader.setPath(path);
    var texture0 = textureLoader.load("wood-js.png");
    var texture1 = textureLoader.load("wood-react.png");
    var texture2 = textureLoader.load("wood-html5.png");
    var texture3 = textureLoader.load("wood-css3.png");
    var texture4 = textureLoader.load("wood-logo.png");
    var texture5 = textureLoader.load("wood-photo.png");
    
    var materials = [
      new THREE.MeshBasicMaterial({ map: texture0 }),
      new THREE.MeshBasicMaterial({ map: texture1 }),
      new THREE.MeshBasicMaterial({ map: texture2 }),
      new THREE.MeshBasicMaterial({ map: texture3 }),
      new THREE.MeshBasicMaterial({ map: texture4 }),
      new THREE.MeshBasicMaterial({ map: texture5 }),
    ];

    const geometry = new THREE.BoxGeometry(1.4, 1.4, 1.4);
    boxMesh.current = new THREE.Mesh(geometry, materials);
    //Add the Spinning Cube to the Scene
    scene.add(boxMesh.current);
  
  }
  
  useFrame(({clock})=>{
    const a = clock.getElapsedTime();
    boxMesh.current.rotation.x = 0.4 * a;
    boxMesh.current.rotation.y = 0.4 * a;
    boxMesh.current.rotation.z = 0.4 * a;
  });

  return null;
}

// Loads the skybox texture and applies it to the scene.
const SkyBox = () => {
  console.log('skybox');
  const { scene } = useThree();
  const loader = new THREE.CubeTextureLoader();
  // The CubeTextureLoader load method takes an array of urls representing all 6 sides of the cube.
  loader.setPath(path);
        const texture = loader.load([
          "right.png",
          "left.png",
          "back.png",
          "front.png",
          "top.png",
          "bottom.png",
        ]);

  // Set the scene background property to the resulting texture.
  scene.background = texture;
  return null;
}

const StarObj =  ({id,index,url,title,createdAt,author,points}) => {
  const ref = useRef();
  useFrame(() => {
    ref.current.rotation.x = ref.current.rotation.y += 0.01;
  })

  const [x,y,z] = Array(3);
  if(typeof starPositions.current[index] === "undefined"){
    starPositions.current[index] = [x, y, z].fill().map(() => THREE.MathUtils.randFloatSpread(150));
  }

  return (
    <mesh
      ref={ref}
      onClick={e => {
        console.log('click', e);
        e.object.material.color.r = 1;
        e.object.material.color.g = 1;
        e.object.material.color.b = 255;        
        

        
        //setHudDataValue("test");
        }}
      onPointerOver={(e) => {
        e.object.material.color.r = 1;
        e.object.material.color.g = 255;
        e.object.material.color.b = 1;
        e.stopPropagation();
        hudDataRef.current = title;
        const top = e.clientY - 5;
        const left = e.clientX + 5;
        // setPointerLayerStyle({
        //   top: `${top}px`,
        //   left: `${left}px`,
        //   width:'200px',
        //   height:'75px',
        //   backgroundColor:'#ffcc33',
        //   display:'block',
        //   visibility: 'visible',
        //   position: 'fixed',
        //   zIndex: 1,
        // });
        portalCanvas.current.style.cursor = "pointer";
        //setHudDataValue(hudDataRef.current);
      }}
      onPointerLeave={(e) => {    
        console.log('pointerLeave'); 
        setPointerLayerStyle({
          top: `0px`,
          left: `0px`,
          width:'0px',
          height:'0px',
          backgroundColor:'#ffcc33',
          display:'none',
          visibility:'hidden'
        });
        hudDataRef.current = "";
        portalCanvas.current.style.cursor = "crosshair";
        //setHudDataValue(hudDataRef.current);
      }}
      onPointerMissed={(e) => {
        console.log('pointerMissed'); 
        portalCanvas.current.style.cursor = "crosshair";

      }}
      position={[starPositions.current[index][0],starPositions.current[index][1],starPositions.current[index][2]]}>
      <boxGeometry attach="geometry" args={[1, 1, 1]} />
      <meshBasicMaterial attach="material" color="#990000" />
    </mesh>
  )
}

  return (
    <div>
      <PortalContainer name="PortalContainer">
          <div>
            
            <Drip name="drip" ref={dripRef}>
            <img alt="drip" src={theme.themePath + theme.media.MiscImages.drip} />
            </Drip>
  
            <Steam ref={steamRef} name="steam">
              <img alt="drip" src={theme.themePath + theme.media.MiscImages.steam} />
            </Steam>
  
            <PortalSwitch
              name="portalSwitch"
              ref={portalSwitch}
              onClick={toggleSwitch}
            />
            
            <PortalBg ref={portalBG} name="portalBg">
              <ThePortalBubble1
                name="thePortalBubble1"
                ref={bubble1}
              ></ThePortalBubble1>
              <ThePortalBubble2
                name="thePortalBubble2"
                ref={bubble2}
              ></ThePortalBubble2>
              <ThePortalBgImg name="thePortalBgImg" />
            </PortalBg>
            
            <Portal ref={portalFG} name="thePortal">
              <ThePortalForeground name="thePortalForeground">
                <ThePortalLeft name="thePortalLeft" />
                <ThePortalCenter
                  name="thePortalCenter"
                  ref={centerUI}
                  style={{ width: "0px" }}
                >
                  <ThePortalTopExpander name="thePortalExpanderTop" ref={topUI}>
                    <LinearImageAnimationElement
                      id={`topExpander`}
                      key={`topExpander`}
                      expand={expander.current}
                      imgArray={topExpanderConfig.frames}
                      style={{ width: "0px" }}
                      size={size}
                    />
                  </ThePortalTopExpander>
                  <ThePortalBottomExpander
                    name="thePortalExpanderBottom"
                    ref={bottomUI}
                  >
                    <LinearImageAnimationElement
                      id={`bottomExpander`}
                      key={`bottomExpander`}
                      expand={expander.current}
                      imgArray={bottomExpanderConfig.frames}
                      style={{ width: "0px" }}
                      size={size}
                    />
                  </ThePortalBottomExpander>
                </ThePortalCenter>

                <ThePortalRight name="thePortalRight" />
              </ThePortalForeground>

              <ThePortalBackground
                ref={portalBackground}
                name="thePortalBackground"
              >    
              <div style={pointerLayerStyle}>Test</div>
                <Canvas ref={portalCanvas} style={{"z-index":"0"}}>
                  <CameraControls/>
                  
                  {stars.current.map((starData, index) => {
                    //https://codesandbox.io/s/shoe-configurator-qxjoj
                    //console.log("generate Star", starData);
                    return (
                    <StarObj
                      key={index}
                      index={index}
                      id={starData.id}
                      url={starData.url}
                      title={starData.title}
                      createdAt={starData.created_at}
                      author={starData.author}
                      points={starData.points}
                    />
                  
                    )
                  })
                  }
                  <MyCube/>
                  <SkyBox/>
                  {/* <CustomCursor/> */}
                  
                </Canvas>
                
              </ThePortalBackground>
              <ThePortalCover
                name="thePortalCover"
                ref={portalCover}
                style={{ width: "0px", height: "0px" }}
              ></ThePortalCover>
            </Portal>
          <div>

          </div>
        </div>
      </PortalContainer>
      <TheHud
        foreground={hudDataValue}
      />
    </div>
  );
}
