import { keyframes } from "@emotion/react";

export const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -30px, 0);
  }

  70% {
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`;

export const fadeIn = keyframes`

  0% {
    transform: opacity 0;
  }

  33% {
    transform: opacity .3;
  }

  66% {
    transform: opacity .6;
  }

  100% {
    transform: opacity .9;
  }
`;

export const fadeOut = keyframes`
  0% {
    transform: opacity .9;
  }

  33% {
    transform: opacity .6;
  }

  66% {
    transform: opacity .3;
  }

  100% {
    transform: opacity 0;
  }
`;
