export const getCategoryImage = (category) => {
  switch(category){
    case "ai":
      return "ai-category.png";
    case "wp":
      return "wordpress-category.png";
    case "js":
      return "javascript-category.png";
    case "css":
      return "css-category.png";
    case "html":
      return "html-category.png";
    case "ssr":
      return "ssr-category.png";
    case "dev":
      return "dev-category.png";
    case "qe":
      return "qe-category.png";
    case "pm":
      return "pm-category.png";
    case "sosci":
      return "socio-category.png";
    case "stem":
      return "scitech-category.png";
    case "random":
      return "thought-category.png";
    case "recipe":
      return "recipe-category.png";
    case "music":
      return "music-category.png";
    case "uncat":
      return "uncat-category.png";
    default:
      break;
  }
}

export const getCategoryAlt = (category) => {
  switch(category){
    case "ai":
      return "Artificial Intelligence";
    case "wp":
      return "Wordpress";
    case "js":
      return "JavaScript";
    case "css":
      return "Cascading Style Sheets";
    case "html":
      return "Hyper Text Markup Language";
    case "ssr":
      return  "Server Side Rendering";
    case "dev":
      return  "Development";
    case "qe":
      return  "Quality Engineering";
    case "pm":
      return  "Project Management";
    case "sosci":
      return  "Social Science";
    case "stem":
      return  "Science & Technology";
    case "random":
      return  "Random Thought";
    case "recipe":
      return "Recipe";
    case "music":
      return "Music";
    case "uncat":
      return "Uncategorized";
    default:
      break;
  }
}