import React, {useContext, useRef, useEffect, useState} from "react";
import { useRecoilState } from "recoil";
import {
  basicUserState
} from "../../config/state/uiState";
import { UserContext } from '../../lib/context';
import Button from "./Button";
import {
  ProfileBoxContainer,
  ProfileBg,
  ProfileBlock,
  ProfileImg,
  ProfileLink,
  ButtonDiv
} from "./ProfileBoxStyle.js";

import { theme } from "../../themes/greyscaleTheme";
export default function ProfileBox(props) {
  console.log('load ProfileBox');
  const [userState, setUserState] = useRecoilState(basicUserState);
  const {toggle} = props;
  const {user, username} = useContext(UserContext);
  const profileBlock = useRef();
  const [photoUrl, setPhotoUrl] = useState(userState.photoURL);

  useEffect(()=> {
    let profileBlockInterval = setInterval(()=>{
      if(profileBlock.current){
        profileBlock.current.style.visibility = "hidden";
        profileBlockInterval = null;
      }
    },1000);

  },[profileBlock.current]);

  useEffect(()=> {
    setPhotoUrl(userState.photoURL);
  
  }, [userState.photoURL]);


  return(
    <ProfileBoxContainer name="profileBoxContainer">
      <ProfileBg props={props}/>
      <ProfileBlock ref={profileBlock}>
        <ButtonDiv name="buttonDiv">
          <ProfileImg alt={`${username}'s Profile Image`} src={photoUrl} />
          <ProfileLink>
            <Button
            isActive={true}
            id={`ProfileBtn`}
            text={`Profile Button`}
            btnOverImgArray={theme.media.ProfileImages.buttons[0].hoverStates}
            btnClickedImg={theme.media.ProfileImages.buttons[0].clickedState}
            btnOverAudio={theme.media.ProfileImages.buttons[0].hoverAudio}
            mediaPath={theme.themePath}
            btnPrompt={theme.media.ProfileImages.buttons[0].prompt}
            handleOnClick={toggle}
          />
          </ProfileLink>
        </ButtonDiv>
      </ProfileBlock>
    </ProfileBoxContainer>
  );

}







