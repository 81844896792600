import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { menuOffsetIs } from "../../utils/MenuOffsetIs";
import { useWindowDimensions } from "../../app/WindowDimensionsProvider";
import BurgerButton from "./BurgerButton";
import ForwardButton from "./ForwardButton";
import BackButton from "./BackButton";
import SearchButton from "./SearchButton";
import {
  SearchInput,
  SearchByType,
  SearchConsoleContainer,
  SearchButtonContainer,
  BackButtonContainer,
  ForwardButtonContainer,
  BurgerButtonLeft,
  BurgerButtonRight,
  MenuToggleContainer,
  ToggleLeftBorder,
  MenuToggleImg,
  ToggleRightBorder,
  MenuBgImage,
  HeaderTitle
} from "./MenuHeaderStyle.js";

import { menuOpenState, contentHeightState } from "../../config/state/uiState";
import { getMenuOffset } from "../../utils/MenuOffset";
import { getFooterOffset } from "../../utils/FooterOffset";
import { theme } from "../../themes/greyscaleTheme";
import {
  mainSearchState,
  searchOpenState,
  searchTypeState,
} from "../../config/state/uiState";
export default function MenuHeader(props) {
  console.log("load MenuHeader");
  const searchInputRef = useRef();
  const searchTypeRef = useRef();
  const navSearchRef = useRef();
  const searchForm = useRef();
 const [searchState, setSearchState] = useRecoilState(mainSearchState);
  const [searchType, setSearchType] = useRecoilState(searchTypeState);
  const { contentRef, footerRef, menubarRef } = props;
  const { width, height } = useWindowDimensions();
  const menuBtnRef = useRef();
  const [menubarOpen] = useRecoilState(menuOpenState);
  const [contentHeight, setContentHeight] = useRecoilState(contentHeightState);
  const theMenuOffset = menuOffsetIs(width, height);
  const { openModifier, closedModifier } = getMenuOffset(width, height);
  const{ openModifierFooter, closedModifierFooter} = getFooterOffset(width, height);

  const [locationKeys, setLocationKeys] = useState([]);
  
  const history = useNavigate();

useEffect(() => {
  console.log("MenuHeader useEffect");
});

  //Update Menu State and Content Size
  useEffect(() => {
    console.log("useEffect Content Size")
    //If the menu is closed
    if (menubarOpen.menuOpen === false) {
      //set menu height to zero
      menubarRef.current.style.height = "0px";
      //set the height of the menu
      contentRef.current.style.height =
        "calc(" + height + "px - " + openModifier + "px)";

      setContentHeight({contentHeight: height - openModifier});
      
      ///store the menuOpen status  
      if (typeof window !== "undefined") {
        localStorage.setItem("menuOpen", false);
      }

    } else {
      menubarRef.current.style.height = theMenuOffset;
      contentRef.current.style.height =
        "calc(" + height + "px - " + closedModifier + "px)";
        setContentHeight({contentHeight: height + closedModifier});

      if (typeof window !== "undefined") {
        localStorage.setItem("promptHidden", true);
      }

      if (typeof window !== "undefined") {
        localStorage.setItem("menuOpen", true);
      }
    }
  }, [
    menubarOpen.menuOpen,
    menubarRef,
    theMenuOffset,
    menuBtnRef,
    contentRef,
    width,
    height,
    footerRef,
    closedModifierFooter, 
    closedModifier, 
    openModifierFooter,
    openModifier,
    setContentHeight
  ]);

  const doSearch = useCallback(() =>{
    setSearchState({ 
      activeSearch: searchInputRef.current.value,
      searchType: searchTypeRef.current.value
    });
    history("/search");//?" + new Date());
  },[history,setSearchState,])

  const handleSearchButton = (event) => {
    doSearch();
  };

  const handleSearchInputKeyEvent = (event) => {
    if (event.code === "Enter") {
      doSearch();
    }
  };
  const handleSearchByType = (event) => {
    doSearch();
  };

  return (
    <MenuToggleContainer id="menuHeader">
      <ToggleLeftBorder themePath={theme.themePath} />
      <MenuBgImage>
        <HeaderTitle>C&nbsp; O&nbsp; R&nbsp; E&nbsp; Y&nbsp; L&nbsp; I&nbsp; N&nbsp; E&nbsp; S&nbsp; .&nbsp; C&nbsp; O&nbsp; M</HeaderTitle>
        <SearchInput>
          <input
            ref={searchInputRef}
            type="text"
            id="q"
            name="q"
            defaultValue={searchState.activeSearch}
            onKeyDown={handleSearchInputKeyEvent}
          ></input>
        </SearchInput>
        <SearchByType>
          <select
            ref={searchTypeRef}
            value={searchState.searchType}
            id="selType"
            name="selType"
            onChange={handleSearchByType}
          >
            <option value="any">All</option>
            <option value="share">Posts</option>
            <option value="resource">Resources</option>
          </select>
        </SearchByType>
        <SearchButtonContainer
          name="searchButton"
          ref={navSearchRef}
          onClick={() => {
            handleSearchButton();
          }}>
          <SearchButton/>
        </SearchButtonContainer>
        <BurgerButtonLeft>
          <BurgerButton/>
        </BurgerButtonLeft>
        <BackButtonContainer>
          <BackButton></BackButton>
        </BackButtonContainer>
        <ForwardButtonContainer>
          <ForwardButton></ForwardButton>
        </ForwardButtonContainer>
        <BurgerButtonRight>
          <BurgerButton/>
        </BurgerButtonRight>
      </MenuBgImage>
      <ToggleRightBorder themePath={theme.themePath} />
    </MenuToggleContainer>
  );
}
