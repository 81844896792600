import React, {useEffect, useRef} from "react";
import TabletLeftControls from "../TabletControls";
import { theme } from "../../themes/greyscaleTheme";

import {
  TabletLeftBezel,
  TabletScreenContentLeft,
  LeftControls,
  LeftScreen
} from "./TabletScreenLeftStyle.js";

export default function TabletScreenLeft(props) {

const { images } = props;
const screenContentSrc = useRef();
let playHead = 0;
const nextImage = () => {
  //console.log(playHead);  
  if(playHead === images.length-1){
    playHead = 0;
  }else{
    playHead++;
  }
  screenContentSrc.current.src = images[playHead].thumbnailPath;
}

const prevImage = () => {
  //console.log(playHead);  
  if(playHead === 0){
    playHead = images.length -1;
  }else{
    playHead--;
  }
  
  screenContentSrc.current.src = images[playHead].thumbnailPath;
}
useEffect(()=>{
if(images.length > 0) {
  screenContentSrc.current.src = images[0].thumbnailPath;
}
},[images]);

  return(
    <div>  
    <TabletLeftBezel> 
    <LeftControls>
      {/* <TabletLeftControls
        next={nextImage}
        prev={prevImage}
       controls={"100011"}
      /> */}
    </LeftControls>
    <LeftScreen>
      <TabletScreenContentLeft
        src={`${theme.themePath}${theme.media.TabletImages.tablet_small_screen_noise_centered}`}
        ref={screenContentSrc}
      />
    </LeftScreen>
      </TabletLeftBezel>
    </div>
  )
}