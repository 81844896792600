import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

export const MenuToggleContainer = styled("div")`
  position: relative;
  text-align: center;
  display: flex;
  z-index: 100;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${sm}px) {
    flex-direction: row;
    border-top: solid 1px ${(props) => props.theme.colors.menubgblue};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    z-index: 100;
    border-top: solid 3px ${(props) => props.theme.colors.menubgblue};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    border-top: solid 3px ${(props) => props.theme.colors.menubgblue};
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    border-top: solid 5px ${(props) => props.theme.colors.menubgblue};
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    border-top: solid 5px ${(props) => props.theme.colors.menubgblue};
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    border-top: solid 5px ${(props) => props.theme.colors.menubgblue};
  }
`;
export const SearchConsoleContainer = styled("div")`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 165px;
    height: 51px;
    top: 44px;
    left: 143px;
    //border-radius: 7px;
    //border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 320px;
    height: 20px;
    top: 52px;
    left: 115px;
    //border-radius: 7px;
    //border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 444px;
    height: 20px;
    top: 47px;
    left: 153px;
    //border-radius: 7px;
    //border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 526px;
    height: 20px;
    top: 44px;
    left: 173px;
   
    border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 526px;
    height: 20px;
    top: 44px;
    left: 173px;

    border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 526px;
    height: 20px;
    top: 44px;
    left: 173px;

    border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};

  }
`;
export const SearchInput = styled("div")`
animation: ease 0.5s;
transition: 0.5s;
  position:absolute;
  display:block;
  cursor: pointer;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 175px;
    height: 14px;
    top: 41px;
    left: 136px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 258px;
    height: 16px;
    top: 46px;
    left: 125px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 18px;
    width: 275px;
    top: 52px;
    left: 215px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 20px;
    width: 320px;
    top: 52px;
    left: 248px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 20px;
    width: 320px;
    top: 52px;
    left: 248px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 20px;
    width: 320px;
    top: 52px;
    left: 248px;
  }

  input {
    animation: ease 0.5s;
transition: 0.5s;
    width: 100%;
    height: 100%;
    font-family: arial;
    border-radius: 7px;
  border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  background-color: #000000;
  color: ${(props) => props.theme.colors.white};
    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      margin-top: 0px;
      font-size:14px;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      margin-top: 0px;
      font-size:14px;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      margin-top: 0px;
      font-size:16px;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      margin-top: 0px;
      font-size:16px;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      margin-top: 0px;
      font-size:16px;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      margin-top: 0px;
      font-size:16px;
    }
  }
`;
export const SearchSortDirection = styled("div")`
  display: inline-block;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 15px;
    width: 40px;
    margin-right: 3px;
    margin-top: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 15px;
    width: 40px;
    margin-right: 3px;
    margin-top: 4px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 17px;
    width: 80px;
    margin-right: 5px;
    margin-top: 7px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 17px;
    width: 80px;
    margin-right: 5px;
    margin-top: 7px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 19px;
    width: 100px;
    margin-right: 5px;
    margin-top: 5px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 19px;
    width: 100px;
    margin-right: 5px;
    margin-top: 5px;
  }

  select {
    width: 100%;
    height: 100%;

    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      font-size: 8px;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      font-size: 8px;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      font-size: 10px;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      font-size: 10px;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      font-size: 12px;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      font-size: 12px;
    }
  }
`;
export const SearchByType = styled("div")`
animation: ease 0.5s;
transition: 0.5s;
  display: block;
  cursor: pointer;
  position: absolute;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 18px;
    width: 90px;
    top: 64px;
    left: 206px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 20px;
    width: 97px;
    top: 70px;
    left: 294px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 24px;
    width: 74px;
    top: 51.5px;
    left: 503px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 25px;
    width: 85px;
    top: 51.5px;
    left: 580px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 25px;
    width: 85px;
    top: 51.5px;
    left: 580px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 25px;
    width: 85px;
    top: 51.5px;
    left: 580px;
  }

  select {
    animation: ease 0.5s;
transition: 0.5s;
    width: 100%;
    height: 100%;
    border-radius: 7px;
  border: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  background-color: #000000;
  color: ${(props) => props.theme.colors.applebarrelpoolblue};
    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      font-size: 8px;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      font-size: 8px;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      font-size: 10px;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      font-size: 12px;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      font-size: 12px;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      font-size: 12px;
    }
  }
`;
export const SearchButtonContainer = styled("div")`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 25px;
    height: 26px;
    top: 62px;
    left: 300px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 35px;
    height: 36px;
    top: 53px;
    left: 394px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 30px;
    height: 31px;
    top: 53px;
    left: 581px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 40px;
    height: 41px;
    top: 51px;
    left: 670px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 40px;
    height: 41px;
    top: 51px;
    left: 670px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 40px;
    height: 41px;
    top: 51px;
    left: 670px;
  }
`
export const ForwardButtonContainer = styled('div')`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 30px;
    height: 20px;
    top: 65px;
    left: 171px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 35px;
    height: 25px;
    top: 71px;
    left: 166px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 35px;
    height: 25px;
    top: 55px;
    left: 179px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 40px;
    height: 30px;
    top: 53px;
    left: 204px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 40px;
    height: 30px;
    top: 53px;
    left: 204px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 40px;
    height: 30px;
    top: 53px;
    left: 204px;
  }
`;
export const BackButtonContainer = styled('div')`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 30px;
    height: 20px;
    top: 65px;
    left: 133px;

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 35px;
    height: 25px;
    top: 71px;
    left: 125px;

  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 35px;
    height: 25px;
    top: 55px;
    left: 148px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 40px;
    height: 30px;
    top: 53px;
    left: 170px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 40px;
    height: 30px;
    top: 53px;
    left: 170px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 40px;
    height: 30px;
    top: 53px;
    left: 170px;
  }
`;
export const BurgerButtonLeft = styled('div')`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 32px;
    height: 32px;
    top: 36px;
    left: 100px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 37px;
    height: 37px;
    top: 30px;
    left: 74px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 37px;
    height: 37px;
    top: 30px;
    left: 101px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 42px;
    height: 42px;
    top: 28px;
    left: 123px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 42px;
    height: 42px;
    top: 28px;
    left: 123px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 42px;
    height: 42px;
    top: 28px;
    left: 123px;

  }
`;

export const BurgerButtonRight = styled('div')`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 32px;
    height: 32px;
    top: 36px;
    left: 323px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 37px;
    height: 37px;
    top: 30px;
    left: 445px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 37px;
    height: 37px;
    top: 30px;
    left: 618px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 42px;
    height: 42px;
    top: 28px;
    left: 714px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 42px;
    height: 42px;
    top: 28px;
    left: 714px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 42px;
    height: 42px;
    top: 28px;
    left: 714px;
  }
`;
export const MenuBgImage = styled("div")`
  background-repeat: no-repeat;
  display: block;
  display: flex;
  z-index: 100;
  margin-right: auto;
  margin-left: auto;
  animation: ease 0.5s;
  transition: 0.5s;
  opacity: 1;
  position: absolute;
  
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-image: url("${(props) => props.theme.themePath}/large/menu-toggle/toggle_bg_narrow.png");
    background-size: 450px 118px;
    top: 0px;
    left: calc(50% - 225px);
    width:450px;
    height:118px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-image: url("${(props) => props.theme.themePath}/large/menu-toggle/toggle_bg_wide.png");
    background-size: 550px 130px;
    top: 0px;
    left: calc(50% - 275px);
    width:550px;
    height:130px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-image: url("${(props) => props.theme.themePath}/large/menu-toggle/toggle_bg_wide.png");
    background-size: 750px 120px;
    top: 0px;
    left: calc(50% - 375px);
    width:750px;
    height:120px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-image: url("${(props) => props.theme.themePath}/large/menu-toggle/toggle_bg_wide.png");
    background-size: 870px 120px;
    top: 0px;
  left: calc(50% - 435px);
  width:870px;
    height:120px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-image: url("${(props) => props.theme.themePath}/large/menu-toggle/toggle_bg_wide.png");
    background-size: 870px 120px;
    top: 0px;
  left: calc(50% - 435px);
  width:870px;
    height:120px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    background-image: url("${(props) => props.theme.themePath}/large/menu-toggle/toggle_bg_wide.png");
    background-size: 870px 120px;
    top: 0px;
  left: calc(50% - 435px);
  width:870px;
    height:120px;
  }
`;

export const HeaderTitle = styled("h1")`
animation: ease 0.5s;
transition: 0.5s;
position:absolute;
display:block;
font-family: "ROG";
  font-size: 24px;
  text-shadow: 2px 2px 5px #000000;
  //top: 103px;
  margin-left: auto;
  margin-right: auto;
  //margin-top: -85px;
  
  display: inline-block;
  text-align: center;
  z-index: 0;
  color: ${(props) => props.theme.colors.applebarrelpoolblue};
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 450px;
    left:calc( 50% - 225px);
    font-size:12px;
    top:12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 550px;
    left:calc( 50% - 275px);
    font-size:16px;
    top:12px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 750px;
    left:calc( 50% - 375px);
    font-size:20px;
    top:8px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 870px;
    left:calc( 50% - 435px);
    font-size:24px;
    top:3px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 870px;
    left:calc( 50% - 435px);
    font-size:24px;
    top:3px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 870px;
    left:calc( 50% - 435px);
    font-size:24px;
    top:3px;


  }

  
`;

export const ToggleLeftBorder = styled("div")`
  display: flex;
  height: 3px;
  border-top: solid 3px ${(props) => props.theme.colors.jet};

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 5px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 5px;
  }
`;

export const MenuToggleImg = styled("img")`
  cursor: pointer;
  display: none;
  z-index: 100;
  margin-right: auto;
  margin-left: auto;
  animation: ease 0.5s;
  transition: 0.5s;
  opacity: 1;
  ${'' /* //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 25px;
    width: 350px;
    cursor: pointer;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 32px;
    width: 450px;
    cursor: pointer;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 49px;
    width: 698px;
    cursor: pointer;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 54px;
    width: 766px;
    cursor: pointer;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 54px;
    width: 766px;
    cursor: pointer;
  } */}

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 54px;
    width: 766px;
    cursor: pointer;
  }
`;

export const ToggleRightBorder = styled("div")`
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
`;

export const MenuSignIn = styled("div")`
  position: fixed;
  display: block;
  left: -100px;
  height: 60px;
  width: 250px;
  border-top: solid 3px ${(props) => props.theme.colors.jet};

  @media (min-width: 360px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1200px) {
  }
`;
