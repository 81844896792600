import styled from "@emotion/styled";
import {
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*  Break Point Summary 
breakpoints: {
  xs: 0,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/

export const SiteMapBg = styled("div")`
  position: absolute;
  display: block;
  z-index: 10;
  animation: ease 0.5s;
  transition: 0.5s;
  z-index:0;
  background-repeat: no-repeat;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-image: url(/themes/greyscaleTheme/large/bg-tiles/sitemap-v.png);
    background-size: 216px 360px;
    height: 360px;
    width: 216px;
    left: calc(50% - ${(xxs - 230) / 2}px);
    top: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-image: url(/themes/greyscaleTheme/large/bg-tiles/sitemap-v.png);
    background-size: 231px 342px;
    height: 342px;
    width: 231px;
    left: calc(50% - 62px);
    top: 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-image: url(/themes/greyscaleTheme/large/bg-tiles/sitemap-h.png);
    background-size: 420px 176px;
    height: 176px;
    width: 420px;
    left: calc(50% - 222px);
    top: 39px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-image: url(/themes/greyscaleTheme/large/bg-tiles/sitemap-h.png);
    background-size: 576px 212px;
    height: 212px;
    width: 576px;
    left: calc(50% - 298px);
    top: 42px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-image: url(/themes/greyscaleTheme/large/bg-tiles/sitemap-h.png);
    background-size: 768px 286px;
    height: 286px;
    width: 768px;
    left: calc(50% - 394px);
    top: 50px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    background-image: url(/themes/greyscaleTheme/large/bg-tiles/sitemap-h.png);
    background-size: 768px 286px;
    height: 286px;
    width: 768px;
    left: calc(50% - 394px);
    top: 50px;
  }
`;
export const SiteMapHomeLink = styled("div")`
  font-family: ROG, "Roboto", sans-serif;

  color: #ffffff;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: block;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    left: 118px;
    top: 183px;
    font-size: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    left: 192px;
    top: 173px;
    font-size: 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: 190px;
    top: 62px;
    font-size: 18px;
    padding: 6px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 279px;
    top: 71px;
    font-size: 20px;
    padding: 8px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 367px;
    top: 88px;
    font-size: 28px;
    padding: 10px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 367px;
    top: 88px;
    font-size: 28px;
    padding: 10px;
  }
`;
export const SiteMapAboutLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;

  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    left: 29px;
    top: 357px;
    font-size: 8px;
    width: 60px;
    padding: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 339px;
    left: 89px;
    font-size: 10px;
    padding: 8px;
    width: 62px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 14px;
    left: 23px;
    font-size: 9px;
    padding: 6px;
    width: 45px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 9px;
    left: 29px;
    font-size: 12px;
    padding: 8px;
    width: 52px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 10px;
    left: 34px;
    font-size: 16px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 10px;
    left: 34px;
    font-size: 16px;
  }
`;
export const SiteMapTermsLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 263px;
    left: 29px;
    font-size: 8px;
    width: 60px;
    padding: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 255px;
    left: 56px;
    width: 95px;
    font-size: 10px;
    padding: 8px;
  }
  //medium 768-992
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 6px;
    left: 119px;
    width: 55px;
    font-size: 9px;
    padding: 4px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 8px;
    left: 136px;
    width: 115px;
    font-size: 12px;
    padding: 8px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 10px;
    left: 187px;
    width: 151px;
    font-size: 16px;
    padding: 10px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 10px;
    left: 187px;
    width: 151px;
    font-size: 16px;
    padding: 10px;
  }
`;
export const SiteMapPrivacyLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 84px;
    left: 29px;
    font-size: 8px;
    padding: 4px;
    width: 60px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 84px;
    left: 46px;
    font-size: 10px;
    padding: 8px;
    width: 105px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 6px;
    left: 318px;
    font-size: 9px;
    width: 57px;
    padding: 4px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 9px;
    left: 420px;
    font-size: 12px;
    padding: 8px;
    width: 128px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 9px;
    left: 559px;
    font-size: 16px;
    padding: 10px;
    width: 168px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 9px;
    left: 559px;
    font-size: 16px;
    padding: 10px;
    width: 168px;
  }
`;
export const SiteMapContactLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 174px;
    left: 29px;
    font-size: 8px;
    width: 60px;
    padding: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 170px;
    left: 52px;
    font-size: 10px;
    padding: 8px;
    width: 98px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 6px;
    left: 214px;
    font-size: 9px;
    padding: 4px;
    width: 62px;
  }
  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 8px;
    left: 290px;
    font-size: 10px;
    width: 94px;
    padding: 10px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 10px;
    left: 378px;
    width: 150px;
    font-size: 16px;
    padding: 10px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 10px;
    left: 378px;
    width: 150px;
    font-size: 16px;
    padding: 10px;
  }
`;
export const SiteMapSiteMapLink = styled("div")`
  position: absolute;
  animation: ease 0.5s;
  transition: 0.5s;
  text-align: center;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 1px;
    left: 29px;
    font-size: 8px;
    padding: 4px;
    width: 60px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 0px;
    left: 90px;
    font-size: 10px;
    width: 61px;
    padding: 8px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 14px;
    left: 418px;
    font-size: 9px;
    padding: 6px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 9px;
    left: 578px;
    font-size: 12px;
    width: 98px;
    padding: 8px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 10px;
    left: 782px;
    font-size: 16px;
    width: 98px;
    padding: 10px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 10px;
    left: 782px;
    font-size: 16px;
  }
`;
export const SiteMapResourceLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 357px;
    left: 199px;
    font-size: 8px;
    padding: 4px;
    width: 85px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 339px;
    left: 275px;
    font-size: 10px;
    padding: 8px;
    width: 81px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: 0px;
    top: 121px;
    font-size: 10px;
    width: 77px;
    padding: 6px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 9px;
    top: 140px;
    font-size: 12px;
    padding: 8px;
    width: 92px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 4px;
    top: 184px;
    font-size: 16px;
    padding: 10px;
    width: 126px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 4px;
    top: 184px;
    font-size: 16px;
    padding: 10px;
    width: 126px;
  }
`;
export const SiteMapResourceDetailLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 263px;
    left: 199px;
    font-size: 8px;
    width: 63px;
    padding: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 250px;
    left: 275px;
    font-size: 10px;
    width: 66px;
    padding: 8px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: 112px;
    top: 121px;
    font-size: 9px;
    width: 63px;
    padding: 6px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 154px;
    top: 140px;
    font-size: 12px;
    width: 82px;
    padding: 8px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 203px;
    top: 184px;
    font-size: 16px;
    width: 110px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 203px;
    top: 184px;
    font-size: 16px;
    width: 110px;
  }
`;
export const SiteMapSearchResultLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 174px;
    left: 199px;
    font-size: 8px;
    width: 63px;
    padding: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    left: 275px;
    top: 163px;
    font-size: 10px;
    padding: 8px;
    width: 53px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: 212px;
    top: 121px;
    width: 65px;
    font-size: 9px;
    padding: 4px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 298px;
    top: 140px;
    width: 80px;
    font-size: 12px;
    padding: 8px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 405px;
    top: 184px;
    width: 84px;
    font-size: 16px;
    padding: 10px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 405px;
    top: 184px;
    width: 84px;
    font-size: 16px;
    padding: 10px;
  }
`;
export const SiteMapShareDetailLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  padding: 10px;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 83px;
    left: 199px;
    font-size: 8px;
    width: 63px;
    padding: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 69px;
    left: 277px;
    font-size: 10px;
    width: 60px;
    padding: 8px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 121px;
    left: 308px;
    font-size: 10px;
    padding: 4px;
    width: 77px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 140px;
    left: 440px;
    font-size: 12px;
    padding: 8px;
    width: 90px;

  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 184px;
    left: 585px;
    font-size: 16px;
    padding: 10px;
    width: 117px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 184px;
    left: 585px;
    font-size: 16px;
    padding: 10px;
    width: 117px;
  }
`;
export const SiteMapShareLink = styled("div")`
  position: absolute;
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 2px #66ffff;
  border-radius: 20px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 0px;
    left: 199px;
    font-size: 8px;
    padding: 4px;
    width: 85px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 0px;
    left: 277px;
    font-size: 10px;
    padding: 8px;
    width: 81px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 121px;
    left: 415px;
    font-size: 9px;
    width: 74px;
    padding: 6px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 140px;
    left: 585px;
    font-size: 12px;
    padding: 8px;
    width: 98px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 184px;
    left: 774px;
    font-size: 16px;
    padding: 10px;
    width: 130px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 184px;
    left: 774px;
    font-size: 16px;
    padding: 10px;
    width: 130px;
  }
`;
export const SiteMapImageTopCorner = styled("div")`
  background-image: url(/themes/greyscaleTheme/large/bg-tiles/top-left-frame.png);
  background-repeat: no-repeat;
  border-radius: 27px;
  position: absolute;
  display: block;
  z-index: 10;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: -26px;
    left: calc(50% - ${(xxs + 52) / 2}px);
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: -26px;
    left: calc(50% - ${sm / 2}px);
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: -26px;
    left: calc(50% - ${(sm + 53) / 2}px);
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: -26px;
    left: calc(50% - ${(md + 53) / 2}px);
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: -26px;
    left: calc(50% - ${(lg + 53) / 2}px);
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: -26px;
    left: calc(50% - ${(lg + 53) / 2}px);
  }
`;
export const SiteMapContainer = styled("div")`
  display: flex;
  flex-direction: column-reverse;
  margin-left: auto;
  margin-right: auto;
  animation: ease 0.5s;
  transition: 0.5s;
  position: relative;
  //extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top: -96px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top: -142px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top: -190px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top: -230px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  top: -295px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  top: -295px;
}
`;
export const SiteMapContentContainer = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 5px #66ffff;
  border-radius: 45px 27px 27px 27px;
  position: absolute;
  z-index: 5;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: ${xxs}px;
    top: 123px;
    left: calc(50% - ${xxs / 2}px);
    height: 470px;
  }
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: ${sm - 50}px;
    top: 140px;
    left: calc(50% - ${(sm - 50) / 2}px);
    height: calc(100% - 160px);
    height: 450px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: ${sm}px;
    top: 215px;
    left: calc(50% - ${sm / 2}px);
    height: 300px;
  }
  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: ${md}px;
    top: 215px;
    left: calc(50% - ${md / 2}px);
    height:340px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: ${lg}px;
    top: 248px;
    left: calc(50% - ${lg / 2}px);
    height: 425px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: ${lg}px;
    top: 248px;
    left: calc(50% - ${lg / 2}px);
    height: 425px;
  }
`;
export const SiteMapContentTitle = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  position: relative;
  display: block;
  color: #fffff;
  font-family: ROG, "Roboto", sans-serif;
  text-align: center;
  z-index:15;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    left: calc(50% - ${xxs / 2}px);
    top: 30px;
    width: ${xxs}px;
    font-size: 16px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    left: calc(50% - ${xs / 2}px);
    top: 30px;
    width: ${xs}px;
    font-size: 16px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: calc(50% - ${sm / 2}px);
    top: 30px;
    width: ${sm}px;
    font-size: 16px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: calc(50% - ${md / 2}px);
    top: 30px;
    width: ${md}px;
    font-size: 16px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: calc(50% - ${lg / 2}px);
    top: 30px;
    width: ${lg}px;
    font-size: 18px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 0px;
    top: 0px; 
    width: 100%;
    font-size: 20px
  }
`;
export const SiteMapContentBody = styled("div")`
  position: absolute;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  animation: ease 0.5s;
  transition: 0.5s;
  font-family: ROG, "Roboto", sans-serif;
  color: #ffcc33;
  z-index:15;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 14px;
    left: calc(50% - ${xs / 2}px);
    height: calc(100% - 75px);
    width: ${xs - 40}px;
    margin-left: 30px;
    top: 65px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 14px;
    left: calc(50% - ${(sm - 50) / 2}px);
    height: calc(100% - 75px);
    width: ${sm - 100}px;
    margin-left: 40px;
    top: 65px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 14px;
    left: calc(50% - ${sm / 2}px);
    height: calc(100% - 75px);
    width: ${sm - 40}px;
    margin-left: 40px;
    top: 80px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 14px;
    left: calc(50% - ${md / 2}px);
    height: calc(100% - 75px);
    width: ${md - 40}px;
    margin-left: 40px;
    top: 80px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 14px;
    left: calc(50% - ${lg / 2}px);
    height: calc(100% - 75px);
    width: ${lg - 40}px;
    margin-left: 40px;
    top: 80px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 14px;
    left: calc(50% - ${lg / 2}px);
    height: calc(100% - 75px);
    width: ${lg - 40}px;
    margin-left: 40px;
    top: 80px;
  }
`;
