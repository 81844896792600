import styled from '@emotion/styled';

export const GridContainer = styled('div')`
  display:grid;
  grid-template-columns: [col-left] 1fr [col-center] auto [col-right] 1fr;
  grid-template-rows: [content-row] 100%;
  grid-auto-flow: column;

  @media (min-width: 768px) { }

  @media (min-width: 992px) { }

  @media (min-width: 1200px) { }
`
export const GridLeftItem = styled('div')`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  @media (min-width: 320px) {
    border-top:solid 3px ${props => props.theme.colors.jet};
  }

  @media (min-width: 768px) {
    border-top:solid 4px ${props => props.theme.colors.jet};
  }

  @media (min-width: 1200px) {
    border-top:solid 5px ${props => props.theme.colors.jet};
  }
`

export const GridCenterItem = styled('div')`
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
`
export const GridRightItem = styled('div')`
  justify-items: center;
  align-items: stretch;
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  @media (min-width: 320px) {
    border-top:solid 3px ${props => props.theme.colors.jet};
  }

  @media (min-width: 768px) {
    border-top:solid 4px ${props => props.theme.colors.jet};
  }

  @media (min-width: 1200px) {
    border-top:solid 5px ${props => props.theme.colors.jet};
  }
`

export const GridRow = styled('div')`
  grid-row-start: 1 ;
  grid-row-end:2;


  @media (min-width: 768px) { }

  @media (min-width: 992px) { }

  @media (min-width: 1200px) { }
`