export const topExpanderConfig = {
  responsiveBreak: [767, 1199],
  themePath: "/themes/greyscaleTheme",
  frames: [
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander001.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander002.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander003.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander004.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander005.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander006.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander007.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander008.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander009.png",
    "/themes/greyscaleTheme/large/three-portal/top-expander/top-expander010.png",
  ],
};
