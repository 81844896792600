import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const Expander = styled("div")`
  display: flex;
  margin-top: 5px;
  text-align: center;
  margin-bottom: -10px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const LeftEndExpander = styled("div")`
  background-image: url("${(props) =>
    props.theme.themePath}/large/divider-horizontal/left-end.png");
  background-repeat: no-repeat;
  margin-left: auto;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 2px;
    width: 14px;
    height: 20px;
    background-size: 14px 20px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 2px;
    width: 14px;
    height: 20px;
    background-size: 14px 20px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 6px;
    width: 17px;
    height: 24px;
    background-size: 17px 24px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 6px;
    width: 17px;
    height: 24px;
    background-size: 17px 24px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 3px;
    width: 27px;
    height: 34px;
    background-size: 27px 34px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 2px;
    width: 3px;
    height: 11px;
    background-size: 3px 11px;
  }
`;
export const CenterHExpander = styled("div")`
  background-image: url("${(props) =>
    props.theme.themePath}/large/divider-horizontal/center-piece.png");
  background-repeat: repeat-x;
  display: block;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 20.5rem;
    height: 10px;
    margin-top: 9px;
    background-size: auto 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 20.5rem;
    height: 10px;
    margin-top: 9px;
    background-size: auto 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 42rem;
    height: 15px;
    margin-top: 13px;
    background-size: auto 15px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 42rem;
    height: 11px;
    margin-top: 2px;
    background-size: auto 11px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 53rem;
    height: 11px;
    margin-top: 2px;
    background-size: auto 11px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 53rem;
    height: 11px;
    margin-top: 2px;
    background-size: auto 11px;
  }

  // //mobile portrait
  // @media (min-width: 360px) {
  //   width: 20.5rem;
  //   height: 10px;
  //   margin-top: 9px;
  //   background-size: auto 10px;
  // }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   width: 42rem;
  //   height: 15px;
  //   margin-top: 13px;
  //   background-size: auto 15px;
  // }
  // //tablet portrait / landscape
  // @media (min-width: 768px) and (min-height: 621px) {
  //   width: 42rem;
  //   height: 15px;
  //   margin-top: 13px;
  //   background-size: auto 15px;
  // }
  // //tablet landscape
  // @media (min-width: 1200px) and (max-height: 620px) {
  //   width: 42rem;
  //   height: 15px;
  //   margin-top: 13px;
  //   background-size: auto 15px;
  // }
  // //desktop / laptop
  // @media (min-width: 1200px) and (min-height: 621px) {
  //   width: 53rem;
  //   height: 23px;
  //   margin-top: 12px;
  //   background-size: auto 23px;
  // }
`;
export const RightEndExpander = styled("div")`
  background-image: url("${(props) =>
    props.theme.themePath}/large/divider-horizontal/right-end.png");
  background-repeat: no-repeat;
  margin-right: auto;

  /*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  height: 25px;
  width: 25px;
  background-size: 25px 25px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  height: 25px;
  width: 25px;
  background-size: 25px 25px;
}

//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  margin-top: 3px;
  width: 35px;
  height: 32px;
  background-size: 35px 32px;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  margin-top: 3px;
  width: 35px;
  height: 32px;
  background-size: 35px 32px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  margin-top: 0px;
  width: 45px;
  height: 42px;
  background-size: 45px 42px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  margin-top: 0px;
  width: 45px;
  height: 42px;
  background-size: 45px 42px;
}
*/
  //mobile portrait
  @media (min-width: 360px) {
    height: 25px;
    width: 25px;
    background-size: 25px 25px;
  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {
    margin-top: 3px;
    width: 35px;
    height: 32px;
    background-size: 35px 32px;
  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    margin-top: 3px;
    width: 35px;
    height: 32px;
    background-size: 35px 32px;
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    margin-top: 3px;
    width: 35px;
    height: 32px;
    background-size: 35px 32px;
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    margin-top: 2px;
    width: 3px;
    height: 11px;
    background-size: 3px 11px;
  }
`;
