import {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  } from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
  
  export const getFooterOffset = (width, height) => {
    let openModifierFooter = "top:150px;";
    let closedModifierFooter = "top:0px;";
    //extra small 0-575
    if (width >= xs && width < sm) {
        openModifierFooter = 275;
        closedModifierFooter = 275;
    }
   //small 576-767
    if (width >= sm && width < md) {
        openModifierFooter = 384;
        closedModifierFooter = 384;
    }
  //medium 768-991
    if (width >= md && width < lg) {
        openModifierFooter = 244;
        closedModifierFooter = 244;
    }
    //large 992-1199
    if (width >= lg && width < xl) {
        openModifierFooter = 244;
        closedModifierFooter = 244;
    }
    //xl 1200-1399
    if (width >= xl && width < xxl) {
        openModifierFooter = 289;
        closedModifierFooter = 289;
    }
    //xxl 1400+
    if (width >= xxl) {
        openModifierFooter = 289;
        closedModifierFooter = 289;
    }
  
    return { openModifierFooter, closedModifierFooter };
  };