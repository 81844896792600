import React, {useRef, useEffect, forwardRef} from "react";
import ReactDOM from "react-dom";
import {
  ModalContainer,
  ModalPop,
  ModalTitle,
  ModalFooter,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
} from "./ModalStyle.js";
//import { fadeIn, fadeOut} from "../../uix/animation/keyframes";
//import { ToggleLeftBorder } from "../MenuToggle/MenuToggleStyle.js";

const Modal = forwardRef((props, ref) => {
  console.log('load Modal');
  const { visible, children, close, title } = props;
  
  const modalContainerRef = useRef();
  const modalPopRef = useRef();

  useEffect(() => {
    if(visible){
      //openModal(modalRef);
      modalPopRef.current.style = `
      visibility: visible;
      opacity: 0.9;
      width:900px;
    `;

    }
  },[visible]);

  if(visible){
    return(
    ReactDOM.createPortal(
      <ModalContainer ref={modalContainerRef}>
        <ModalPop
        ref={modalPopRef}
        role="dialog"
        aria-modal="true">
          <ModalCloseButton type="button" onClick={close}>Close</ModalCloseButton>
          <ModalTitle>{title}</ModalTitle>
          <ModalContent>{children}</ModalContent>
          <ModalFooter>Privacy  · Terms  · Advertising  · Ad Choices   · Cookies<br/> CoreyLines © 2022</ModalFooter>
          
        </ModalPop>  
        <ModalOverlay onClick={close} ></ModalOverlay>    
      </ModalContainer>, document.body
    )
    )
  }
  
  if(!visible){
    return null;
  }
});

export default Modal;