
import React from 'react';
import {
    PanelContainer,
} from'./LeftPanelStyle.js';

export default function LeftPanel() {
    return (
        <PanelContainer>

        </PanelContainer>
    );
}