import React from 'react';

import ResponsiveLayout from '../ResponsiveLayout';
import LargeView from '../LargeView';
import { UserContext} from '../../lib/context'
//import { useUserData } from '../../hooks/useUserData';

const AppInterface = () => {

  //const userData = useUserData();

  return(
    // <UserContext.Provider value={userData}>
      <ResponsiveLayout
        renderSmall={() => <LargeView viewState="small"/>}
        renderMedium = {() => <LargeView viewState="medium"/>}
        renderLarge = {() => <LargeView viewState="large"/>}
      />
    //</UserContext.Provider>
  )
}

export default AppInterface;