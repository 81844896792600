import styled from "@emotion/styled";
import {
  xxs,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

/*
  //mobile portrait
  @media (min-width: 360px) {
  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {

  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {

  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {

  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px){

  }
*/
export const ListContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  padding-left:0px;
  width:100%;

  @media (min-width: 768px) {
  }

  @media (min-width: 1200px) {
  }
`;

export const ListContainer = styled("div")`
  //mobile portrait
  @media (min-width: 360px) {
  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {
  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    width: 96%;
  }
`;

export const ListFilter = styled("div")`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 5px;
  visibility: hidden;

  @media (min-width: 768px) {
    visibility: hidden;
    display: block;
    margin-top: 0px;
  }

  @media (min-width: 1200px) {
    visibility: hidden;
    display: block;
    margin-top: 0px;
  }
`;

export const ListItemColumn = styled("div")`
  text-align: right;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    //width: 30%;
  }

  @media (min-width: 1200px) {
    //width: 30%;
  }
`;
export const MiniListItem = styled("div")`
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 3px 3px 3px 3px;
  margin-bottom: 3px;
  border-radius: 10px;
  border: solid 2px ${(props) => props.theme.colors.powder_blue};
  background-color: ${(props) => props.bgColor};
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const ListPivot = styled("div")`
  display: flex;
  flex-direction: column;
  //flex-wrap: nowrap;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const ResourcesCardBody = styled("div")`
  padding: 5px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    flex-direction: column;
  }
`;
export const ListItemTitle = styled("div")`
  font-size: 11px;
  display: inline-block;
  //max-width: 100%;
  width: calc(${md/2}px);
  text-align: center;
  width:100%;
  color: ${(props) => props.theme.colors.powder_blue};
  a {
    text-decoration: none;
    text-weight: bold;
    color: ${(props) => props.theme.colors.powder_blue};
  }
  a:hover {
    color: ${(props) => props.theme.colors.yellow};
  }

  @media (min-width: 768px) {
    font-size: 14px;
    //text-align: left;
    margin-right: 10px;
  }

  @media (min-width: 1200px) {
    font-size: 14px;
    //text-align: left;
  }
`;
export const ListItemCategory = styled("div")`
  display: inline-block;
  margin-right: 15px;
  img {
    width: 80px;
  }

  @media (min-width: 768px) {
    text-align: left;
    img {
      width: 90px;
    }
  }

  @media (min-width: 1200px) {
    text-align: left;
    img {
      width: 100px;
    }
  }
`;
export const ListItemImgContainer = styled("div")`
  width: 100%;
  display: inline-block;
  text-align: center;
  //padding:3px;
  //background-color:#202020;
  //border-radius:4px;

  a {
    display: inline-block;
  }

  @media (min-width: 768px) {
    max-width: 150px;
    width: 150px;
    padding: 6px;
    //background-color:#ffffff;
    border-radius: 8px;
  }

  @media (min-width: 1200px) {
    max-width: 175px;
    max-width: 175px;
    //padding:8px;
    //background-color:#ffffff;
    //border-radius:10px;
  }
`;

export const ListItemImg = styled("img")`
  max-width: 4rem;
  max-height: 2rem;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: 5rem;
    max-height: 3rem;
  }
`;

export const ListItemPublishDate = styled("div")`
  font-size: 11px;
  //max-width:100%;
  //width:100%;
  display: inline-block;
  text-align: center;

  @media (min-width: 768px) {
    max-width: 150px;
    width: 150px;
    text-align: right;
  }

  @media (min-width: 1200px) {
    max-width: 175px;
    width: 175px;
    text-align: right;
  }
`;

export const ListItemModifyDate = styled("div")`
  //max-width:100%;
  //width:100%;
  display: inline-block;
  text-align: center;
  font-size: 11px;

  @media (min-width: 768px) {
    max-width: 150px;
    width: 150px;
    text-align: right;
  }

  @media (min-width: 1200px) {
    max-width: 175px;
    width: 175px;
    text-align: right;
  }
`;

export const ResourcesEmpty = styled("div")`
  text-align: center;
  font-family: "ROG";
  font-size: 14px;
  display: block;
  height: 50vh;
  margin: auto;
  padding: 70px 0;
`;

export const ListItemCategoryName = styled("div")`
  display: inline-block;
  text-align: center;
  font-size: 11px;

  @media (min-width: 768px) {
    text-align: right;
  }

  @media (min-width: 1200px) {
    text-align: right;
    width: 100%;
  }
`;

export const ListHeaderRow = styled("div")`
  font-size: 12px;
  margin-left: auto;
  margin-right: auto;
  width: 77%;

  flex-direction: row;
  flex-wrap: nowrap;
  padding: 3px 10px 3px 3px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: solid 3px ${(props) => props.theme.colors.jet};
  background-color: ${(props) => props.theme.colors.rich_black_steam};
  align-items: center;
  justify-content: space-between;
  display: none;

  @media (min-width: 768px) {
    width: 660px;
    display: flex;
    flex-direction: row;
  }

  @media (min-width: 1200px) {
    width: 840px;
    display: flex;
    flex-direction: row;
  }
`;

export const ListHeaderItemCategory = styled("div")`
  display: inline-block;
  margin-right: 15px;
  width: 40px;

  @media (min-width: 768px) {
    text-align: center;
    width: 50px;
  }

  @media (min-width: 1200px) {
    text-align: center;
    width: 60px;
  }
`;

export const ListHeaderPivot = styled("div")`
  display: flex;
  flex-direction: column;
  //flex-wrap: nowrap;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const ListHeaderItemTitle = styled("div")`
  font-size: 12px;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-left: 5px;
  @media (min-width: 768px) {
    font-size: 14px;
    text-align: left;
  }

  @media (min-width: 1200px) {
    font-size: 14px;
    text-align: left;
  }
`;

export const ListHeaderItemImgContainer = styled("div")`
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 3px;
  background-color: #202020;
  @media (min-width: 768px) {
    max-width: 125px;
    width: 125px;
    padding: 6px;
  }

  @media (min-width: 1200px) {
    max-width: 175px;
    width: 175px;
    padding: 8px;
  }
`;

export const ListHeaderItemColumn = styled("div")`
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 30%;
  }

  @media (min-width: 1200px) {
    width: 37%;
    text-align: right;
  }
`;
