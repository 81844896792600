import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const SearchMenuContainer = styled("div")`
  text-align: center;
  flex-direction: column;
  z-index: 100;
  transition: 0.3s;
  display: flex;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: -29px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: -29px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: -29px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -35px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -35px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -35px;

  }
`;
export const SearchMenuComponent = styled("div")`
  z-index: 99;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {


  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {

  }
`;
export const SearchMenuTools = styled("div")`
  background-image: url("${(props) => props.theme.themePath}${(props) =>
    props.theme.media.SearchDropdownImages.search_tools}");
  background-repeat: no-repeat;
  display: inline-block;
  visibility: hidden;
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 29px;
    width: 320px;
    background-size: 320px 29px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 29px;
    width: 320px;
    background-size: 320px 29px;
    display: inline-block;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 29px;
    width: 630px;
    background-size: 630px 29px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 35px;
    width: 778px;
    background-size: 778px 35px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 35px;
    width: 778px;
    background-size: 778px 35px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 35px;
    width: 778px;
    background-size: 778px 35px;
  }
`;
export const SearchToolsRow = styled("div")`
  flex-direction: row;
  justify-content: center;
  display: flex;
  //align-items: center;
  margin-left: auto;
  margin-right: auto;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {

  }
`;
export const SearchToolsColumn = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height:35px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const SearchMenuToggle = styled("div")`
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
  position: relative;
  display: flex;
  flex-direction: row;
  animation: ease 0.5s;
  transition: 0.5s;
  opacity: 1;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 24px;
    width: 318px;
    cursor: pointer;
    background-image: url("${(props) =>
      props.themePath}/large/menu-search/menu-search-bg.png");
    background-repeat: no-repeat;
    background-size: 319px 24px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 31px;
    width: 410px;
    cursor: pointer;
    background-image: url("${(props) =>
      props.themePath}/large/menu-search/menu-search-bg.png");
    background-repeat: no-repeat;
    background-size: 411px 31px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 47px;
    width: 635px;
    cursor: pointer;
    background-image: url("${(props) =>
      props.themePath}/large/menu-search/menu-search-bg.png");
    background-repeat: no-repeat;
    background-size: 637px 47px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    position: relative;
    cursor: pointer;
    background-image: url("${(props) =>
      props.themePath}/large/menu-search/menu-search-bg.png");
    background-repeat: no-repeat;
    background-size: 698px 52px;
    width: 696px;
    height: 52px;
 
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    position: relative;
    cursor: pointer;
    background-image: url("${(props) =>
      props.themePath}/large/menu-search/menu-search-bg.png");
    background-repeat: no-repeat;
    background-size: 698px 52px;
    width: 696px;
    height: 52px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    position: relative;
    cursor: pointer;
    background-image: url("${(props) =>
      props.themePath}/large/menu-search/menu-search-bg.png");
    background-repeat: no-repeat;
    background-size: 698px 52px;
    width: 696px;
    height: 52px;
  }
`;
export const NavToggle = styled("div")`
  background-color: #4d4d4d;
  color: #ffffff;
  font-weight: bolder;
  font-size: 10px;

  height: 11px;
  margin-right: auto;

  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-left: 17px;
    margin-top: 6px;
    font-size: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-left: 17px;
    margin-top: 6px;
    font-size: 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 7px;
    margin-left: 34px;
    font-size: 12px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 7px;
    margin-left: 34px;
    font-size: 12px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 7px;
    margin-left: 47px;
    font-size: 14px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 7px;
    margin-left: 47px;
    font-size: 14px;
  }
`;
export const SearchToggle = styled("div")`
  background-color: #4d4d4d;
  color: #ffffff;
  font-weight: bolder;
  margin-left: auto;
  margin-right: 0px;
  height: 11px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 2px;
  
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 10px;
    padding-left: 2px;
    padding-right: 0px;
    margin-top: 6px;
  }
  
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 10px;
    padding-left: 2px;
    padding-right: 0px;
    margin-top: 6px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 7px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }
  
  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 7px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 7px;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
  }
  
  //xxl 1400+
  @media (min-width:  ${xxl}px){
    margin-top: 7px;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
`;
export const LockToggle = styled("div")`
  background-color: #4d4d4d;
  font-weight: bolder;
  color: #333333;
  font-size: 10px;
  height: 11px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 10px;
    margin-top: 6px;
    margin-right: 8px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: inline-block;
    font-size: 10px;
    margin-top: 6px;
    margin-right: 8px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 12px;
    margin-top: 7px;
    margin-right: 30px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 12px;
    margin-top: 7px;
    margin-right: 30px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 14px;
    margin-top: 7px;
    margin-right: 38px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 14px;
    margin-top: 7px;
    margin-right: 38px;
  }
`;
export const NavBackButton = styled("div")`
position:absolute;
display: block;
  cursor: pointer;

  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width:18px;
    height: 18px;
    left:25px;
    top:-1px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 22px;
    height: 22px;
    left:29px;
    top:-2px;
  

    svg {
      width: 100%;
      height: 100%;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 32px;
    height: 32px;
    left:51px;
    top:-2px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 40px;
    height: 40px;
    left:55px;
    top:-5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 40px;
    height: 40px;
    left:55px;
    top:-5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 40px;
    height: 40px;
    left:55px;
    top:-5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
export const NavForwardButton = styled("div")`
  display: block;
  cursor: pointer;
  position:absolute;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 18px;
    height: 18px;
    left: 41px;
    top: -1px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 22px;
    height: 22px;
    left:51px;
    top:-2px;
  

    svg {
      width: 100%;
      height: 100%;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 32px;
    height: 32px;
    left:82px;
    top:-2px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 40px;
    height: 40px;
    left:90px;
    top:-5px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 40px;
    height: 40px;
    left:90px;
    top:-5px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 40px;
    height: 40px;
    left:90px;
    top:-5px;


    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
export const SearchInput = styled("div")`
  position:absolute;
  display:block;
  cursor: pointer;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 157px;
    height: 8px;
    left:60px;
    top:-3px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 215px;
    height: 12px;
    top:0px;
    left:79px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 18px;
    width: 340px;
    top:0px;
    left:126px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 20px;
    width: 380px;
    top:1px;
    left:135px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 20px;
    width: 380px;
    top:-2px;
    left:135px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 20px;
    width: 380px;
    top:-2px;
    left:135px;
  }

  input {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    font-family: arial;
    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      margin-top: 0px;
      font-size:8px;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      margin-top: 0px;
      font-size:10px;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      margin-top: 0px;
      font-size:12px;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      margin-top: 0px;
      font-size:16px;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      margin-top: 0px;
      font-size:16px;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      margin-top: 0px;
      font-size:16px;
    }
  }
`;
export const SearchSortDirection = styled("div")`
  display: inline-block;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 15px;
    width: 40px;
    margin-right: 3px;
    margin-top: 4px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 15px;
    width: 40px;
    margin-right: 3px;
    margin-top: 4px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 17px;
    width: 80px;
    margin-right: 5px;
    margin-top: 7px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 17px;
    width: 80px;
    margin-right: 5px;
    margin-top: 7px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 19px;
    width: 100px;
    margin-right: 5px;
    margin-top: 5px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 19px;
    width: 100px;
    margin-right: 5px;
    margin-top: 5px;
  }

  select {
    width: 100%;
    height: 100%;

    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      font-size: 8px;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      font-size: 8px;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      font-size: 10px;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      font-size: 10px;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      font-size: 12px;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      font-size: 12px;
    }
  }
`;
export const SearchByType = styled("div")`
  display: block;
  cursor: pointer;
  position: absolute;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 12px;
    width: 50px;
    top:-3px;
    left:227px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 16px;
    width: 53px;
    top:-1px;
    left:304px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 22px;
    width: 74px;
    top:-1px;
    left:478px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 24px;
    width: 85px;
    top:0px;
    left:525px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 24px;
    width: 85px;
    top:-2px;
    left:525px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 24px;
    width: 85px;
    top:-2px;
    left:525px;
  }

  select {
    width: 100%;
    height: 100%;
    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      font-size: 8px;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      font-size: 8px;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      font-size: 10px;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      font-size: 10px;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      font-size: 12px;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      font-size: 12px;
    }
  }
`;
export const SearchButton = styled("div")`
  width: 12px;
  height: 12px;
  display: block;
  cursor: pointer;
  position:absolute;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 14px;
    height: 14px;
    left:280px;
    top:0px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 19px;
    height: 19px;
    left:360px;
    top:0px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 25px;
    height: 25px;
    left:556px;
    top:1px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 30px;
    height: 30px;
    left:614px;
    top:0px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 30px;
    height: 30px;
    left:614px;
    top:0px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 30px;
    height: 30px;
    left:614px;
    top:0px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
export const LockButton = styled("div")`
  width: 12px;
  height: 12px;
  display: block;
  cursor: pointer;
  background-color: #0c1f2b;
  border: solid 1px #3e5862;
  border-radius: 0px 0px 27px 0px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 15px;
    height: 14px;
    margin-left: 4px;
    margin-top: 0px;
    svg {
      margin-top: 7px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 20px;
    height: 14px;
    margin-left: 6px;
    margin-top: 0px;
    svg {
      margin-top: 7px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 28px;
    height: 16px;
    margin-left: 6px;
    margin-top: 0px;
    svg {
      margin-top: 7px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
    }
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 28px;
    height: 16px;
    margin-left: 6px;
    margin-top: 0px;
    svg {
      margin-top: 7px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 30px;
    height: 20px;
    margin-left: 6px;
    margin-top: 1px;
    svg {
      margin-top: 9px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 30px;
    height: 20px;
    margin-left: 6px;
    margin-top: 1px;
    svg {
      margin-top: 9px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
    }
  }
`;
export const UnlockButton = styled("div")`
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const SearchFilterDate = styled("div")`
  font-size: 12px;
  margin-left: 7px;
  margin-right: 5px;
  width: 318px;
  display: flex;
  flex-direction: row;
  z-index: 1000;

  input {
    width: 150px;
  }

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const SearchSortBy = styled("div")`
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
