export const theme = {
  breakpoints: {
    xxs:340,
    xs: 360,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  themePath: "/themes/greyscaleTheme",

  mediaPath: "/themes/greyscaleTheme",

  colors: {
    white: "#ffffff",
    black: "#000000",
    blue: "#000099",
    red: "#990000",
    yellow: "#ffcc33",
    light_gray: "#cccccc",
    onyx: "#3f3f3f",
    eerie_black: "#1a1a1c",
    jet: "#333333",
    applebarrelpoolblue: "#66ffff", //aqua - https://encycolorpedia.com/66ffff
    menubgblue: "#1b424a", //blue - https://encycolorpedia.com/2d86b1
    sunglow:"#ffcc33", //sunglow - https://encycolorpedia.com/ffcc33
    rich_black_FOGRA_39: "#0a0a11",
    rich_black_steam: "#1f1f26",
    powder_blue: "#66ffff",//"#BBE5ED",
    button_border: "#707070",
    button_bg: "#000000",
    button_cover: "#333333",
    button_text_white: "#FFFFFF",
    button_text_blue: "#000099",
    button_text_red: "#990000",

    content_border: "#707070",
    content_bg: "#b8b8b8",
    content_text_white: "#FFFFFF",
    content_text_blue: "#000099",
    content_text_red: "#990000",
  },
  media: {
    MenuToggleImages: {
      burger_open_default:"/large/menu-toggle/menu-toggle-burger-open-default.png",
      burger_close_default:"/large/menu-toggle/menu-toggle-burger-close-default.png",
      burger_open_over: "/large/menu-toggle/menu-toggle-burger-open-over.png",
      burger_close_over: "/large/menu-toggle/menu-toggle-burger-close-over.png",
    },
    MenuImages: {
      menu_bg_left: "/large/menu/menu-bg/menu-bg-left.png",
      menu_bg_center: "/large/menu/menu-bg/menu-bg-center.png",
      menu_bg_right: "/large/menu/menu-bg/menu-bg-right.png",
      menu_btn_info: "/large/menu/button-info.png",
      menu_btn_info_active: "/large/menu/button-info-active.png",
      left_menu: {
        buttons: [
          {
            name: "welcome",
            title: "welcome",
            prompt: "Welcome to CoreyLines.com!",
            id: "d4275630-d801-406b-ae0b-12c3c34d3185",
            hoverAudio: "/sound/pressure.mp3",
            clickedState: "/large/menu/button/button_clicked.png",
            column: "1",
            position: "1",
            hoverStates: [
              "/large/menu/button/button00.png",
              "/large/menu/button/button01.png",
              "/large/menu/button/button02.png",
              "/large/menu/button/button03.png",
              "/large/menu/button/button04.png",
              "/large/menu/button/button05.png",
              "/large/menu/button/button06.png",
              "/large/menu/button/button07.png",
            ],
          },
          {
            name: "resource",
            title:"resource",
            prompt: "Information about things and stuff...",
            id: "72bed2d7-aa45-40d8-8628-f92e9a1b7738",
            hoverAudio: "/sound/pressure.mp3",
            clickedState: "/large/menu/button/button_clicked.png",
            column: "1",
            position: "2",
            hoverStates: [
              "/large/menu/button/button00.png",
              "/large/menu/button/button01.png",
              "/large/menu/button/button02.png",
              "/large/menu/button/button03.png",
              "/large/menu/button/button04.png",
              "/large/menu/button/button05.png",
              "/large/menu/button/button06.png",
              "/large/menu/button/button07.png",
            ],
          },
          {
            name: "share",
            title:"post",
            prompt: "Posts about stuff and things...",
            id: "ad2d7f5b-7578-4cc4-bb4c-1db756d62d1c",
            hoverAudio: "/sound/pressure.mp3",
            clickedState: "/large/menu/button/button_clicked.png",
            column: "1",
            position: "3",
            hoverStates: [
              "/large/menu/button/button00.png",
              "/large/menu/button/button01.png",
              "/large/menu/button/button02.png",
              "/large/menu/button/button03.png",
              "/large/menu/button/button04.png",
              "/large/menu/button/button05.png",
              "/large/menu/button/button06.png",
              "/large/menu/button/button07.png"
            ],
          },
          {
            name: "about",
            title: "about",
            prompt: "The autobiographical context for the posted stuff...",
            id: "f64727c2-413e-4b2a-b5d5-5bdc0a738e2c",
            hoverAudio: "/sound/pressure.mp3",
            clickedState: "/large/menu/button/button_clicked.png",
            column: "1",
            position: "4",
            hoverStates: [
              "/large/menu/button/button00.png",
              "/large/menu/button/button01.png",
              "/large/menu/button/button02.png",
              "/large/menu/button/button03.png",
              "/large/menu/button/button04.png",
              "/large/menu/button/button05.png",
              "/large/menu/button/button06.png",
              "/large/menu/button/button07.png",
            ],
          },
        ],
      },
      right_menu: {
        buttons: [],
      },
    },
    SubMenuImages: {
      buttons: [
        {
          name: "about",
          prompt: "The autobiographical context for the posted stuff...",
          id: "f64727c2-413e-4b2a-b5d5-5bdc0a738e2c",
          hoverAudio: "/sound/aerosol.mp3",
          clickedState:
            "/large/button-about-singleton/button-about-clicked.png",
          hoverStates: [
            "/large/button-about-singleton/button-about00.png",
            "/large/button-about-singleton/button-about01.png",
            "/large/button-about-singleton/button-about02.png",
            "/large/button-about-singleton/button-about03.png",
            "/large/button-about-singleton/button-about04.png",
            "/large/button-about-singleton/button-about05.png",
            "/large/button-about-singleton/button-about06.png",
            "/large/button-about-singleton/button-about07.png",
          ],
        },
        {
          name: "share",
          prompt: "Posts about stuff and things...",
          id: "ad2d7f5b-7578-4cc4-bb4c-1db756d62d1c",
          hoverAudio: "/sound/aerosol.mp3",
          clickedState:
            "/large/button-posts-singleton/button-posts-clicked.png",
          hoverStates: [
            "/large/button-posts-singleton/button-posts00.png",
            "/large/button-posts-singleton/button-posts01.png",
            "/large/button-posts-singleton/button-posts02.png",
            "/large/button-posts-singleton/button-posts03.png",
            "/large/button-posts-singleton/button-posts04.png",
            "/large/button-posts-singleton/button-posts05.png",
            "/large/button-posts-singleton/button-posts06.png",
            "/large/button-posts-singleton/button-posts07.png",
          ],
        },
        {
          name: "resource",
          prompt: "Useful things, helpful stuff, and other distractions...",
          id: "72bed2d7-aa45-40d8-8628-f92e9a1b7738",
          hoverAudio: "/sound/aerosol.mp3",
          clickedState:
            "/large/button-resources-singleton/button-resources-clicked.png",
          hoverStates: [
            "/large/button-resources-singleton/button-resources00.png",
            "/large/button-resources-singleton/button-resources01.png",
            "/large/button-resources-singleton/button-resources02.png",
            "/large/button-resources-singleton/button-resources03.png",
            "/large/button-resources-singleton/button-resources04.png",
            "/large/button-resources-singleton/button-resources05.png",
            "/large/button-resources-singleton/button-resources06.png",
            "/large/button-resources-singleton/button-resources07.png",
          ],
        },
      ],
    },
    ProfileImages: {
      buttons: [
        {
          name: "Profile",
          prompt: "Login or manage your profile",
          id: "f64727c2-413e-4b2a-b5d5-5bdc0a738e23",
          hoverAudio: "/sound/aerosol.m4a",
          clickedState: "/large/profile-button/profile-btn04.png",
          hoverStates: [
            "/large/user-details/profile-button/profile-btn00.png",
            "/large/user-details/profile-button/profile-btn01.png",
            "/large/user-details/profile-button/profile-btn02.png",
            "/large/user-details/profile-button/profile-btn03.png",
            "/large/user-details/profile-button/profile-btn04.png",
            "/large/user-details/profile-button/profile-btn05.png",
            "/large/user-details/profile-button/profile-btn06.png",
          ],
        },
      ],
    },
    SearchDropdownImages: {
      search_tools: "/large/menu-search/menu-search-tools.png",
      search_toggle: "/large/menu-search/menu-search-toggle.png",
    },
    PortalImages: {
      switch_on: "/large/three-portal/toggle-switch-on.png",
      switch_off: "/large/three-portal/toggle-switch-off.png",
      portal_bg: "/large/three-portal/portal-bg.png",
      portal_fg_left: "/large/three-portal/portal-left-001.png",
      portal_fg_right: "/large/three-portal/portal-right-001.png",
      portal_bubble_1: "/large/page-welcome/bubble1.png",
      portal_bubble_2: "/large/page-welcome/bubble2.png",
    },
    LoginImages: {},
    SignupImages: {},
    SpinnerImages: {},
    SocialLinksImages: {},
    ModalImages: {},
    SiteImages: {
      menu_bg_left: "/large/bg-tiles/menu-bg-001.jpg",
      menu_bg_right: "/large/bg-tiles/menu-bg-002.jpg",
      menu_toggle_border_bg: "/large/menu-toggle/menu-toggle-border-bg.png",
      content_bg_left: "/large/bg-tiles/content-bg-001.png",
      content_bg_right: "/large/bg-tiles/content-bg-002.png",
    },
    MiscImages: {
      drip: "/drip-05.gif",
      steam: "/steam-top2bottom.webp",
      spinner: "/spinner/new-spinner.gif",
    },
    SvgImages: {
      facebook_icon: "/social-links/facebook_icon.svg",
      twitter_icon: "/social-links/twitter_icon.svg",
      linkedin_icon: "/social-links/linkedin_icon.svg",
      instagram_icon: "/social-links/instagram_icon.svg",
      youtube_icon: "/social-links/youtube_icon.svg",
      pinterest_icon: "/social-links/pinterest_icon.svg",
      github_icon: "/social-links/github_icon.svg",
      codepen_icon: "/social-links/codepen_icon.svg",
      stackoverflow_icon: "/social-links/stackoverflow_icon.svg",
    },
    MiniGallery: {
      bg_image: "/large/mini-gallery/mini-gallery-bg.png",
      forward_button: "/large/mini-gallery/mini-gallery-forward.png",
      back_button: "/large/mini-gallery/mini-gallery-back.png",
      zoom_button: "/large/mini-gallery/mini-gallery-zoom.png",
      default_image: "/large/mini-gallery/mini-gallery-default-img.png", 
      default_title_txt: "Use the controls to view images",
      default_desc_txt: "2023",
    }
  },
};
