import React, { useRef} from "react";
import { useNavigate } from "react-router-dom";
import { BackButtonImg } from "./BackButtonStyle.js";
import { theme } from "../../../themes/greyscaleTheme";

export default function BackButton(props) {
  const backBtnRef = useRef();
  const imgBackDefault = "/large/menu-toggle/toggle_back_default.png";
  const imgBackOver = "/large/menu-toggle/toggle_back_over.png";
  const history = useNavigate();
 
  const goBack = () => {
    history(-1);
  };
  return (
    <BackButtonImg
      ref={backBtnRef}
      alt="Navigate Back"
      onTouchStart={(e) => e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgBackOver }
      onTouchEnd={(e) =>e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgBackDefault }
      onMouseOver={(e) =>  e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgBackOver }
      onMouseOut={(e) => e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgBackDefault }
      onClick={(e) => goBack()}
      src={process.env.PUBLIC_URL + theme.themePath + imgBackDefault}
    />
  );
}
