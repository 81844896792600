import React from "react";

import {
  Container,
  BackgroundContainer,
  ForegroundContainer,
  TopRow,
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  BottomRow
} from "./ContentBoxStyle.js";

import { theme } from "../../themes/greyscaleTheme";

export default function ContentBox(props) {
  const { children, width, height, bgColor } = props;
  return (
  <>
<Container>
    <BackgroundContainer>
      <TopRow>
        <TopLeft/>
        <TopRight/>
      </TopRow>
      <BottomRow>
        <BottomLeft/>
        <BottomRight/>
      </BottomRow>
    </BackgroundContainer>
    <ForegroundContainer
      bgColor={bgColor}
      height={height}
    >
      {children}
    </ForegroundContainer>
</Container>
  </>
  );
}
