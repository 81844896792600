import styled from "@emotion/styled";
import {xxs, xs, sm, md, lg, xl, xxl} from "../app/ResponsiveLayout/ResponsiveBreakpoints";
//import { theme } from "../themes/greyscaleTheme";
/*  Break Point Summary 
breakpoints: {
  xs: 0,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/

export const PanelRow = styled("div")`
  width: 100%;
  height: 100%;
  top:0px;
  position:relative;
  margin-top:0px;
  display: flex;
  flex-direction: column;
  
`;
export const PanelPageRoot = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  position:relative;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${sm}px) {
  }

  @media (min-width: ${sm}px) and (max-width: ${md}px) {
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    top:100px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${xl}px) {
    top:218px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${xxl}px) {
    top:218px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top:218px;
  }
`;

export const PanelFlexContainer = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${sm}px) {
    top:75px;
  }

  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    top:60px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    top: 15px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${xl}px) {
    top:-75px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${xxl}px) {
    top:-75px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top:-75px;
  }
`

export const PanelPageContainer = styled("div")`
  opacity: 1;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 125px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 165px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 110px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 65px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 28px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 28px;
  }
`;

export const PanelBorderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  z-index: 10;
  position: relative;
  background-color: rgba(0, 0, 0, 0.72); // #000000;
  border: solid 5px rgba(98, 233, 245, 1);
  border-radius: 46px 27px 27px 27px;
  margin-bottom: 20px;
  animation: ease 0.5s;
  transition: 0.5s;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 0px;
    width: ${xxs}px;
    top: -45px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 0px;
    width: ${sm - 50}px;
    top: -105px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 0px;
    width: ${sm}px;
    top: -105px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 0px;
    width: ${md}px;
    top: -105px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 0px;
    width: ${lg}px;
    top: -105px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 0px;
    width: ${lg}px;
    top: -105px;
  }
`;
export const PanelPageContent = styled("div")`
  flex-grow: 2;
  text-align: left;
  padding: 12px;
  height: 42%;
  width: 98%;
`;

export const PanelImageTopCorner = styled("div")`
  background-image: url(/themes/greyscaleTheme/large/bg-tiles/top-left-frame.png);
  background-repeat: no-repeat;
  border-radius: 46px 27px 27px 27px;
  position: absolute;
  display: block;
  z-index: 10;
  animation: ease 0.5s;
  transition: 0.5s;
  z-index: 100;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: 217px;
    left: calc(50% - 474px);
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: 217px;
    left: calc(50% - 474px);
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: 217px;
    left: calc(50% - 474px);
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: 217px;
    left: calc(50% - 474px);
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: 217px;
    left: 209px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    background-size: 317px 188px;
    height: 188px;
    width: 317px;
    top: 0px;
    left: 228px;
    
  }
`;
