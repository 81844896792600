import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

export const menuOffsetIs = (width, height) => {
  let offset = 0;
  if (width >= xs && width < sm) {
    offset = "237px"; //230px";
  }

  if (width >= sm && width < md) {
    offset = "237px";
  }

  if (width >= md && width < lg) {
    offset = "237px";
  }

  if (width >= lg && width < xl) {
    offset = "237px";
  }

  if (width >= xl && width < xxl) {
    offset = "237px";
  }

  if (width >= xxl) {
    offset = "237px";
  }

  return offset;
};

// export const searchMenuOffsetIs = (size) =>{
//   switch(size){
//     case 'large':
//       return '-35px';
//     case 'medium':
//       return '-29px';
//     case 'small':
//       return '-29px';
//     default:
//       break;
//   }

//}
