import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

export const getMenuOffset = (width, height) => {
  console.log("getMenuOffset");
  let openModifier = 0;
  let closedModifier = 0;

  if (width >= xs && width < sm) {
    console.log('xs');
    openModifier = 0;
    closedModifier = 237;
  }

  if (width >= sm && width < md) {
    openModifier = 0;
    closedModifier = 237;
  }

  if (width >= md && width < lg) {
    openModifier = 0;
    closedModifier = 235;
  }

  if (width >= lg && width < xl) {
    openModifier = 0;
    closedModifier = 237;
  }

  if (width >= xl && width < xxl) {
    openModifier = 0;
    closedModifier = 237;
  }

  if (width >= xxl) {
    openModifier = 0;
    closedModifier = 237;
  }
  console.log('openModifier Function', openModifier);
  console.log('closedModifier Function', closedModifier);
  return { openModifier, closedModifier };
};
