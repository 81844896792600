import { auth, popupSignIn, googleAuthProvider} from '../../lib/firebase';
import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import {
  Form,
  FullWidthTextInput,
  FullWidthButton,
  CreateAccountButton,
  ErrorMessage,
  Heading
} from './SignInStyle';

import {signIn}  from '../../lib/auth/signIn';

export default function SignIn(props) {
  //const { auth } = props;
  const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const history = useNavigate();
    
    const onSignInClicked = async () => {
      try {
        //console.log(emailValue, passwordValue)
          await signIn(auth, emailValue, passwordValue)
          .then(()=>{
            //history.push('/welcome');
          })
          .catch((e)=>{
            setErrorMessage(e.message);
          })
          //
      } catch (e) {
          setErrorMessage(e.message);
      }
    }

  const withTopMargin = px => ({ marginTop: `${px}px` });

  const onSignInWithGoogleClicked = async() => {
    await popupSignIn(auth, googleAuthProvider)
    .then((results)=>{
      // console.log('Google SignIn\n',results);
      // console.log(results.user._delegate.displayName + '\n');
      // console.log(results.user._delegate.email + '\n');
      // console.log(results.user._delegate.emailVerified + '\n');
      // console.log(results.user._delegate.photoURL + '\n');

    })
    .catch((e)=>{
      console.log('Google Login Error: ', e);
    });
  };

  return (

    <Form>
    <Heading>Login / Profile</Heading>
    {errorMessage
        ? <ErrorMessage style={{
            marginBottom: '16px',
        }}>
            {errorMessage}
        </ErrorMessage>
        : null}
    <FullWidthTextInput
        name='email'
        value={emailValue}
        placeholder='someone@gmail.com'
        onChange={e => setEmailValue(e.target.value)} />
    <FullWidthTextInput
        name='password'
        type='password'
        value={passwordValue}
        placeholder='Password'
        style={withTopMargin(8)}
        onChange={e => setPasswordValue(e.target.value)} />
    <FullWidthButton
        type='transparent'
        style={withTopMargin(8)}
        onClick={() => setShowModal(true)}
    >Forgot your password?</FullWidthButton>
    <FullWidthButton
        disabled={!emailValue || !passwordValue}
        style={withTopMargin(8)}
        onClick={onSignInClicked}
    >Sign In</FullWidthButton>
    <FullWidthButton
        style={withTopMargin(8)}
        onClick={onSignInWithGoogleClicked}
    >Sign In With Google</FullWidthButton>
    <CreateAccountButton
    type='transparent'
    onClick={() => history.push('/create-account')}
>Create an account</CreateAccountButton>
    
</Form>



      
  )

}