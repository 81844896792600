import React from "react";
import { Link } from "react-router-dom";
import { theme } from "../../themes/greyscaleTheme";
import {
  ListItemContainer,
  ListItemType,
  ListItemCategory,
  ListItemTitle,
  ListItemTitleText,
} from "../SearchStyle.js";

export default function SearchItem(props) {
  const { subtype, url, title, rowBG, index } =
    props;

  return (
    <ListItemContainer bg={rowBG} key={index} id={`SearchResultRow${index}`}>
        <ListItemType>SEARCH RESULT</ListItemType>
        <ListItemTitle>
          <Link
            to={`../${subtype + url.match("/[^/]+(?=/$|$)/")}`}
          >
            <ListItemTitleText
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Link>
        </ListItemTitle>
        <ListItemCategory>
        {subtype === "share" && (
          <span>
            <Link to={`../${subtype}`}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  theme.themePath + "/large/content-categories/post-item.png"
                }
                alt="Posts"
              />
            </Link>
          </span>
        )}
        {subtype === "resource" && (
          <span>
            <Link to={`../${subtype}`}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  theme.themePath + "/large/content-categories/resource-item.png"
                }
                alt="Resources"
              />
            </Link>
          </span>
        )}
        </ListItemCategory>
    </ListItemContainer>
  )
}
