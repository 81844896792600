import styled from "@emotion/styled";
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

export const LeftBlock = styled("div")`
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  z-index: 100;
  position:relative;
  display:flex;
  margin-left:auto;
  margin-right:auto;
  order:2;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  z-index: 100;
  position:relative;
  display:flex;
  //display:block;
  margin-left:auto;
  margin-right:auto;
  order:2;

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  position:relative;
  display:flex;
  top:180px;
  width: 233px;
  height: 170px;
  order:2;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  position:relative;
  display:flex;
  top:180px;
  width: 233px;
  height: 170px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  display:flex;
 
  top:180px;
  width: 233px;
  height: 170px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  display:flex;
  position:relative;
  top:229px;
  width: 315px;
  height: 230px;
}

`;
export const RightBlock = styled("div")`

@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  z-index: 99;
position:relative;
margin-left:auto;
margin-right:auto;
display:flex;
order:3;

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top:-328px;
  width: 233px;
  height: 170px;
  position:relative;
  display:flex;
order:3;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top:-328px;
  width: 233px;
  height: 170px;
  position:relative;
  display:flex;
order:3;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top:-328px;
  width: 233px;
  height: 170px;
  position:relative;
  display:flex;
order:3;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  position:relative;
  top:-160px;
  width: 233px;
  height: 170px;
  display:flex;
order:3;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  position:relative;
  top:-211px;
  width: 315px;
  height: 230px;
  display:flex;
  order:3;
}
`;

export const CenterBlock = styled("div")`
  margin-left:auto;
  margin-right:auto;
  width:100%;
  z-index:150;
  position:relative;
  display:flex;
  order:1;  
`;

export const MenuImgCenter = styled("div")`
  background-color: #000000;
  background-image: url("${props => props.theme.themePath}/large/bg-tiles/red-tube.jpg?update=08-08-2022");
  background-position: center top;
  background-size: 153px 300px;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width:153px;
  height:300px;
  display:inline;
  position:relative;
  margin-left:500px;
  margin-right:auto;
`;

export const MenuForeground = styled("div")`
position: relative;
z-index:10;

@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  //display:flex;
  top:-264px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  //display:flex;
  top:-263px;

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  //display:flex;
  //top:-170px;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  //display:flex;
  top:-165px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  //display:flex;
  top:-165px;

}

//xxl 1400+
@media (min-width:  ${xxl}px){
  //display:flex;
  top:-225px;
}
`;

export const MenuBackground = styled("div")`
position: relative;
z-index:9;
width:100vw;
display:flex;

@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: -237px;
  }
  
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top:-407px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top:-578px;
  }
  
  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top:-577px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top:-577px;
  }
  
  //xxl 1400+
  @media (min-width:  ${xxl}px){
    top:-697px;
  }
`;

export const MenuBgLeft = styled("div")`
  background-color: #000000;
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.SiteImages.menu_bg_left}");
  background-position: left top;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 200;
  display: inline-block;
  left:-35px;
  animation: ease 0.5s;
  transition: 0.5s;
  width: 50%;
  height: 237px;
  background-size: 50% 237px;
  opacity:0.3;
`;

export const MenuBgRight = styled("div")`
  background-color: #000000;
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.SiteImages.menu_bg_right}");
  background-position: right top;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 200;
  display: inline-block;
  right:-35px;
  animation: ease 0.5s;
  transition: 0.5s;
  width: 50%;
  height: 237px;
  background-size: 50% 237px;
  opacity:0.3;
`;

export const MenuBlock = styled("div")`
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  height: 0px;
  width: 100%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: 0.5s;
  display: block;
  position: relative;
  background-color: black;
`;