import styled from "@emotion/styled";
import { fadeIn } from "../../uix/animation/keyframes";
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

/*
  //default

  //display:none;

  //mobile portrait
  @media (min-width: 360px) {
    //width is at least 360px AND the height is anything
    //small sized drop down menu
    //small sized toggle and search
    //small portal
  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {

    //width is at least 768px AND the height is at at max 620px
    //medium sized drop down menu
    //medium sized toggle and search
    //small portal

  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {

    //width is at least 768px OR the height is at least 621px
    //medium sized drop down menu
    //medium sized toggle and search
    //medium portal

  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {

    //width is at least 768px AND the height is at least 621px
    //large sized drop down menu
    //large sized toggle and search
    //medium sized portal

  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px){
    //large sized drop down menu
    //large sized toggle and search
    //large sized portal
  }
*/
// export const PortalHeader = styled("div")`
// //extra small 0-575
// @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

// }

// //small 576-767
// @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

// }
// //medium 768-991
// @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

// }

// //large 992-1999
// @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

// }
// //xl 1200-1399
// @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

// }

// //xxl 1400+
// @media (min-width:  ${xxl}px){

// }

// `;
export const PortalContainer = styled("div")`
  //default
  display: flex;
  flex-direction: row;
 
  margin-left: auto;
  margin-right: auto;

  //mobile portrait
  @media (min-width: 360px) { 
    top:0px;
    position:relative;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   top:0px;
  //   position:relative;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    top:64px;
    position:relative;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    top:0px;
    position: relative;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    top:0px;
    position: relative;
  }
`;
export const PortalSwitch = styled("img")`
  position: absolute;
  cursor:hand;
  //top: 25px;
  //left: 114px;
  display: inline;
  background-image: url("${props => props.theme.themePath}${(props) => props.theme.media.PortalImages.switch_off}");
  margin-top: 0px;
  margin-right: auto;
  z-index: 50;

  //mobile portrait
  @media (min-width: 360px) {
    position:relative;
    display:inline-block;
    background-size: 30px 46px;
    width: 30px;
    height: 46px;
    top: -95px;
    left: -220px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   background-size: 42px 75px;
  //   width: 42px;
  //   height: 75px;
  //   top: -34px;
  //   left: -391px;
  //   position:relative;
  //   display:inline-block;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    background-size: 42px 75px;
    width: 42px;
    height: 75px;
    top: -102px;
    left: -391px;
    position:relative;
    display:inline-block;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    background-size: 42px 75px;
    width: 42px;
    height: 75px;
    top: -22px;
    left: -390px;
    position:relative;
    display:inline-block;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    background-size: 56px 100px;
    width: 56px;
    height: 100px;
    top: -66px;
    left: -524px;
    position:relative;
    display:inline-block;
  }
`;

export const Drip = styled("div")`
  display: none;
  visibility: visible;
  position: absolute;

  //mobile portrait
  @media (min-width: 360px) {
    display: inline-block;
    position: relative;
    top: 231px;
    left: -97px;
    width: 65px;
    img {
      width: 15%;
      height: 150px;
      opacity: 0.6;
    }
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   position:relative;
  //   display:inline-block;
  //   top: 455px;
  //   left: -234px;
  //   width: 75px;
  //   img {
  //     width: 25%;
  //     height: 128px;
  //     opacity: 0.6;
  //   }
  // }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    position: relative;
    display: inline-block;
    top: 387px;
    left: -235px;
    width: 75px;
    img {
      width: 25%;
      height: 128px;
      opacity: 0.6;
    }
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    position: relative;
    display: inline-block;
    top: 462px;
    left: -233px;
    width: 75px;
    img {
      width: 25%;
      height: 128px;
      opacity: 0.6;
    }
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    display: inline-block;
    position: relative;
    top: 623px;
    left: -320px;
    width: 100px;
    img {
      width: 25%;
      height: 200px;
      opacity: 0.6;
    }
  }
`;

export const Steam = styled("div")`
  visibility: hidden;
  position: absolute;

  display: none;

  //mobile portrait
  @media (min-width: 360px) {
    display: inline-block;
    position: relative;
    top: 148px;
    left: -175px;
    width: 90px;
    height: 70px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   display: inline-block;
  //   position: relative;
  //   top: 448px;
  //   left: -337px;
  //   width: 130px;
  //   height: 125px;

  // }
  
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    display: inline-block;
    position: relative;
    top: 380px;
    left: -337px;
    width: 130px;
    height: 125px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    display: inline-block;
    position: relative;
    top: 455px;
    left: -335px;
    width: 130px;
    height: 125px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    display: inline-block;
    position: relative;
    top: 581px;
    left: -454px;
    width: 170px;
    height: 164px;
  }

  img {
    width: 20%;
    height: 100%;
    display: inline;
    opacity: 0.6;
  }
`;

export const ThePortalBgImg = styled("div")`
  //default
  display: none;
  background-image: url("${props => props.theme.themePath}${(props) => props.theme.media.PortalImages.portal_bg}");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
  //mobile portrait
  @media (min-width: 360px) {
    display: block;
    top: 43px;
    left: 18px;
    background-size: 310px 274px;
    width: 310px;
    height: 274px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   top: 68px;
  //   left: 42px;
  //   background-size: 620px 547px;
  //   width: 620px;
  //   height: 547px;
  // }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    top: 68px;
    left: 42px;
    background-size: 620px 547px;
    width: 620px;
    height: 547px;
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    top: 176px;
    left: 258px;
    background-size: 620px 547px;
    width: 620px;
    height: 547px;
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    top: 305px;
    left: 144px;
    background-size: 838px 741px;
    width: 838px;
    height: 741px;
  }
`;

export const Portal = styled("div")`
  //default
  display: none;
  position: relative;
  margin-top: 0px;
  left: 0px;
  top: 0px;

  //mobile portrait
  @media (min-width: 360px) {
    display: block;
    top:-153px;
    left:5px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   display: block;
  //   position: relative;
  //   //margin-top: 20px;
  //   left: 6px;
  //   top: -101px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    display: block;
    position: relative;
    //margin-top: 100px;
    left: 4px;
    top: -84px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    display: block;
    position: relative;
    margin-top: 0px;
    left: 0px;
    top: -128px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    display: block;
    position: relative;
    margin-top: 0px;
    left: 0px;
    top: -190px;
  }
`;
export const PortalBg = styled("div")`
position: relative;
display:inline-block;

//mobile portrait
@media (min-width: 360px) {
  top: -191px;
  left: -278px;
}

// //mobile landscape
// @media (min-width: 768px) and (max-height: 620px) {
//   top: -197px;
//   left: -505px;
// }

//tablet portrait / landscape
@media (min-width: 768px) and (min-height: 621px) {
  top: -265px;
  left: -505px;
}

//tablet landscape
@media (min-width: 1200px) and (max-height: 620px) {
  top: -297px;
  left: -718px;
}

//desktop / laptop
@media (min-width: 1200px) and (min-height: 621px) {
  top: -499px;
  left: -765px;
}

`

export const ThePortalForeground = styled("div")`
  display: none;
  position: relative;
  margin-top: 0px;

  //mobile portrait
  @media (min-width: 360px) {
    display: flex;
    flex-direction: row;

    top: 4px;
    left: -2px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   top: -25px;
  //   left: -2px;
  // }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    top: -110px;
    left: 0px;
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    top: 6px;
    left: 0px;
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    top: 6px;
    left: 0px;
  }
`;

export const ThePortalLeft = styled("div")`
  display: none;
  outline: none;
  padding: 0;
  background-image: url("${props => props.theme.themePath}${(props) => props.theme.media.PortalImages.portal_fg_left}");
  margin-left: auto;
  margin-right: 0px;
  transition-property: left;
  will-change: left;

  //mobile portrait
  @media (min-width: 360px) {
    display: inline-block;
    width: 150px;
    height: 285px;
    background-size: 150px 285px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   margin-top: -6px;
  //   width: 300px;
  //   height: 570px;
  //   background-size: 300px 570px;
  //   display: inline-block;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    margin-top: -6px;
    width: 300px;
    height: 570px;
    background-size: 300px 570px;
    display: inline-block;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    margin-top: -6px;
    width: 300px;
    height: 570px;
    background-size: 300px 570px;
    display: inline-block;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    margin-top: -13px;
    background-size: 400px 760px;
    width: 400px;
    height: 760px;
    display: inline-block;
  }
`;

export const ThePortalCenter = styled("div")`
  width: 0px;
  display: none;
  margin-left: 0px;
  margin-right: 0px;
  overflow: hidden;
  transition-property: width;
  will-change: width;

  //mobile portrait
  @media (min-width: 360px) {
    margin-top: 0px;
    height: 285px;
    display: block;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   margin-top: 35px;
  //   height: 570px;
  //   display: block;
  // }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    margin-top: 35px;
    height: 570px;
    display: block;
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    margin-top: 35px;
    height: 570px;
    display: block;
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    margin-top: 44px;
    height: 700px;
    display: block;
  }
`;

export const ThePortalTopExpander = styled("div")`
  z-index: 100;
  width: 0px;
  display: none;
  transition-property: width;

  //mobile portrait
  @media (min-width: 360px) {
    display: block;
    height: 36px;
    margin-top: 20px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   height: 74px;
  //   margin-top: -3px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    height: 74px;
    margin-top: -3px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    height: 74px;
    margin-top: -3px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    height: 99px;
  }
`;

export const ThePortalBottomExpander = styled("div")`
  width: 0px;
  display: none;
  transition-property: width;

  //mobile portrait
  @media (min-width: 360px) {
    display: block;
    margin-top: 186px;
    height: 30px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   display: block;
  //   margin-top: 369px;
  //   height: 62px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    margin-top: 369px;
    height: 62px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    margin-top: 369px;
    height: 62px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    margin-top: 490px;
    height: 81px;
  }
`;

export const ThePortalRight = styled("div")`
  display: none;
  outline: none;
  padding: 0;
  margin-left: 0px;
  margin-right: auto;
  overflow: hidden;
  background-image: url("${props => props.theme.themePath}${(props) => props.theme.media.PortalImages.portal_fg_right}");
  transition-property: left;
  will-change: left;

  //mobile portrait
  @media (min-width: 360px) {
    width: 150px;
    height: 285px;
    margin-top: 10px;
    background-size: 150px 285px;
    display: inline-block;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   margin-top: 15px;
  //   width: 300px;
  //   height: 570px;
  //   background-size: 300px 570px;
  //   display: inline-block;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    margin-top: 15px;
    width: 300px;
    height: 570px;
    background-size: 300px 570px;
    display: inline-block;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    margin-top: 15px;
    width: 300px;
    height: 570px;
    background-size: 300px 570px;
    display: inline-block;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    margin-top: 15px;
    width: 400px;
    height: 760px;
    background-size: 400px 760px;
    display: inline-block;
  }
`;

export const ThePortalBackground = styled("div")`
  //default
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0;
  overflow: hidden;
  display: none;
  position: relative;
  z-index: 20;
  top: 0px;
  margin-left: auto;
  margin-right: auto;

  //mobile portrait
  @media (min-width: 360px) {
    display: block;
    left: -10px;
    width: 264px;
    height: 187px;
    margin-top: -240px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   left: -9px;
  //   width: 508px;
  //   height: 379px;
  //   margin-top: -525px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    left: -9px;
    width: 508px;
    height: 375px;
    margin-top: -613px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    left: -9px;
    width: 735px;
    height: 497px;
    margin-top: -497px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    left: -13px;
    width: 735px;
    height: 497px;
    margin-top: -636px;
    border-radius:255px;
  }
`;

export const ThePortalCanvas = styled("canvas")`
  outline: inline-block;
  padding: 0;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;

  //mobile portrait
  @media (min-width: 360px) {
    display: inline-block;
    border-radius: 87px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   border-radius: 255px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    border-radius: 255px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    border-radius: 255px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    border-radius: 255px;
  }
`;

export const ThePortalBubble1 = styled("div")`
  display: none;
  position: absolute;
  z-index: 10;
  transform: opacity;
  background-image: url("${props => props.theme.themePath}${(props) => props.theme.media.PortalImages.portal_bubble_1}");
  background-repeat: no-repeat;
  opacity:0;
  //mobile portrait
  @media (min-width: 360px) {
    display: inline-block;
    top: 116px;
    left: 67px;
    background-size: 91px 77px;
    width: 91px;
    height: 77px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   top: 235px;
  //   left: 178px;
  //   background-size: 130px 110px;
  //   width: 130px;
  //   height: 110px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    top: 235px;
    left: 178px;
    background-size: 130px 110px;
    width: 130px;
    height: 110px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    top: 345px;
    left: 380px;
    background-size: 130px 110px;
    width: 130px;
    height: 110px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    top: 510px;
    left: 300px;
    background-size: 186px 157px;
    width: 186px;
    height: 157px;
    
  }
`;

export const ThePortalBubble2 = styled("div")`
  display: none;
  position: absolute;
  z-index: 10;
  transform: opacity;
  background-image: url("${props => props.theme.themePath}${(props) => props.theme.media.PortalImages.portal_bubble_2}");
  background-repeat: no-repeat;
  opacity:0;

  //mobile portrait
  @media (min-width: 360px) {
    display: inline-block;
    top: 122px;
    left: 214px;
    background-size: 91px 77px;
    width: 91px;
    height: 77px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   top: 247px;
  //   left: 432px;
  //   background-size: 140px 118px;
  //   width: 140px;
  //   height: 118px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    top: 247px;
    left: 432px;
    background-size: 140px 118px;
    width: 140px;
    height: 118px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    top: 369px;
    left: 662px;
    background-size: 140px 118px;
    width: 140px;
    height: 118px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    top: 530px;
    left: 682px;
    background-size: 190px 160px;
    width: 190px;
    height: 160px;
  }
`;

export const ThePortalCover = styled("div")`
  opacity: 1;
  transition: width 1s;
  margin-top: -605px;
  padding: 0;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 0;

  margin-left: auto;
  margin-right: auto;
  //display:none;

  //mobile portrait
  @media (min-width: 360px) {
    display: block;
    top: 0px;
    left: 0px;
    width: 190px;
    height: 190px;
  }

  // //mobile landscape
  // @media (min-width: 768px) and (max-height: 620px) {
  //   top: 0px;
  //   left: 0px;
  //   width: 300px;
  //   height: 301px;
  // }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    top: 0px;
    left: 0px;
    width: 300px;
    height: 301px;
  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    top: 0px;
    left: -10px;
    top: 112px;
    background-color: #000000;

    height: 493px;
    width: 493px;
    border-radius: 250px;
  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    top: 0px;
    left: -10px;
    top: 112px;
    background-color: #000000;

    height: 493px;
    width: 493px;
    border-radius: 250px;
  }
`;
