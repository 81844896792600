import React, {useEffect, useRef} from "react";
import { theme } from "../../themes/greyscaleTheme";

import {
  TabletRightBezel,
  TabletScreenContentRight,
  RightControls,
  RightScreen
} from "./TabletScreenRightStyle.js";


export default function TabletScreenRight(props) {
  const { images } = props;
  const screenContentSrc = useRef();
  let playHead = 0;
  const nextImage = () => {
    //console.log(playHead);  
    if(playHead === images.length-1){
      playHead = 0;
    }else{
      playHead++;
    }
    screenContentSrc.current.src = images[playHead].thumbnailPath;
  }
  
  const prevImage = () => {
    //console.log(playHead);  
    if(playHead === 0){
      playHead = images.length -1;
    }else{
      playHead--;
    }
    
    screenContentSrc.current.src = images[playHead].thumbnailPath;
  }
  useEffect(()=>{
  if(images.length > 0) {
    screenContentSrc.current.src = images[0].thumbnailPath;
  }
  },[images]);
  
    return(
      <div>  
      <TabletRightBezel> 
      <RightControls>
        {/* <TabletRightControls
          next={nextImage}
          prev={prevImage}
         controls={"000000"}
        /> */}
      </RightControls>
      <RightScreen>
        <TabletScreenContentRight
          src={`${theme.themePath}${theme.media.TabletImages.tablet_small_screen_noise_centered}`}
          ref={screenContentSrc}
        />
      </RightScreen>
        </TabletRightBezel>
      </div>
  )
}
