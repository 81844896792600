import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/

export const Copyright = styled("div")`
  color: #66ffff;
  font-family: "ROG";
  display: grid;
  align-items: center;
  position: absolute;
  z-index: 500;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top:47px;
    left:120px;
    font-size: 8px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 12px;
    top:76px;
    left:417px;
    width:375px;
    line-height: 12px;
    display: grid;
    align-items: center;
    text-align: center;
    h1{
      font-size: 12px;
      display: grid;
      align-items: center;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 12px;
    top:60px;
    left:417px;
    width:375px;
    line-height: 12px;
    display: grid;
    align-items: center;
    text-align: center;
    h1{
      font-size: 12px;
      display: grid;
      align-items: center;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 12px;
    top:60px;
    left:417px;
    width:375px;
    line-height: 12px;
    display: grid;
    align-items: center;
    text-align: center;
    h1{
      font-size: 12px;
      display: grid;
      align-items: center;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 10px;
    top:68px;
    left:28px;
    width:375px;
    line-height: 12px;
    display: grid;
    align-items: center;
    text-align: center;
    font-weight: lighter;
    h1{
      font-size: 8px;
      display: grid;
      align-items: center;
      
    }
  }
`;

export const FooterLinks = styled("div")`
position:absolute;
display: flex;
flex-direction: column;
font-size: 14px;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top:18px;
  left:24px;
  width:196px;
  height:60px;
  font-size: 12px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top:18px;
left:24px;
width:196px;
height:60px;
font-size: 12px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top:24px;
left:72px;
width:220px;
height:60px;
font-size: 12px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top:24px;
left:72px;
width:220px;
height:60px;
font-size: 12px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  top:30px;
left:100px;
width:220px;
height:60px;
font-size: 14px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  top:30px;
left:100px;
width:220px;
height:60px;
font-size: 14px;
}

`;

export const FooterRow = styled("div")`
display:flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
margin:5px;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
 
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;

export const PrivacyPolicy = styled("div")`
position:relative;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  ${'' /* top:35px;
 left:105px; */}
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  ${'' /* top:35px;
left:105px; */}
}

//xxl 1400+
@media (min-width:  ${xxl}px){
${'' /* top:35px;
left:105px; */}
}
`;

export const TermsOfUse = styled("div")`
position:relative;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  ${'' /* top: 55px;
  left: 85px; */}
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  ${'' /* top: 55px;
  left: 85px; */}
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  ${'' /* top: 55px;
  left: 85px; */}
}
`;

export const Sitemap = styled("div")`
position:relative;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  ${'' /* top: 35px;
  left: 250px; */}
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  ${'' /* top: 35px;
  left: 250px; */}
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  ${'' /* top: 35px;
  left: 250px; */}
}
`;

export const ContactUs = styled("div")`
position:relative;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  ${'' /* top: 55px;
  left: 230px; */}
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  ${'' /* top: 55px;
  left: 230px; */}
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  ${'' /* top: 55px;
  left: 230px; */}
}
`;

export const SocialLinks = styled("div")`
position:absolute;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){
  display: flex;
top:0px;
left:0px;
width:100%;
height:100%;
}
`;


