import styled from "@emotion/styled";
//import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../app/ResponsiveLayout/ResponsiveBreakpoints";
//import { theme } from "../themes/greyscaleTheme";

//** Specific to ResourceDetails */
export const DetailsContent = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {}

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {}
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {}

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {}
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {}

  //xxl 1400+
  @media (min-width: ${xxl}px) {}
`;
export const DetailsList = styled('div')`


  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {}

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {}
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {}

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {}
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {}

  //xxl 1400+
  @media (min-width: ${xxl}px) {}

`;

//** Specific to ItemDetailsPages */
export const DetailsCard = styled('div')`
  margin-left:auto;
  margin-right:auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom:10px;
  border: rgba(102, 255, 255, .72) solid 3px;
  border-radius: 27px 27px 27px 27px;
  background-color: rgba(0,0,255, 0.1);
  background-repeat: no-repeat;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    
    width:340px;
    height:640px;
    background-size:340px 630px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width:512px;
    height:640px;
    background-size:512px 520px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width:728px;
    height:400px;
    background-size:728px 400px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width:940px;
    height:400px;
    background-size:940px 400px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width:940px;
    height:400px;
    background-size:940px 400px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width:940px;
    height:400px;
    background-size:940px 400px;
  }
`;
export const DetailsTitleLink = styled('a')`
    text-decoration:none;
    animation: ease 0.5s;
    transition: 0.5s;
    color: ${props => props.theme.colors.powder_blue};
    text-align:center;

    &:hover {
      color: ${props => props.theme.powder_blue};
    }
  

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {

  }

`;
export const DetailsCardTitle = styled('h2')`
  ${'' /* position:absolute; */}
  font-weight:bold;
   color:#ffcc33;
  font-family: 'ROG', sans-serif;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) 
  {
    position: absolute;
    top: 0px;
        left: 39px;
        width: 243px;
    height: 80px;
    font-size: 14px;
    padding: 0px 8px 8px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    position:absolute;
    top: 0px;
    left: 69px;
    width: 354px;
    height: 80px;
    font-size: 16px;
    padding: 0px 13px 13px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    position:absolute;
    top: 0px;
    left: 2px;
    width: 364px;
    height: 80px;
    font-size: 16px;

    padding: 0px 13px 13px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    position:absolute;
    top: 0px;
    left: 2px;
    width: 507px;
    height: 80px;
    font-size: 20px;
    //border: solid 1px;
    padding: 0px 13px 13px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    position:absolute;
    top: 0px;
    left: 2px;
    width: 507px;
    height: 80px;
    font-size: 20px;
    //border: solid 1px;
    padding: 0px 13px 13px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    position:absolute;
    top: 0px;
    left: 2px;
    width: 507px;
    height: 80px;
    font-size: 20px;
    //border: solid 1px;
    padding: 0px 13px 13px 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;
export const DetailsCardUrlImage  = styled('div')`
text-align:left;
max-height:50px;
max-width:150px;
margin-left:10px;
animation: ease 0.5s;
transition: 0.5s;
img{
  max-height:50px;
  max-width:150px;
}

@media (min-width: 768px) {
  max-height:75px;
  max-width:175px;
  img{
    max-height:75px;
    max-width:175px;
  }
}

@media (min-width: 1200px) {
  max-height:100px;
  max-width:200px;
  img{
    max-height:100px;
    max-width:200px;
  }
}
`
export const DetailsCardCategoryName  = styled('div')`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "ROG";
    opacity: 0.27;
    color: #66ffff;
    animation: ease 0.5s;
    transition: 0.5s;
    //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {}
    top: 544px;
    width: 338px;
    left: 2px;
    height: 30px;
    font-size: 18px;

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 538px;
    width: 512px;
    left: 2px;
    height: 30px;
    font-size: 18px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 198px;
    width: 380px;
    left: 15px;
    height: 30px;
    font-size: 18px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 198px;
        width: 520px;
        left: 15px;
        height: 30px;
        font-size: 18px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 198px;
        width: 520px;
        left: 15px;
        height: 30px;
        font-size: 18px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 198px;
        width: 520px;
        left: 15px;
        height: 30px;
        font-size: 18px;
  }

`;
export const DetailsCardType = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.27;
  font-family: "ROG";
  text-align: center;
  animation: ease 0.5s;
  transition: 0.5s;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 338px;
        height: 30px;
        line-height: 36px;
        top: 575px;
        left: 2px;
        font-size: 30px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 512px;
    height: 30px;
    line-height: 36px;
    top: 572px;
    left: 2px;
    font-size: 30px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 380px;
    height: 30px;
    line-height: 36px;
    top: 225px;
    left: 15px;
    font-size: 30px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 520px;
        height: 36px;
        line-height: 36px;
        top: 225px;
        left: 15px;
        font-size: 36px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 520px;
        height: 36px;
        line-height: 36px;
        top: 225px;
        left: 15px;
        font-size: 36px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 520px;
        height: 36px;
        line-height: 36px;
        top: 225px;
        left: 15px;
        font-size: 36px;
  }

`;
export const DetailsCardCategoryImage  = styled('div')`
position: absolute;
margin-right:10px;
animation: ease 0.5s;
transition: 0.5s;
//extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: -5px;
        left: 292px;
        width: 80px;
    img{
      width:60px;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: -5px;
    left: 458px;
    width: 70px;
    img{
      width:70px;
    }
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: -5px;
    left: 659px;
    width: 80px;
    img{
      width:80px;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: -5px;
        left: 871px;
        width: 80px;
    img{
      width:80px;
    }
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: -5px;
        left: 871px;
        width: 80px;
    img{
      width:80px;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: -5px;
        left: 871px;
        width: 80px;
    img{
      width:80px;
    }
  }

`;
export const DetailsInfo = styled('div')`
  background-image: url(/themes/greyscaleTheme/large/bg-tiles/hud-landscape.png);
  background-repeat: no-repeat;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 170px;
        width: 320px;
        height: 368px;
        background-size: 320px 368px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 170px;
    width: 450px;
    height: 350px;
    -webkit-background-size: 350px 400px;
    background-size: 450px 350px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    position: absolute;
    top: 23px;
    right: 20px;
    width: 320px;
    height: 358px;
    background-size: 320px 358px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    position: absolute;
    top: 23px;
    right: 20px;
    width: 390px;
    height: 358px;
    background-size: 390px 358px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    position: absolute;
    top: 23px;
    right: 20px;
    width: 390px;
    height: 358px;
    background-size: 390px 358px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    position: absolute;
      top: 23px;
      right: 20px;
      width: 390px;
      height: 358px;
      /* -webkit-background-size: 380px 435px; */
      background-size: 390px 358px;
  }

`;
export const DetailsCardUrl = styled('div')`
  position: absolute;
  text-align:left;
  display:flex;
  align-items: center;
  justify-content:center;
  text-align:center;
  overflow-wrap: anywhere;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 100px;
    left: 2px;
        width: 323px;
        height: 80px;
    font-size: 13px;
    padding: 0px 8px 8px 8px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 100px;
    left: 15px;
    height: 100px;
    width: 480px;
    font-size: 16px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 110px;
    left: 2px;
    width: 364px;
    height: 80px;
    padding: 0px 13px 13px 13px;
    display: flex;
    justify-content: center;
    position: absolute;
    font-size: 16px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 110px;
    left: 2px;
    width: 507px;
    height: 80px;
    padding: 0px 13px 13px 13px;
    display: flex;
    justify-content: center;
    position: absolute;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 110px;
    left: 2px;
    width: 507px;
    height: 80px;
    padding: 0px 13px 13px 13px;
    display: flex;
    justify-content: center;
    position: absolute;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 110px;
    left: 2px;
    width: 507px;
    height: 80px;
    padding: 0px 13px 13px 13px;
    display: flex;
    justify-content: center;
    position: absolute;
  }

  a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-decoration:underline;
    text-weight:bold;
    color: ${props => props.theme.colors.powder_blue};
  }
  a:hover{
    color: ${props => props.theme.colors.yellow};
  }

  #label{
    font-weight:bold;
    color: ${props => props.theme.colors.yellow};
  }
`;
export const DetailsCardDescription = styled('div')`
  position: absolute;
  top: 65px;
  left: 626px;
  width: 250px;
  height: 272px;
  font-size:14px;
  margin:10px;
  overflow-wrap: break-word;
  overflow-y: auto;
  animation: ease 0.5s;
  transition: 0.5s;  
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 171px;
    left: 6px;
    width: 284px;
    height: 340px;
    border-radius: 27px;
    padding: 13px 13px 0px 13px;

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 172px;
    left: 24px;
    width: 417px;
    height: 319px;
    font-size: 13px;
    padding: 15px 15px 0px 15px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 21px;
    left: 386px;
    width: 280px;
    height: 327px;
    font-size: 13px;
    padding: 15px 15px 0px 15px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 21px;
        left: 529px;
        width: 343px;
        height: 308px;
        font-size: 14px;
        //border: solid 1px;
        padding: 18px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 21px;
        left: 529px;
        width: 343px;
        height: 308px;
        font-size: 14px;
        //border: solid 1px;
        padding: 18px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 21px;
        left: 529px;
        width: 343px;
        height: 308px;
        font-size: 14px;
        //border: solid 1px;
        padding: 18px;
  }

  #label{
    font-weight:bold;
    color: ${props => props.theme.colors.yellow};
  }
`;
export const DetailsCardModifyDate = styled('div')`

    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffcc33;
    animation: ease 0.5s;
    transition: 0.5s;

    //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    position: absolute;
    top: 615px;
    left: 170px;
    height: 16px;
    width: 169px;
    font-size: 12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    position: absolute;
        top: 615px;
        left: 257px;
        height: 16px;
        width: 243px;
        font-size: 14px;
        //border: solid 1px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    position: absolute;
    top: 380px;
    left: 205px;
    height: 14px;
    width: 190px;
    font-size: 12px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    position: absolute;
    top: 380px;
    left: 277px;
    width: 260px;
    height:14px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    position: absolute;
    top: 380px;
    left: 277px;
    width: 260px;
    height:14px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    position: absolute;
    top: 380px;
    left: 277px;
    width: 260px;
    height:14px;
  }
`;
export const DetailsCardPublishDate = styled('div')`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffcc33;
    animation: ease 0.5s;
    transition: 0.5s;
    //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    position: absolute;
        top: 615px;
        left: 2px;
        height: 16px;
        width: 169px;
        font-size: 12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

    position: absolute;
        top: 615px;
        left: 15px;
        height: 16px;
        width: 242px;
        font-size: 14px;
        //border: solid 1px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 380px;
    left: 15px;
    height: 14px;
    width: 190px;
    font-size: 12px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 380px;
    left: 15px;
    height:14px;
    width: 260px;
    font-size: 12px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 380px;
    left: 15px;
    height:14px;
    width: 260px;
    font-size: 12px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 380px;
    left: 15px;
    height:14px;
    width: 260px;
    font-size: 12px;
  }
`;


//* To BE Depricated from all pages
export const DetailsCardHeader = styled('div')`
  border-radius: 10px 10px 0px 0px;
  //border: solid 3px ${props => props.theme.colors.jet};
  margin-top:5px;
  padding:3px 10px 3px 3px;
  animation: ease 0.5s;
  transition: 0.5s;
  a{
    text-decoration:none;
    text-weight:bold;
    font-size:18px;
    color: ${props => props.theme.colors.powder_blue};
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    align-items:center;

  }

  a:hover{
    color: ${props => props.theme.colors.yellow};
  }

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {}

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {}
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {}

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {}
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {}

  //xxl 1400+
  @media (min-width: ${xxl}px) {}
`;

export const DetailsCardCategoryImg  = styled('img')`
width:40px;

@media (min-width: 768px) {
  width:60px;
}

@media (min-width: 1200px) {
  width:80px;
}
`;
export const DetailsCardBody = styled('div')`
  display:flex;
  flex-direction:column;
  border-radius: 0px 0px 10px 10px;
  padding:3px 10px 3px 3px;
  word-wrap:break-word;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {}

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {}
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {}

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {}
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {}

  //xxl 1400+
  @media (min-width: ${xxl}px) {}
`;
export const DetailsCardMetaData = styled('div')`
  display:flex;
  flex-direction:row;
  width:100%;
  justify-content: space-between;
  align-items:center;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    flex-direction:row;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    flex-direction:row;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {}

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {}
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {}

  //xxl 1400+
  @media (min-width: ${xxl}px) {}
  

`;
export const DetailsLeftColumn = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:center;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    max-height:50px;
    max-width:150px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    max-height:50px;
    max-width:150px;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    max-height:75px;
    max-width:175px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    max-height:75px;
    max-width:175px;
  }
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    max-height:100px;
    max-width:200px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    max-height:100px;
    max-width:200px;
  }
`;
export const DetailsRightColumn = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:right;
  width:100%;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    justify-content:center;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    justify-content:center;
  }
  
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    justify-content:center;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {}
  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {}

  //xxl 1400+
  @media (min-width: ${xxl}px) {}
`;
export const DetailsColumn = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:center;

  @media (min-width: 768px) {
    min-width:200px;
  }

  @media (min-width: 1200px) {}
`;
export const DetailsCenterColumn = styled('div')`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  width:100%;
  @media (min-width: 768px) {
    min-width:200px;
    width:100%;
  }

  @media (min-width: 1200px) {}
`;
export const DetailsPivot = styled('div')`


  @media (min-width: 768px) {}

  @media (min-width: 1200px) {}
`;
