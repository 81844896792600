import styled from "@emotion/styled";
import { fadeIn } from "../../uix/animation/keyframes";
import { fadeOut } from "../../uix/animation/keyframes";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
const min = 359;
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const SidePanelLeft = styled("div")`
  display: none;
`;
export const SidePanelRight = styled("div")`
  display: none;
`;

export const ResponsiveDisplay = styled("div")`
  background-attachment: fixed;
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(100vw - 100%);
  width: 100%;
  background-color: #000000;
  z-index: 50;

  @media (min-width: ${xs}px) and (max-width: ${String(min)}px) {
    //if the display side is less than
    display: none;
  }
`;
export const ResponsiveModal = styled("div")`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  z-index: 51;
  opacity: 0;
  visibility: hidden;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 300px;
    height: 200px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 300px;
    height: 200px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 600px;
    height: 400px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 600px;
    height: 400px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 620px;
    left: 0px;
    margin-top: -780px;
    width: 0px;
    height: 780px;
    border-radius: 34px;
    border: solid 2px #ffcc33;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 839px;
    left: 0px;
    margin-top: -780px;
    width: 0px;
    height: 780px;
    border-radius: 34px;
    border: solid 2px #ffcc33;
  }
`;
export const ProfileBlock = styled("div")`
  position: relative;
  z-index: 500;
  visibility: hidden;
  //extra small 0-575

  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    visibility: hidden;
    flex-direction: row;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    visibility: hidden;
    flex-direction: row;
    z-index: 500;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: -59px;
    top: 15px;
    left: 372px;
    height: 59px;
    width: 82px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    button {
      background-color: rgba(0, 0, 0, 0);
      color: #bbe5ed;
      height: 21px;
      width: 64px;
    }
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -59px;
    top: 15px;
    left: 372px;
    height: 59px;
    width: 82px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    button {
      background-color: rgba(0, 0, 0, 0);
      color: #bbe5ed;
      height: 21px;
      width: 64px;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -59px;
    top: 7px;
    left: 450px;
    height: 59px;
    width: 82px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    button {
      background-color: rgba(0, 0, 0, 0);
      color: #bbe5ed;
      height: 21px;
      width: 64px;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -59px;
    top: 7px;
    left: 450px;
    height: 59px;
    width: 82px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    button {
      background-color: rgba(0, 0, 0, 0);
      color: #bbe5ed;
      height: 21px;
      width: 64px;
    }
  }
`;
export const MainBlock = styled("div")`
  transition: margin-top 0.5s;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 50;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 12px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 16px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 20px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 20px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 20px;
  }
`;
export const FooterBlock = styled("div")`
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  height: 70px;
  min-height: 70px;
  z-index: 50;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 12px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 12px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 14px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 14px;
  }
`;
export const HeaderContainer = styled("div")`
  height: 16px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const MenuToggleContainer = styled("div")`
  text-align: center;
  display: flex;
  flex-direction: row;
  z-index: 100;
  min-height: 1vh;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }
  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const SearchMenuContainer = styled("div")`
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 100;
  transition: 0.3s;
  margin-top: -35px;
  @media (min-width: 360px), (max-height: 620px) {
  }

  @media (min-width: 768px) and (min-height: 621px) {
  }

  @media (min-width: 1200px) {
  }
`;
export const PageContainer = styled("div")`
  @media (min-width: 360px), (max-height: 620px) {
  }

  @media (min-width: 768px) and (min-height: 621px) {
  }

  @media (min-width: 1200px) {
  }
`;
export const ToggleLeftBorder = styled("div")`
  display: flex;
  height: 3px;
  border-top: solid 3px #333333;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px #333333;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px #333333;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 5px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 5px;
  }
`;
export const MenuToggleImg = styled("img")`
  cursor: pointer;
  display: flex;
  z-index: 100;
  margin-right: auto;
  margin-left: auto;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 28px;
    width: 320px;
    min-width: 320px;
    cursor: pointer;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 28px;
    width: 320px;
    min-width: 320px;
    cursor: pointer;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 47px;
    width: 714px;
    min-width: 714px;
    cursor: pointer;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 47px;
    width: 714px;
    min-width: 714px;
    cursor: pointer;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 57px;
    width: 880px;
    min-width: 880px;
    cursor: pointer;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 57px;
    width: 880px;
    min-width: 880px;
    cursor: pointer;
  }
`;
export const TogglePromptContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 320px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 320px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: -5px;
    width: 714px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -5px;
    width: 714px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -6px;
    width: 880px;
    margin-right: 110px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -6px;
    width: 880px;
    margin-right: 110px;
  }
`;
export const ToggleRightBorder = styled("div")`
  display: flex;
  height: 3px;
  border-top: solid 3px #333333;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px #333333;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px #333333;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.SiteImages.menu_toggle_border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
`;
export const MediaPanelLeftText = styled("div")`
  display: none;

  @media (min-width: 360px), (max-height: 620px) {
    display: block;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    width: 80%;
    text-align: center;
  }

  @media (min-width: 768px) and (min-height: 621px) {
    display: block;
    font-size: 16px;
  }

  @media (min-width: 1200px) {
    display: block;
    font-size: 20px;
  }
`;
export const MediaPanelRightText = styled("div")`
  display: none;

  @media (min-width: 360px), (max-height: 620px) {
    display: block;
    font-size: 12px;
    margin: 0px;
    padding: 0px;
    width: 80%;
    text-align: center;
  }

  @media (min-width: 768px) and (min-height: 621px) {
    display: block;
    font-size: 16px;
  }

  @media (min-width: 1200px) {
    display: block;
    font-size: 20px;
  }
`;
export const ContentContainer = styled("div")`
  display: block;
  position:relative;
  top:0px;
  width: 100%;
  height: 100%;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  margin-top: -200px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: -200px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
   
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
   
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
   
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
 
  }
`;
export const ContentForeground = styled("div")`
  z-index: 50;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const ContentBackground = styled("div")`
  z-index: 49;
  position: relative;
  //background-color: ${(props) => props.theme.colors.eerie_black};
  z-index: -1;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  //display: block;
  top: -10px;
  width: 100%;
  height: 1024px;
`;
export const ContentBackgroundLeft = styled("div")`
  background-image: url("${(props) => props.theme.themePath}${(props) => props.theme.media.SiteImages.content_bg_left}");
  background-repeat: no-repeat;
  position: absolute;
  display: inline-block;
  opacity:0.30;
  background-position: top left;
  z-index: auto;
  animation: ease 0.5s;
  transition: 0.5s;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: block;
    background-size: 100% 100%;
    width: 27%;
    height: 100vh;
    left: -20px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: block;
    background-size: 100% 100%;
    width: 27%;
    height: 100vh;
    left: 0px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 100% 100%;
    width: 27%;
    height: 100vh;
    left: 0px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: 100% 100%;
    width: 27%;
    height: 100vh;
    left: 0px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-size: 100% 100%;
    width: 27%;
    height: 100vh;
    left: 0px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    background-size: 100% 100%;
    width: 27%;
    height: 100vh;
    left: 0px;
  }
`;
export const ContentBackgroundCenter = styled("div")`
  z-index: -1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  display: none;
  flex-direction: column;
  animation: ease 0.5s;
  transition: 0.5s;
  height: 100%;
    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

      top: 120px;
      left: calc(50% - 180px);
      width: 100%;
      max-width: 360px;
      display: flex;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

      top: 100px;
      left: calc(50% - 250px);
      width: 500px;
      display: flex;
    
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

      top: 80px;
      left: calc(50% - 350px);
      width: 700px;
      display: flex;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      top: 70px;
      left: calc(50% - 400px);
      width: 800px;
      display: flex;

    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      top: 60px;
      left: calc(50% - 496px);
      width: 992px;
      display: flex;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      top: 60px;
      left: calc(50% - 496px);
      width: 992px;
      display: flex;
    }

    

    img{
      z-index: 2; 
      opacity: 0.27;
      width:100%;
      margin-left:auto;
      margin-right:auto;
      height: 100vh;
    }
  video {
    z-index: 0; 
    //extra small 0-575
    @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
      opacity: 0.9;
      width: 200vw;
      height: 100%;
    }

    //small 576-767
    @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
      opacity: 0.9;
      width: 200vw;
      height: 100%;
    }
    //medium 768-991
    @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
      opacity: 0.9;
      width: 700px;
      height: 642px;
      margin-top:-800px;
      margin-left:auto;
      margin-right:auto;
    }

    //large 992-1999
    @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
      opacity: 0.9;
      width: 700px;
      height: 642px;
      margin-top:-800px;
      margin-left:auto;
      margin-right:auto;
    }
    //xl 1200-1399
    @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
      opacity: 0.9;
      width: 700px;
      height: 642px;
      margin-top:-800px;
      margin-left:auto;
      margin-right:auto;
    }

    //xxl 1400+
    @media (min-width: ${xxl}px) {
      opacity: 0.9;
      width: 700px;
      height: 642px;
      margin-top:-800px;
      margin-left:auto;
      margin-right:auto;
  
    }


    }
  
`;
export const ContentBackgroundRight = styled("div")`
  background-image: url("${(props) => props.theme.themePath}${(props) =>
    props.theme.media.SiteImages.content_bg_right}");
  background-repeat: no-repeat;
  display: inline-block;
  position: absolute;
  background-position: top right;
  z-index: auto;
  opacity:0.30;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: block;
    right: -20px;
    background-size: 100% 100%;
    width: calc(100vw - 72%);
    height: 100vh;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: block;
    background-size: 100% 100%;
    width: 27%;//
    calc(100vw - 72%);
    height: 100vh;
    right: -5px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 100% 100%;
    width: 27%;
    //calc(100vw - 72%);
    height: 100vh;
    right: -5px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: 100% 100%;
    width: 27%;
    //;
    height: 100vh;
    right: -5px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-size: 100% 100%;
    width: 27%; 
    //calc(100vw - 72%);
    height: 100vh;
    right: -5px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    background-size: 100% 100%;
    width: 27%;
    //calc(100vw - 72%);
    height: 100vh;
    right: -5px;
  }
`;
export const Content = styled("div")`
  transition: height 0.5s;
  position: relative;
  overflow-y: hidden;
  overflow-x: visible;
  //transition: opacity 0.5s;
  height:100vh;

  @media (min-width: ${xs}px) and (max-width: ${sm}px) {
    display: block;
    width: 100%;
    left: 5px;
    margin-top: -1029px;
  }

  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    display: block;
    width: 100%;
    left: 0px;
    margin-top: -1031px;
  }

  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    width: 100%;
    left: 0px;
    margin-top: -1031px;
  }

  @media (min-width: ${lg}px) and (max-width: ${xl}px) {
    width: 100%;
    left: 0px;
    margin-top: -1031px;
  }

  @media (min-width: ${xl}px) and (max-width: ${xxl}px) {
    width: 100%;
    left: 0px;
    margin-top: -1031px;
  }

  @media (min-width: ${xxl}px) {
    width: 100%;
    left: 0px;
    margin-top: -1031px;
  }

`;
export const FooterRef = styled("div")`
  opacity: 1;
  animation: ease 0.5s;
  transition: 0.5s;
  position: absolute;
  top: calc(100vh ${(props) => props.isOpen ? " - " + props.closedModifierFooter + "px"  : " + " + props.openModifierFooter + "px"});
  display: flex;
  font-weight: bold;
  color: #ffcc33;
  background-image: url("${(props) => props.theme.themePath}/large/footer/footer-bg.png");
  z-index: 500;
  //margin-left: auto;
  //margin-right: auto;

  //extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top: calc(92vh ${(props) => props.isOpen ? " - " + props.closedModifierFooter + "px"  : " + " + props.openModifierFooter + "px"});
  width: 360px;
  height: 66px;
  background-size:  360px 66px;
  font-size: 12px;
  //left: calc(50% - 180px);
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top: calc(100vh ${(props) => props.isOpen ? " - " + props.closedModifierFooter + "px"  : " + " + props.openModifierFooter + "px"});
  width: 503px;
  height: 77px;
  background-size:  503px 77px;
  font-size: 12px;
  //left: calc(50% - 252px);
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  width: 768px;
  height: 88px;
  background-size:  768px 88px;
  font-size: 12px;
  //left: calc(50% - 384px);
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 768px;
  height: 88px;
  background-size:  768px 88px;
  font-size: 12px;
  //left: calc(50% - 384px);
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 904px;
  height: 103px;
  background-size:  904px 103px;
  font-size: 12px;
  //left: calc(50% - 452px);
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  width: 904px;
  height: 103px;
  background-size:  904px 103px;
  font-size: 12px;
  top: 94px; //calc(380px ${(props) => props.isOpen ? " - " + props.closedModifierFooter + "px"  : " + " + props.openModifierFooter + "px"});
  animation: ease 0.5s;
  transition: 0.5s;
  left: ${(props) => props.isOpen ? "calc(50% - 452px)"  : "-902px"};
}
`;
