import React, { useEffect, useReducer, useRef} from "react";
import MetaHead from "../../components/MetaHead";
import Cams from "../../components/Cams/index.js";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";
import { useRecoilState } from "recoil";
import { theme } from "../../themes/greyscaleTheme";
import { appConfig } from "../../config/data/appConfig.js";
import { useRequestList } from "../../hooks/useRequestList.js";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll.js";
import {mainMenuState,mainSearchState,searchTypeState} from "../../config/state/uiState";
import { ListContainer, PageContent, PageSpinner, PageEmpty } from "../PageListStyle.js";

import { SearchList, SearchEmpty } from "./SearchResultsStyle.js";
import SearchItem from "../SearchItem/index.js";

export default function SearchResults(props) {
 
  const listReducer = (state, action) => {
    switch (action.type) {
      case "STACK_LIST":
        return {
          ...state,
          items: action.items, //For search we don't want to stack the list, we want to replace it
        };
      case "FETCHING_LIST":
        return { 
          ...state, 
          fetching: action.fetching 
        };
      case 'RELOAD':
          return { items: [], fetching: true };
      default:
        return state;
    }
  };

  const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        return { 
          ...state, 
          page: state.page + 1 
        };
      case "RESET_PAGE":
        return { 
          ...state, 
          page: state.page 
        };
      default:
        return state;
    }
  };
  let bottomBoundaryRef = useRef(null);

  const [menuState, setMenuState] = useRecoilState(mainMenuState);
  const [searchState, setSearchState] = useRecoilState(mainSearchState);
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  const [searchResultsData, searchResultsDispatch] = useReducer(listReducer,{ 
    items: [], 
    fetching: true }
  );
  const searchList = useRef();

  useRequestList(
    pager, 
    searchResultsDispatch, 
    appConfig.apiUrls.searchSite.replace("{{subType}}", searchState.searchType).replace("{{searchCriteria}}", searchState.activeSearch),
    10
    );

  useInfiniteScroll(
    bottomBoundaryRef, 
    pagerDispatch
  );

  useEffect(() => {
    if (searchState.activeSearch !== "") {
      searchList.current.style.visibility = "visible";
    }

    searchResultsDispatch({ type: 'RELOAD_LIST' });
  }, [searchState.activeSearch]);

  return (
    <main>
      <MetaHead
        page="SearchResults"
        title="Search Results on CoreyLines.com"
        description="Search results for stuff and things..."
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PageContent>
        <Cams/>
        <DisplayAdBanner/>
        <ListContainer name="ListContainer">
        <SearchList ref={searchList}>
          {searchResultsData.items.map((searchResult, index) => {
            const searchObject = {};
            searchObject.id = searchResult.id;
            searchObject.title = searchResult.title;
            searchObject.type = searchResult.type;
            searchObject.subtype = searchResult.subtype;
            searchObject.url = searchResult.url;
            searchObject.rowBG = "/large/bg-tiles/search-hud.png";
            return (
              <div key={index}>
              {index >= 0 && (
                <SearchItem
                  subtype={searchObject.subtype}
                  url={searchObject.url}
                  title={searchObject.title}
                  rowBG={searchObject.rowBG}
                  index
                />
              )}
              </div>
            );
          })}
        </SearchList>
        </ListContainer>
        {searchResultsData.items.length === 0 && (
          <SearchEmpty>Your Search For<br/><br/> <strong>{searchState.activeSearch}</strong><br/><br/>Returned No Results</SearchEmpty>
        )}
        {searchResultsData.fetching && (
        <PageSpinner>
          <img
            src={
              process.env.PUBLIC_URL + theme.themePath + theme.media.MiscImages.spinner
            }
            alt="Spinner"
          />
        </PageSpinner>
      )}
        {searchResultsData.items.length >= 0 && (
          <div
            id="page-bottom-boundary"
            ref={bottomBoundaryRef}
            style={{ border: "1px solid rgb(255, 0, 0,0.0)" }}
          ></div>
        )}
      </PageContent>
    </main>
  );
}
