import React, { useEffect, useState, useRef} from 'react';
import { images } from '../../images.js'; 
import { theme } from '../../themes/greyscaleTheme';
import { PreloadMessage } from './preloadImagesStyle.js';

export const preloadImagesComponent = (Component) => {
  return props => {
    const [imgsLoaded, setImgsLoaded] = useState(false);
    const imgCount = useRef(0)

    const cacheImages =  (srcArray) => {
      const promises =  srcArray.map((src,index) =>{

        imgCount.current = index;
        //console.log("Loading image # " +  imgCount.current);
          return new Promise(function (resolve, reject){
            const img = new Image();
            img.src = src;
            img.onload = resolve();
            img.onError = reject();
          });
      });

      Promise.all(promises)
      .then(() => {
        setTimeout(() => setImgsLoaded(true), 2000);
      });

    }

    useEffect(() => {
      cacheImages(images);
    },[]);


    if(imgsLoaded === true){
      return <Component {...props} />
    }else{
      return <PreloadMessage>Loading images...</PreloadMessage>;
    }


  }
}