import React from "react";

import {
CamRow,
Camera1,
Camera2
} from "./CamsStyle.js";

import { theme } from "../../themes/greyscaleTheme";

export default function Cams(props) {

    return (
        <CamRow name="CamRow">
            <Camera1>
                <img
                    src={
                        process.env.PUBLIC_URL +
                        theme.themePath +
                        "/large/page-welcome/camera1.gif"
                    }
                    alt=""
                />
            </Camera1>
            <Camera2>
                <img
                    src={
                    process.env.PUBLIC_URL +
                    theme.themePath +
                    "/large/page-welcome/camera2.gif"
                    }
                    alt=""
                />
            </Camera2>
        </CamRow>
    );
}