import styled from "@emotion/styled";

export const SignInForm = styled("div")`
display: flex;
  flex-direction: row;
  width:100%;
`

export const SignInUserFormContainer = styled("div")`
  border:solid 1px white;
  width:100%;
  section{
    width:100%;
  }
`;