import React, { useEffect, useReducer, useRef } from "react";
import { useRecoilState } from "recoil";
import { mainMenuState } from "../../config/state/uiState";
import { useRequestList } from "../../hooks/useRequestList.js";
import { appConfig } from "../../config/data/appConfig.js";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll.js";
import { theme } from "../../themes/greyscaleTheme";
import MetaHead from "../../components/MetaHead";
import Cams from "../../components/Cams";
import ContentBox from "../../components/ContentBox";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";
import { PageSpinner, PageEmpty } from "../PagesStyle";
import { PanelPageRoot, PanelFlexContainer } from "../PanelPageStyle";
import {pageReducer, listReducer} from "../reducer.js";
import { lg } from "../../app/ResponsiveLayout/ResponsiveBreakpoints.js";

import {
  ContentContainer,
  ContentTitle,
  ContentBody,
  ContentGraphic,
} from "./AboutStyle.js";


export default function About(props, { path }) {
  const [menuState, setMenuState] = useRecoilState(mainMenuState);
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  const [aboutData, aboutDispatch] = useReducer(listReducer, {
    items: [],
    fetching: true,
  });

  useRequestList(
    pager, 
    aboutDispatch,
    appConfig.apiUrls.aboutList,
    10
  );

  let bottomBoundaryRef = useRef(null);
  useInfiniteScroll(bottomBoundaryRef, pagerDispatch);

  useEffect(() => {
    setMenuState({ activePath: "about" });
  }, [setMenuState]);

  return (
    <section>
      <MetaHead
        page="About"
        title="About Corey Lines"
        description="The condensed autobiographical context for the posted stuff..."
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PanelPageRoot id="PanelPageRoot">
        {/* {<AnimatedBackground />} */}
        <Cams />
        <DisplayAdBanner/>
        <PanelFlexContainer>
        <ContentBox>
            <ContentContainer bgColor={`rgba(102,255,255, 0.1)`}>
              <ContentTitle>About Me</ContentTitle>
              <ContentBody>
              <p>I wear many hats: husband, father, armchair<br/> philosopher, part-time sociologist, <br/>Internet enthusiast, web <br/>developer, and quality engineer.</p>
              <p>I thrive on both the creative and logical aspects of web application development, from overall design to frontend experiences and backend services.</p>
              </ContentBody>
              <ContentGraphic>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    theme.themePath +
                    "/large/page-welcome/avatar.png"
                  }
                  alt="Corey"
                />
              </ContentGraphic>
            </ContentContainer>
        </ContentBox>
        <ContentBox>
          <ContentContainer bgColor={`rgba(0,0,255, 0.1)`}>
          <ContentTitle>What You’ll Find Here</ContentTitle>
          <ContentBody>
          <p>This space serves as a repository for my interests:
        <ul>
        <li>Technical Insights: Articles on programming, software development, and emerging technologies.</li>
        <li>Philosophical Musings: Random thoughts and reflections.</li>
        <li>Music Interests: Tunes that resonate with me.</li>
        <li>Personal Opinions: Everything here, unless linked externally, reflects my perspective or intriguing ideas that have captured my attention.</li>
        </ul>
        </p>
          </ContentBody>
          </ContentContainer>
        </ContentBox>
        <ContentBox>
          <ContentContainer bgColor={`rgba(255,0,0, 0.1)`}>
            <ContentTitle>Beyond Technology</ContentTitle>
            <ContentBody>
          <p>
        While technology fuels my professional life, I’m equally passionate about broader topics:
        <ul>
        <li>Politics: I pay close attention to political landscapes and their impact on society.</li>
        <li>Science: Curiosity drives me to explore scientific discoveries and breakthroughs.</li>
        <li>Culture: I appreciate cultural nuances and their influence on our lives.</li>
        <li>Social and Environmental Issues: Advocacy for positive change is close to my heart.</li>
        </ul>
        </p>
          </ContentBody>
            </ContentContainer>
        </ContentBox>
        <ContentBox>
        <ContentContainer bgColor={`rgba(102,255,255,0.1)`}>
        <ContentTitle>Showcasing Skills</ContentTitle>
        <ContentBody>
        <p>Beyond content, this site also showcases my design and development prowess. It’s where I experiment, create, and share.<br/><br/>
        Feel free to reach out via email at corey.lines@protonmail.com or connect through the social media and developer resources linked at the bottom of this page.
        </p>
        <p>Thank you for joining me on this journey!</p>
          </ContentBody>
          </ContentContainer>
        </ContentBox>
        </PanelFlexContainer> 
      </PanelPageRoot>
      {/* <PanelPageRoot>
        <Cams/>
      <DisplayAdBanner/>
      <PanelFlexContainer>
      <ContentBox
          width={lg} 
          height={`390`}
          bgColor={`rgba(102,255,255, 0.1)`}
          textRatio={`60%`}
        >
        <Title>About Me</Title>
            <IntroContent textRatio={`60%`}>
            <p>I wear many hats: husband, father, armchair philosopher, part-time sociologist, Internet enthusiast, web developer, and quality engineer. My journey has led me through diverse experiences—learning, researching, instructing, facilitating, web development, graphic design, freelancing, consulting, and most recently, quality engineering. I thrive on both the creative and logical aspects of web application development, from overall design to frontend experiences and backend services.</p>
            </IntroContent>
            <IntroGraphic>
              <img
                src={
                  process.env.PUBLIC_URL +
                  theme.themePath +
                  "/large/page-welcome/avatar.png"
                }
                alt="Corey"
              />
            </IntroGraphic>
        </ContentBox>
        <ContentBox
          width={lg} 
          height={`390`}
          bgColor={`rgba(102,255,255, 0.1)`}
          textRatio={`100%`}
        >
        <Title>Beyond Technology</Title>
            <IntroContent>
       
            </IntroContent>
        </ContentBox>
        <ContentBox
          width={lg} 
          height={`390`}
          bgColor={`rgba(102,255,255, 0.1)`}
          textRatio={`100%`}
        >
        <Title>What You’ll Find Here</Title>
            <IntroContent>
            <p>This space serves as a repository for my interests:
        <ul>
        <li>Technical Insights: Articles on programming, software development, and emerging technologies.</li>
        <li>Philosophical Musings: Random thoughts and reflections.</li>
        <li>Music Interests: Tunes that resonate with me.</li>
        <li>Personal Opinions: Everything here, unless linked externally, reflects my perspective or intriguing ideas that have captured my attention.</li>
        </ul>
        </p>
            </IntroContent>
        </ContentBox>
        <ContentBox
          width={lg} 
          height={`390`}
          bgColor={`rgba(102,255,255, 0.1)`}
          textRatio={`60%`}
        >
        <Title>Showcasing Skills</Title>
        <IntroContent textRatio={`60%`}>
            <p>Beyond content, this site also showcases my design and development prowess. It’s where I experiment, create, and share.</p>
        <h3>Get in Touch</h3>
        <p>Feel free to reach out via email at corey.lines@protonmail.com or connect through the social media and developer resources linked at the bottom of this page.
        </p>
        <p>Thank you for joining me on this journey!</p>
            </IntroContent>
        </ContentBox>
        {aboutData.fetching && (
          <PageSpinner>
            <img
              src={
                process.env.PUBLIC_URL +
                theme.themePath +theme.media.MiscImages.spinner
              }
              alt="Spinner"
            />
          </PageSpinner>
        )}
        {aboutData.items.length === 0 && <PageEmpty>Fetching...</PageEmpty>}
        {aboutData.items >= 0 && (
          <div
            id="page-bottom-boundary"
            style={{ border: "1px solid rgb(6, 6, 6, 0.0)" }}
            ref={bottomBoundaryRef}
          ></div>
        )}
        </PanelFlexContainer>
      </PanelPageRoot> */}
    </section>
  );
}
