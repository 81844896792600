//import { auth } from '../../lib/firebase';
import { signOut } from "../../lib/auth/signOut.js";
import React from "react";
import { UserContext } from '../../lib/context';
import { SignInUserForm } from '../../components/SignInUserForm';
import { useContext, useEffect, useRef } from 'react';

import {
  SignOutContainer,
  SignOutLink
} from './SignOutStyle';

export default function SignOutButton(){
  const {user, username} = useContext(UserContext);
  const userForm = useRef();
  
  const signOutWithGoogle =  () => {
     signOut()
  }

  useEffect(()=>{
    //console.log('username**:' ,username);
   // userForm.current.style.display = "block";
  },[])

  return(
    <SignOutContainer>
        <SignOutLink> <button onClick={()=>{signOutWithGoogle()}}>Sign Out</button> </SignOutLink>
    </SignOutContainer>
  )
}