import styled from "@emotion/styled";

export const PreloadMessage = styled("div")`
margin-right: auto;
margin-left: auto;
display: block;
width: 100vw;
height: 300px;
text-align: center;
top: 270px;
position: relative;
`;