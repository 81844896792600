import { useEffect, useCallback, useRef, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import request from '../../utils/request';
import { MyCombinatorData } from './MyCombinatorData';

export const useMyCombinatorData = (dispatch) => {
  const requestStoryIds = useRef(null);
  const requestStoryDetails = useRef(null);

  const getData = useCallback( () => {
    request({
      method: 'get',
      url: `${requestStoryIds.current}`
    })
    .then(combinators => {
      console.log("combinators", combinators);
      dispatch({ type: 'STACK_MYCOMBINATOR',  combinators});
      dispatch({ type: 'FETCHING_MYCOMBINATOR', fetching: false });

    })
    .catch(e => {
      console.log('FETCH MYCOMBINATOR - ERROR: ', e);
      dispatch({ type: 'FETCHING_MYCOMBINATOR', fetching: false });
    })
},[dispatch]);

  useEffect(() => {
    requestStoryIds.current = MyCombinatorData.apiUrls.get500Stories;
    requestStoryDetails.current = MyCombinatorData.apiUrls.getStoryById;
    getData();
  }, [dispatch, getData])
}
