export const bottomExpanderConfig = {
  responsiveBreak: [767, 1199],
  themePath: "/themes/greyscaleTheme",
  frames: [
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander001.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander002.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander003.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander004.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander005.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander006.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander007.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander008.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander009.png",
    "/themes/greyscaleTheme/large/three-portal/bottom-expander/bottom-expander010.png",
  ],
};
