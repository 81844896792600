import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
import { theme } from "../../themes/greyscaleTheme";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/

export const Container = styled("div")`
  display:block;
  border-radius: 27px 27px 27px 27px;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 330px;
    height: 400px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 526px;
    height: 410px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 730px;
    height: 550px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 800px;
    height: 600px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 800px;
    height: 600px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 800px;
    height: 600px;  }
`;

/** TOP **/
export const TopRow = styled("div")`


  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {

  }
`;
export const TopLeft = styled("div")`
  background-image: url("${(props) => props.theme.themePath}/large/components/content-box/left-top.png");
  background-repeat: no-repeat;  
  animation: ease 0.5s;
  transition: 0.5s;
  display: flex;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  width:165px;
  height:190px;
  background-size: 165px 190px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  width:263px;
  height:190px;
  background-size: 263px 190px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  width: 365px;
  height:264px;
  background-size: 365px 264px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}

//xxl 1400+
@media (min-width: ${xxl}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
`;
export const TopRight = styled("div")`
  background-image: url("${(props) => props.theme.themePath}/large/components/content-box/right-top.png");
  background-repeat: no-repeat;  
  animation: ease 0.5s;
  transition: 0.5s;
  display: flex;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  width:165px;
  height:190px;
  background-size: 165px 190px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  width:263px;
  height:190px;
  background-size: 263px 190px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  width: 365px;
  height:264px;
  background-size: 365px 264px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}

//xxl 1400+
@media (min-width: ${xxl}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
`;

/** BOTTOM **/
export const BottomRow = styled("div")`

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {

  }
`;
export const BottomLeft= styled("div")`
  background-image: url("${(props) => props.theme.themePath}/large/components/content-box/left-bottom.png");
  background-repeat: no-repeat;  
  animation: ease 0.5s;
  transition: 0.5s;
  display: flex;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  width:165px;
  height:190px;
  background-size: 165px 190px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  width:263px;
  height:190px;
  background-size: 263px 190px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  width: 365px;
  height:264px;
  background-size: 365px 264px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}

//xxl 1400+
@media (min-width: ${xxl}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
`;

export const BottomRight= styled("div")`
      background-image: url("${(props) => props.theme.themePath}/large/components/content-box/right-bottom.png");
    background-repeat: no-repeat;  
    animation: ease 0.5s;
  transition: 0.5s;
  display: flex;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  width:165px;
  height:190px;
  background-size: 165px 190px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  width:263px;
  height:190px;
  background-size: 263px 190px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  width: 365px;
  height:264px;
  background-size: 365px 264px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}

//xxl 1400+
@media (min-width: ${xxl}px) {
  width: 400px;
  height:289px;
  background-size: 400px 289px;
}
`;

export const BackgroundContainer = styled("div")`
position: relative;
display: flex;
flex-direction: column;


  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 330px;
    div {
    display:flex;
    flex-direction: row;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */ 
      img{
        width:165px;
      }
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      img{
        width:165px;
      }
    }

  }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 526px;
    div {
    display:flex;
    flex-direction: row;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */ 
      img{
        width:263px;
      }
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      img{
        width:263px;
      }
    }

  }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 730px;
    div {
    display:flex;
    flex-direction: row;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */ 
      img{
        width:365px;
      }
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      img{
        width:365px;
      }
    }

  }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 800px;
    div {
    display:flex;
    flex-direction: row;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */ 
      img{
        width:400px;
      }
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      img{
        width:400
      }
    }

  }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 800px;
    div {
    display:flex;
    flex-direction: row;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */ 
      img{
        width:400px;
      }
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      img{
        width:400px;
      }
    }

  }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 800px;
    div {
    display:flex;
    flex-direction: row;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */ 
      img{
        width:400px;
      }
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      img{
        width:400px;
      }
    }

  }
  }
  `;

  export const ForegroundContainer = styled("div")`
    position: relative;
    border-radius: 27px 27px 27px 27px;
    padding: 27px;
    //background-color: ${(props) => props.bgColor};

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: -700px;
    left: 0px;
    height:552px;
    width:${sm}px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: -722px;
    left: -22px;
    height: 642px;
    width: 812px;
    padding:49px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: -722px;
    left: -22px;
    height: 642px;
    width: 812px;
    padding:49px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: -722px;
    left: -22px;
    height: 642px;
    width: 812px;
    padding:49px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: -722px;
    left: -22px;
    height: 642px;
    width: 812px;
    padding:49px;
  }
  `;