import styled from "@emotion/styled";

export const ProfileContainer = styled("div")`
display:flex;
flex-direction:column;
margin-right:auto;
margin-left:auto;
`

export const ProfileBlock = styled("div")`
display:flex;
flex-direction:column;
margin-right:auto;
margin-left:auto;
`