export const appConfig = {
  version: "0.5.0",
  apiUrls: {
    welcomesList:
      "https://coreylines.com/byt3m3/wp-json/wp/v2/welcomes?page={{page}}&per_page={{numResults}}",
    welcomeDetails:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/welcomes?slug={{slug}}",
    aboutList:
      "https://coreylines.com/byt3m3/wp-json/wp/v2/about?page={{page}}&per_page={{numResults}}&orderby=date&order=asc",
    aboutDetails:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/about?slug={{slug}}",
    shareList:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/share?page={{page}}&per_page={{numResults}}",
    shareDetails:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/share?slug={{slug}}",
    resourcesListRaw:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/resources?page={{page}}&per_page={{numResults}}&order=desc&_fields=id,date_gmt,modified_gmt,slug,status,acf",
    resourcesList:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/resources?page={{page}}&per_page={{numResults}}&order={{order}}&orderby={{orderBy}}&after={{after}}&before={{before}}",
    resourceDetails:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/resources?slug={{slug}}",
    searchSite:
      "https://www.coreylines.com/byt3m3/wp-json/wp/v2/search?search={{searchCriteria}}&per_page={{numResults}}&subtype={{subType}}",
    withImagesList:
      "https://coreylines.com/byt3m3/wp-json/wp/v2/image",
    withVideosList:
      "https://coreylines.com/byt3m3/wp-json/wp/v2/video"
    }, 
  analytics: {
    trackingId: "",
  },
};
