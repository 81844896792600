import styled from "@emotion/styled";
//import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
//import { theme } from "../../themes/greyscaleTheme";


export const SearchEmpty = styled("div")`
  text-align: center;
  font-family: "ROG";
  font-size: 14px;
  display: block;
  height: 50vh;
  margin: auto;
  padding: 70px 0;
`;

export const SearchFilterContainer = styled("div")`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 5px;
  visibility: hidden;

  @media (min-width: 768px) {
    visibility: hidden;
    display: block;
    margin-top: 0px;
  }

  @media (min-width: 1200px) {
    visibility: hidden;
    display: block;
    margin-top: 0px;
  }
`;

export const SearchList = styled("div")`
  visibility: hidden;

  //mobile portrait
  @media (min-width: 360px) {

  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {
 
  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {

  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {

  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {

  }
`;


/*  Break Point Summary 
breakpoints: {
  xs: 360,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/