import { RecoilRoot } from 'recoil';
import { BrowserRouter } from "react-router-dom";
//import * as firebase from 'firebase/app';
import React from 'react';
//import 'firebase/analytics';
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/functions';
// import 'firebase/storage';
import AppInterface from './app/AppInterface';
import WindowDimensionsProvider from './app/WindowDimensionsProvider';
import './App.css';

import { preloadImagesComponent } from './hoc/preloadImagesComponent/index.js';


// var firebaseConfig = {
//     apiKey: "AIzaSyAft8hLLlZVuxbjLQqo9l1D7dqFd00_tfg",
//     authDomain: "coreylines-com.firebaseapp.com",
//     projectId: "coreylines-com",
//     storageBucket: "coreylines-com.appspot.com",
//     messagingSenderId: "79445118081",
//     appId: "1:79445118081:web:8c29974d670483d6f39180",
//     measurementId: "G-DL1SK2NJG8"
//   };

//   firebase.initializeApp(firebaseConfig);
  //firebase.analytics();
 

function App() {
  const PreloadAppInterface = preloadImagesComponent(AppInterface);
  return (
        <BrowserRouter><RecoilRoot><WindowDimensionsProvider><PreloadAppInterface/></WindowDimensionsProvider></RecoilRoot></BrowserRouter>
  );
}
export default App;