import styled from "@emotion/styled";

export const SignOutContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-right:auto;
  margin-left:auto;
  margin-top:50px;
  width:200px;
  height: 40px;
  text-align:center;
`;

export const SignOutLink = styled("div")`

`;
export const SignOutPhoto = styled("div")`
  img{
    width:48px;
    height:48px;
    border-radius:24px;
  }
  margin-right:auto;
  margin-left:auto;
  display:block;
  width:48px;
`;
