export const images = [
"./themes/greyscaleTheme/spinner/new-spinner.gif",
"./themes/greyscaleTheme/large/bg-tiles/bottom-right-frame.png",
"./themes/greyscaleTheme/large/bg-tiles/menu-bg-001.jpg",
"./themes/greyscaleTheme/large/bg-tiles/menu-bg-002.jpg",
"./themes/greyscaleTheme/large/bg-tiles/content-bg-001.jpg",
"./themes/greyscaleTheme/large/bg-tiles/content-bg-002.jpg",
"./themes/greyscaleTheme/large/bg-tiles/content-hud.png",
"./themes/greyscaleTheme/large/bg-tiles/details-panel.png",
"./themes/greyscaleTheme/large/bg-tiles/hud-landscape.png",
"./themes/greyscaleTheme/large/bg-tiles/hud-portrait.png",
"./themes/greyscaleTheme/large/bg-tiles/led-bg-box.png",
"./themes/greyscaleTheme/large/bg-tiles/led-content-box.png",
"./themes/greyscaleTheme/large/bg-tiles/left-side-decoration.png",
"./themes/greyscaleTheme/large/bg-tiles/left-top-decoration.png",
"./themes/greyscaleTheme/large/bg-tiles/links-hud.png",
"./themes/greyscaleTheme/large/bg-tiles/media-hud.png",
"./themes/greyscaleTheme/large/bg-tiles/blue-hud.png",
"./themes/greyscaleTheme/large/bg-tiles/sitemap-h.png",
"./themes/greyscaleTheme/large/bg-tiles/sitemap-v.png",
"./themes/greyscaleTheme/large/bg-tiles/top-left-frame.png",
"./themes/greyscaleTheme/large/bg-tiles/yellow-hud.png",

"./themes/greyscaleTheme/large/components/content-box/bottom-join.png",
"./themes/greyscaleTheme/large/components/content-box/left-bottom.png",
"./themes/greyscaleTheme/large/components/content-box/left-join.png",
"./themes/greyscaleTheme/large/components/content-box/right-bottom.png",
"./themes/greyscaleTheme/large/components/content-box/right-join.png",
"./themes/greyscaleTheme/large/components/content-box/top-join.png",
"./themes/greyscaleTheme/large/components/content-box/left-top.png",
"./themes/greyscaleTheme/large/components/content-box/right-top.png",
"./themes/greyscaleTheme/large/content-categories/ai-category.png",
"./themes/greyscaleTheme/large/content-categories/css-category.png",
"./themes/greyscaleTheme/large/content-categories/dev-category.png",
"./themes/greyscaleTheme/large/content-categories/gallery-category.png",
"./themes/greyscaleTheme/large/content-categories/html-category.png",
"./themes/greyscaleTheme/large/content-categories/javascript-category.png",
"./themes/greyscaleTheme/large/content-categories/music-category.png",
"./themes/greyscaleTheme/large/content-categories/pm-category.png",
"./themes/greyscaleTheme/large/content-categories/qe-category.png",
"./themes/greyscaleTheme/large/content-categories/react-category.png",
"./themes/greyscaleTheme/large/content-categories/recipe-category.png",
"./themes/greyscaleTheme/large/content-categories/scitech-category.png",
"./themes/greyscaleTheme/large/content-categories/socio-category.png",
"./themes/greyscaleTheme/large/content-categories/ssr-category.png",
"./themes/greyscaleTheme/large/content-categories/thought-category.png",
"./themes/greyscaleTheme/large/content-categories/uncat-category.png",
"./themes/greyscaleTheme/large/content-categories/wordpress-category.png",

"./themes/greyscaleTheme/large/content-categories/post-item.png",
"./themes/greyscaleTheme/large/content-categories/resource-item.png",
"./themes/greyscaleTheme/large/content-categories/posts.jpg",
"./themes/greyscaleTheme/large/content-categories/post-type.jpg",
"./themes/greyscaleTheme/large/content-categories/resources.jpg",

"./themes/greyscaleTheme/large/menu/button-info.png",
"./themes/greyscaleTheme/large/menu/button-info-active.png",
"./themes/greyscaleTheme/large/menu/prompt-hud.png",
"./themes/greyscaleTheme/large/menu/menu-bg/menu-bg-left.png",
"./themes/greyscaleTheme/large/menu/menu-bg/menu-bg-center.png",
"./themes/greyscaleTheme/large/menu/menu-bg/menu-bg-right.png",
"./themes/greyscaleTheme/large/menu/button/button_active.png",
"./themes/greyscaleTheme/large/menu/button/button_clicked.png",
"./themes/greyscaleTheme/large/menu/button/button00.png",
"./themes/greyscaleTheme/large/menu/button/button01.png",
"./themes/greyscaleTheme/large/menu/button/button02.png",
"./themes/greyscaleTheme/large/menu/button/button03.png",
"./themes/greyscaleTheme/large/menu/button/button04.png",
"./themes/greyscaleTheme/large/menu/button/button05.png",
"./themes/greyscaleTheme/large/menu/button/button06.png",
"./themes/greyscaleTheme/large/menu/button/button07.png",

"./themes/greyscaleTheme/drip-05.gif",
"./themes/greyscaleTheme/steam-05.gif",

"./themes/greyscaleTheme/large/menu-toggle/toggle_back_default.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_back_over.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_bg_narrow.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_bg_wide.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_burger_default.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_burger_over.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_close_default.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_close_over.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_forward_default.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_forward_over.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_search_default.png",
"./themes/greyscaleTheme/large/menu-toggle/toggle_search_over.png",

"./themes/greyscaleTheme/large/page-welcome/camera1.gif",
"./themes/greyscaleTheme/large/page-welcome/camera2.gif",
"./themes/greyscaleTheme/large/page-welcome/panel-ad-placeholder.gif",
]