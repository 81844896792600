import React from "react";
import { Link } from "react-router-dom";
import { getCategoryImage, getCategoryAlt } from "../../utils/getCategoryImage";
import { theme } from "../../themes/greyscaleTheme";
import {
  ListItemContainer,
  ListItemType,
  ListItemTitleText,
  ListItemCategory,
  ListItemTitle,
  ListItemCategoryName,
  ListItemPublishDate,
  ListItemModifyDate,
} from "../ShareStyle.js";

export default function ShareItem(props) {
  const { slug, acfTitle, acfCategory, modifiedGmt, dateGmt, route, index } =
    props;
console.log("dateGmt",dateGmt);
  return (
    <ListItemContainer key={index} id={`ListItem${index}`}>
      <ListItemType>POST</ListItemType>
        <ListItemCategoryName
          dangerouslySetInnerHTML={{
            __html: getCategoryAlt(acfCategory),
          }}
        />
        <ListItemTitle>
          <Link to={`/${route}/${slug}`}>
            <ListItemTitleText dangerouslySetInnerHTML={{ __html: acfTitle }} />
          </Link>
        </ListItemTitle>  
        
        <ListItemPublishDate
          dangerouslySetInnerHTML={{
            __html: `Listed: ${new Date(dateGmt).toDateString()}`,
          }}
        ></ListItemPublishDate>
        <ListItemModifyDate
          dangerouslySetInnerHTML={{
            __html: `Updated: ${new Date(modifiedGmt).toDateString()}`,
          }}
        ></ListItemModifyDate>
        <ListItemCategory>
          <img
            src={`${process.env.PUBLIC_URL}${
              theme.themePath
            }/large/content-categories/${getCategoryImage(acfCategory)}`}
            alt={`${getCategoryAlt(acfCategory)}`}
          />
        </ListItemCategory>
    </ListItemContainer>
  );
}
/**
 *  <ListRow key={index} id={`ResourceRow${index}`}>
                <ListItemCategory>
                  <img
                    src={`${process.env.PUBLIC_URL}${
                      theme.themePath
                    }/large/content-categories/${getCategoryImage(
                      acf.resource_category
                    )}`}
                    alt={`${getCategoryAlt(acf.resource_category)}`}
                  />
                </ListItemCategory>
                <ListPivot>
                  <ListItemTitle>
                    <Link to={`/resource/${slug}`}>
                      <div
                        dangerouslySetInnerHTML={{ __html: acf.resource_title }}
                      />
                    </Link>
                  </ListItemTitle>
                  <ListItemColumn>
                    <ListItemCategoryName
                      dangerouslySetInnerHTML={{
                        __html: getCategoryAlt(acf.resource_category),
                      }}
                    />
                    <ListItemPublishDate
                      dangerouslySetInnerHTML={{
                        __html: `Listed: ${new Date(date_gmt).toDateString()}`,
                      }}
                    ></ListItemPublishDate>
                    <ListItemModifyDate
                      dangerouslySetInnerHTML={{
                        __html: `Updated: ${new Date(
                          modified_gmt
                        ).toDateString()}`,
                      }}
                    ></ListItemModifyDate>
                  </ListItemColumn>
                </ListPivot>
              </ListRow>
 */
