import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const FooterContainer = styled("div")`
  font-family: 'ROG';
  position: absolute;
  display: flex;
  top: 204px;
  justify-content: space-around;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  width: 260px;
    left: calc(50% - 132px);
    font-size:8px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 400px;
    left: calc(50% - 200px);
    font-size:8px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 560px;
    left: calc(50% - 280px);
    font-size:10px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 560px;
  left: calc(50% - 280px);
  font-size:10px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 560px;
    left: calc(50% - 280px);
    font-size:10px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  width: 560px;
    left: calc(50% - 280px);
    font-size:10px;
}
`;
export const TermsOfUse = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`

export const PrivacyPolicy = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`

export const SiteMap = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`

export const ContactDetails = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`



