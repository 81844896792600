//React libraries
import React, { useRef, useEffect, useState } from "react";
import { useRecoilState } from "recoil";

//Firebase
//import { auth } from '../../lib/firebase.js';
//import { useAuthState } from 'react-firebase-hooks/auth';

//Emotion
import { css } from "@emotion/react";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../themes/greyscaleTheme";

//Data source components
import { withImagesComponent } from "../../hoc/withImagesComponent/index.js";
import { withVideosComponent } from "../../hoc/withVideosComponent/index.js";

//Recoil Atoms
import {
  appViewState,
  menuOpenState,
  openFooterState,
  appDebugState,
  basicUserState,
  footerOpenState,
} from "../../config/state/uiState";

//Hooks
import useModal from "../../hooks/useModal";

//Base Routes
import RouterControl from "../RouterControl/index.js";
import { useWindowDimensions } from "../../app/WindowDimensionsProvider";
import { getFooterOffset } from "../../utils/FooterOffset";
//Custom components
//import Menu from "../../components/Menu";
import Footer from "../../components/Footer";
import MenuBase from "../../components/MenuBlue";
import MenuToggle from "../../components/MenuToggle";
import MenuHeader from "../../components/MenuHeader/index.js";
import SearchDropdown from "../../components/SearchDropdown";
import TogglePrompt from "../../components/TogglePrompt";
import ProfileBox from "../../components/ProfileBox";
import LoginProfile from "../../components/LoginProfile";
import Modal from "../../components/Modal";

import TabletScreenLeft from "../../components/TabletScreenLeft";
import TabletScreenRight from "../../components/TabletScreenRight";

import LeftPanel from "../LeftPanel/index.js";
import RightPanel from "../RightPanel/index.js";

//Custom CSS and CSS Animations

import "../../components/Modal/modal.css";

//Styled Component Definitions
import {
  ResponsiveDisplay,
  ProfileBlock,
  MainBlock,
  TogglePromptContainer,
  ContentContainer,
  Content,
  ContentBackgroundLeft,
  ContentBackgroundRight,
  ContentBackgroundCenter,
  ContentForeground,
  ContentBackground,
  SidePanelLeft,
  SidePanelRight,
  FooterRef,
} from "../ResponsiveLayout/ResponsiveLayoutStyle.js";

import {
  MenuBlock,
  MenuBgLeft,
  MenuBgRight,
  MenuForeground,
  MenuBackground,
  LeftBlock,
  RightBlock,
  CenterBlock,
} from "../ResponsiveLayout/MenuLayoutStyle.js";

//import { PageSpinner, PageEmpty } from "../../pages/PagesStyle";

import {
  GridContainer,
  GridLeftItem,
  GridCenterItem,
  GridRightItem,
} from "../GridLayout/GridLayoutStyle.js";
import { HudRoot } from "../../components/TheHud/TheHudStyle";
//LargeView Component Definition
const LargeView = (props) => {
  const [debugState] = useRecoilState(appDebugState);
  const [viewState, setViewState] = useState(props.viewState);
  const [menuRecoilState, setMenuRecoilState] = useRecoilState(menuOpenState);
  const [footerRecoilState, setFooterRecoilState] = useRecoilState(footerOpenState);
  const [viewRecoilState, setViewRecoilState] = useRecoilState(appViewState);
  const [userState, setUserState] = useRecoilState(basicUserState);
  const { width, height } = useWindowDimensions();
  const{ openModifierFooter, closedModifierFooter} = getFooterOffset(width, height);
  //const [menuState] = useRecoilState(mainMenuState);
  const openFooterMod = useRef();
  const closedFooterMod = useRef();
  const menubarRef = useRef();
  const mainRef = useRef();
  const contentRef = useRef();
  const footerRef = useRef();
  const modalRef = useRef();
  const { open, close, visible } = useModal();
  const [isHidden, setIsHidden] = useState(true);

  const LeftTabletWithImages = withImagesComponent(TabletScreenLeft);
  const RightTabletWithImages = withImagesComponent(TabletScreenRight);

  //TODO: Should this be a ref to avoid unnecessary dom reloads
  const toggleMenuState = () => {
    if (menuRecoilState.menuOpen) {
     

      setMenuRecoilState({ menuOpen: false });
      //setFooterRecoilState({ footerOpen: false });
    
      //setIsHidden(true);
    } else {
     
      setMenuRecoilState({ menuOpen: true });
    
      //setFooterRecoilState({ footerOpen: true });
      //setIsHidden(false);
    }
  };

useEffect(() => {
  console.log("openModifierFooter: ", openModifierFooter);
  console.log("closedModifierFooter: ", closedModifierFooter);
}, []);

  return (
    <ThemeProvider theme={theme} name="ThemeProvider">
      <ResponsiveDisplay name="ResponsiveDisplay">
        <HudRoot name="hudroot" id="hudRoot" />

        <MenuBlock
          name="menuBlock"
          ref={menubarRef}
          themePath={theme.themePath}
          debugState={debugState}
        >
          <MenuForeground name="menuForeground">
            <LeftBlock name="LeftBlock"></LeftBlock>
            <CenterBlock name="CenterBlock">
              <MenuBase />
            </CenterBlock>
            <RightBlock name="RightBlock"></RightBlock>
          </MenuForeground>
          <MenuBackground name="menuBackground">
            <MenuBgLeft name="menuBgLeft"></MenuBgLeft>
            <MenuBgRight name="menuBgRight"></MenuBgRight>
          </MenuBackground>
        </MenuBlock>
        <Modal
          name="Modal"
          ref={modalRef}
          visible={visible}
          close={close}
          open={open}
          title={""}
        >
          <LoginProfile close={close} />
        </Modal>

        <MainBlock name="MainBlock" ref={mainRef}>
        <MenuHeader
              name="MenuHeader"
              contentRef={contentRef}
              footerRef={footerRef}
              menubarRef={menubarRef}
              size={props.viewState}
            ></MenuHeader>
           {/*<div onClick={toggleMenuState}>
            <MenuToggle
              name="MenuToggle"
              contentRef={contentRef}
              footerRef={footerRef}
              menubarRef={menubarRef}
              size={props.viewState}
            ></MenuToggle> 

          </div>*/}

          {/* <SearchDropdown
            name="SearchDropdown"
            contentRef={contentRef}
            size={props.viewState}
          /> */}

          <ProfileBlock name="ProfileBlock">
            <ProfileBox
              name="ProfileBox"
              toggle={open}
              photoURL={userState.photoURL}
            />
          </ProfileBlock>
          <ContentContainer name="ContentContainer">
            <ContentBackground>
              <ContentBackgroundLeft name="ContentBackgroundLeft" />
              <ContentBackgroundCenter>
             <img
                  alt="background"
                  src={
                    process.env.PUBLIC_URL +
                    theme.themePath +
                    "/large/bg-tiles/led-bg-box.png"
                  } 
                />
                   {/* <video muted={true} autoPlay={true} loop={true} playsInline={true} preload="auto" poster={process.env.PUBLIC_URL + theme.themePath + "/large/bg-tiles/space_compress.jpg"}>
              <source src={
                    process.env.PUBLIC_URL +
                    theme.themePath +
                    "/large/bg-tiles/space_compress.mp4"
                  } type="video/mp4"/>
              </video> */}


              </ContentBackgroundCenter>
              <ContentBackgroundRight name="ContentBackgroundRight" />
            </ContentBackground>
            <Content name="Content" ref={contentRef}>
              <ContentForeground name="ContentForeground">
                <RouterControl />
              </ContentForeground>
            </Content>
            {/* <FooterRef 
            name="Footer" 
            ref={footerRef} 
            isHidden={false}
            openModifierFooter={openModifierFooter}
            closedModifierFooter={closedModifierFooter}
            isOpen={menuRecoilState.menuOpen}
            >
              <Footer />
        </FooterRef> */}
          </ContentContainer>
        </MainBlock>

        <SidePanelRight name="SidePanelRight">
          <RightPanel />
        </SidePanelRight>
        <SidePanelLeft name="SidePanelLeft">
          <LeftPanel />
        </SidePanelLeft>

      </ResponsiveDisplay>
    </ThemeProvider>
  );
};
export default LargeView;
