import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
import { theme } from "../../themes/greyscaleTheme";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/

export const CamRow = styled("div")`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;
export const Camera1 = styled.div`
  position: relative;
  margin-left: -2px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 0px;
    img {
      width: 40px;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 4px;
    img {
      width: 70px;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: -100px;
    img {
      width: 90px;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -220px;
    img {
      width: 90px;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -220px;
    img {
      width: 100px;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -220px;
    img {
      width: 100px;
    }
  }
`;
export const Camera2 = styled.div`
  position: relative;
  //margin-right: 10px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 0px;
    margin-right: -2px;
    img {
      width: 40px;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-right: 10px;
    margin-top: 4px;
    img {
      width: 70px;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    // margin-left: 285px;
    margin-top: -100px;
    img {
      width: 90px;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -220px;
    img {
      width: 90px;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -220px;

    img {
      width: 100px;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -220px;
    img {
      width: 100px;
    }
  }
`;