import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRecoilState } from "recoil";
import { hudState } from "../../config/state/uiState";
import { theme } from "../../themes/greyscaleTheme";
import {
    HudComponent,
    HudBackground,
    HudForeground
} from "./TheHudStyle.js";

export default function TheHud({isOpen, onClose, foreground}) {

    const [hudStateValue, setHudStateValue] = useRecoilState(hudState);
    const hudRef = useRef();

    const el = document.getElementById("hud");
    useEffect(() => {

        if (hudStateValue.hudVisible) {
        hudRef.current.style.top = "0px";
       } else {
         hudRef.current.style.top = "-235px";
        }

     }, [hudStateValue.hudVisible]);

        return ( createPortal(
            <HudComponent 
            ref={hudRef}
            name="hudComponent">
            <HudBackground></HudBackground>
            <HudForeground dangerouslySetInnerHTML={{ __html: foreground }}/>
            </HudComponent>, el)
        )

};