export const listReducer = (state, action) => {
    switch (action.type) {
      case "STACK_LIST":
        return {
          ...state,
          items: state.items.concat(action.items),
        };
      case "FETCHING_LIST":
        return { 
          ...state, 
          fetching: action.fetching 
        };
      case 'RELOAD':
          return { items: [], fetching: true };
      default:
        return state;
    }
  };

  export const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        return { 
          ...state, 
          page: state.page + 1 
        };
      case "RESET_PAGE":
        return { 
          ...state, 
          page: state.page 
        };
      default:
        return state;
    }
  };