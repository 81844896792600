import styled from "@emotion/styled";
//import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../app/ResponsiveLayout/ResponsiveBreakpoints";
//import { theme } from "../themes/greyscaleTheme";


export const PageContent = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${sm}px) {
    // margin-left: calc(100vw - 100%);
    //margin-top: 104px;
  }

  @media (min-width: ${sm}px) and (max-width: ${md}px) {
    //margin-left: calc(100vw - 100%);
    margin-top: 72px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${lg}px) {
    //margin-left: calc(100vw - 100%);
    margin-top: 100px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${xl}px) {
    //margin-left: calc(100vw - 100%);
    margin-top: 158px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${xxl}px) {
    //margin-left: calc(100vw - 100%);
    margin-top: 218px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    //margin-left: calc(100vw - 100%);
    margin-top: 218px;
  }
`;
export const PageSpinner = styled("div")`
  z-index: 0;
  position: fixed;
  margin-left: auto;
  margin-right: auto;

  border: solid 3px ${(props) => props.theme.colors.light_gray};
  height: 100%;
 width: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0.7);
    img {
      margin-top: calc(50vh - 108px );
    }
text-align: center;
display: inline-block;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {

  }
`;

export const PageEmpty = styled("div")`
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    text-align: center;
    font-family: "ROG";
    font-size: 14px;
    display: block;
    margin-top: 75px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 75px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 100px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 200px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 200px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
`;
export const ListContainer = styled("div")`
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 85px;
    margin-left:0px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 60px;
    margin-left:10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 10px;
    margin-left: 14px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -80px;
    margin-left: 16px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -80px;
    margin-left: 20px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -80px;
    margin-left: 24px;
  }

`;