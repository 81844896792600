import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

export const MenuToggleContainer = styled("div")`
  position: relative;
  text-align: center;
  display: flex;
  z-index: 100;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${sm}px) {
    flex-direction: row;
    border-top: solid 1px ${(props) => props.theme.colors.applebarrelpoolblue};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: row;
    z-index: 100;
    border-top: solid 1px ${(props) => props.theme.colors.applebarrelpoolblue};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    border-top: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    border-top: solid 2px ${(props) => props.theme.colors.applebarrelpoolblue};
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    border-top: solid 3px ${(props) => props.theme.colors.applebarrelpoolblue};
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    border-top: solid 3px ${(props) => props.theme.colors.applebarrelpoolblue};
  }
`;

export const HeaderTitle = styled("h1")`
  font-family: "ROG";
  position: relative;
  top: 95px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -95px;
  width: 100%;
  border: solid 1px;
  display: inline-block;
  text-align: center;
  z-index: 1000;
`;

export const ToggleLeftBorder = styled("div")`
  display: flex;
  height: 3px;
  border-top: solid 3px ${(props) => props.theme.colors.jet};

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 5px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 5px;
  }
`;

export const MenuToggleImg = styled("img")`
  cursor: pointer;
  display: flex;
  z-index: 100;
  margin-right: auto;
  margin-left: auto;
  animation: ease 0.5s;
  transition: 0.5s;
  opacity: 1;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    height: 25px;
    width: 350px;
    cursor: pointer;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    height: 32px;
    width: 450px;
    cursor: pointer;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 49px;
    width: 698px;
    cursor: pointer;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    height: 54px;
    width: 766px;
    cursor: pointer;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    height: 54px;
    width: 766px;
    cursor: pointer;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    height: 54px;
    width: 766px;
    cursor: pointer;
  }
`;

export const ToggleRightBorder = styled("div")`
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    height: 3px;
    border-top: solid 3px ${(props) => props.theme.colors.jet};
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    display: flex;
    height: 5px;
    background-image: url("${(props) => props.theme.themePath}${(props) =>
      props.theme.media.MenuToggleImages.border_bg}");
    background-repeat: repeat-x;
    background-size: auto 4px;
  }
`;

export const MenuSignIn = styled("div")`
  position: fixed;
  display: block;
  left: -100px;
  height: 60px;
  width: 250px;
  border-top: solid 3px ${(props) => props.theme.colors.jet};

  @media (min-width: 360px) {
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 1200px) {
  }
`;
