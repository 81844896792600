import styled from '@emotion/styled'
export const PanelContainer = styled('div')`
display:block;
width:100%;
height:100%;
border:dashed 1px #666666;
//background-color: rgb(26,26,28,0.42);
//border-radius: 0px;
//box-shadow: inset -12px 12px 24px #0f0f0f,
//            inset 12px -12px 24px #252525;
`
export const PanelTab = styled('div')`
width: 100%;
display:block;
height:100%;
border: solid 1px #ffcc33;
`
export const TabOptions = styled('div')`
display:flex;
flex-direction:column;
`
export const TabItem = styled('div')`
display:block;
position:relative;
left:0px;
height:153px;
width:43px
border:solid 1px #ff0000;    

background-image: url("${props => props.theme.themPath}/large/panel-left/${props => props.img}");
background-repeat: no-repeat;
background-size: 43px 153px;
`


export const TabContent = styled('div')`
border:solid 1px #000099;
position:relative;
top:0px;
left:0px;
`
export const TabBackground = styled('div')`

`

