import React from "react";
//import { theme } from "../../themes/greyscaleTheme";
import MetaHead from "../../components/MetaHead";
import SiteMapPanel from "../../components/SiteMapPanel";
import Cams from "../../components/Cams/index.js";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";  
import {PageContent} from "../PagesStyle";



export default function Terms(props, { path }) {
  return (
    <section>
      <MetaHead
        page="Site Map"
        title="Site Map for CoreyLines.com"
        description="The layout and navigation paths for coreylines.com"
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PageContent>
        <Cams/>
        <DisplayAdBanner/>
        <SiteMapPanel/>
      </PageContent>
    </section>
  );
}