import React, { useEffect, useReducer } from "react";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { theme } from "../../themes/greyscaleTheme";
import { mainMenuState } from "../../config/state/uiState";
import { useAxiosRequestShareDetails } from "../../hooks/useAxiosRequestShareDetails.js";
import ShareItemDetails from "../ShareItemDetails";

import { PageSpinner, PageEmpty } from "../PagesStyle";

import { DetailsContent, DetailsList } from "../DetailsStyle";

export default function ShareDetails(props) {
  let { id } = useParams();
  const shareDetailsReducer = (state, action) => {
    switch (action.type) {
      case "RETURN_SHARE_DETAILS":
        return { ...state, shares: action.shares };
      case "FETCHING_SHARE_DETAILS":
        return { ...state, fetching: action.fetching };
      default:
        return state;
    }
  };

  // const pageReducer = (state, action) => {
  //   switch (action.type) {
  //     case "ADVANCE_PAGE":
  //       return { ...state, page: state.page + 1 };
  //     default:
  //       return state;
  //   }
  // };

  const [menuState, setMenuState] = useRecoilState(mainMenuState);
  //const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 })
  const [shareData, shareDispatch] = useReducer(shareDetailsReducer, {
    shares: [],
    fetching: true,
  });

  useAxiosRequestShareDetails(id, shareDispatch);

  useEffect(() => {
    setMenuState({ activePath: "shares" });
  }, [setMenuState]);

  return (
    <>
      <DetailsContent>
        {shareData.fetching && (
          <PageSpinner>
            <img
              src={
                process.env.PUBLIC_URL +
                theme.themePath + theme.media.MiscImages.spinner
              }
              alt="Spinner"
            />
          </PageSpinner>
        )}
        {shareData.shares.length === 0 && <PageEmpty>Fetching...</PageEmpty>}

        <DetailsList>
          {shareData.shares.map((share, index) => {
            const { slug } = share;
            return <ShareItemDetails id={slug} key={index} />;
          })}
        </DetailsList>
      </DetailsContent>
    </>
  );
}
