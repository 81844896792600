import React from "react";
import { Link } from "react-router-dom";
import {
    SiteMapContainer,           
    SiteMapContentContainer,
    SiteMapContentTitle,
    SiteMapContentBody,
    SiteMapImageTopCorner,
    SiteMapBg,
    SiteMapHomeLink,
    SiteMapResourceLink,
    SiteMapResourceDetailLink,
    SiteMapSearchResultLink,
    SiteMapShareDetailLink,
    SiteMapShareLink,
    SiteMapAboutLink,
    SiteMapTermsLink,
    SiteMapPrivacyLink,
    SiteMapContactLink,
    SiteMapSiteMapLink

} from "./SiteMapPanelStyle";

export default function SinglePagePanel(props) {
    return (
        <SiteMapContainer name="panelContainer">
            <SiteMapContentContainer name="panelContentContainer">
                <SiteMapImageTopCorner></SiteMapImageTopCorner>
                <SiteMapContentTitle name="panelContentTitle">Site Map</SiteMapContentTitle>
                <SiteMapContentBody name="panelContentBody">
                    <SiteMapBg />
                    <SiteMapHomeLink name="welcome" to="/">
                        <Link to="/welcome">Welcome</Link>
                    </SiteMapHomeLink>
                    <SiteMapResourceLink name="resource" >
                        <Link to="/resource">Resources</Link>
                    </SiteMapResourceLink>
                    <SiteMapResourceDetailLink name="resourceDetails" >
                        <Link to="/resource:id">Resource Details</Link>
                    </SiteMapResourceDetailLink>
                    <SiteMapSearchResultLink name="searchResults" >
                        <Link to="/search:q">Search Result</Link>
                    </SiteMapSearchResultLink>
                    <SiteMapShareDetailLink name="blogPostDetail" >
                        <Link to="/share:id">BLOG Post Details</Link>
                    </SiteMapShareDetailLink>
                    <SiteMapShareLink name="blogPost" >
                        <Link to="/share">BLOG Posts</Link></SiteMapShareLink>
                    <SiteMapAboutLink name="about" >
                        <Link to="/about">About</Link>
                    </SiteMapAboutLink>
                    <SiteMapTermsLink name="terms" >
                        <Link to="/terms">Terms of Use</Link>
                    </SiteMapTermsLink>
                    <SiteMapPrivacyLink name="privacy" >
                        <Link to="/privacy">Privacy Policy</Link>
                    </SiteMapPrivacyLink>
                    <SiteMapContactLink name="contant" >
                        <Link to="/contact">Contact Info</Link>
                    </SiteMapContactLink>
                    <SiteMapSiteMapLink name="siteMap" >
                        <Link to="/sitemap">Site Map</Link>
                    </SiteMapSiteMapLink>
                    
                </SiteMapContentBody>
            </SiteMapContentContainer>
        </SiteMapContainer> 
    );
}
