import styled from '@emotion/styled'

export const PanelContainer = styled('div')`
display:flex;
flex-direction:row;
width:100%;
height:100%;
border: 1px solid #990099;
position:relative;

`
export const PanelContent = styled('div')`
display:flex;
position:relative;
left:0px;
width:100%;
height:calc(100%-27px);
border:solid 1px #000099;
`

export const PanelToggleButton = styled('div')`
display:flex;
position:relative;
right:0px;
width:27px;
height:auto;
border:solid 1px #009900;
text-orientation: mixed;
writing-mode: vertical-rl;
top: calc(height/2)
display: inline;
`
