import styled from "@emotion/styled";
import { fadeIn} from "../../uix/animation/keyframes";
export const ModalContainer = styled("div")`
  color:#BBE5ED;
  background-color: black;
  display:block;
`

export const ModalPop = styled("div")`
display:flex;
top:60px;
width:0px;
height: 500px;
min-height:500px;
opacity: 0;
border-radius: 72px;
background-color: black;
border: 5px solid #333333;
z-index: 999;
//visibility: hidden;
animation: ${fadeIn} 1s ease;
  margin-left: auto;
  margin-right: auto;
  padding: 1em 2em 2em;
  position: relative;
  flex-direction:column;

  @media (min-width: 360px) {

  }

  @media (min-width: 768px) {
  
  }

  @media (min-width: 1200px) {

  }
`;

export const ModalOverlay = styled("div")`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
z-index: 900;
background-color: #1a1a1c;
opacity: 0.8;

  @media (min-width: 360px) {

  }

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {

  }
`;
export const ModalTitle = styled("div")`

  @media (min-width: 360px) {

  }

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {
    font-family:'ROG';
    font-size: 18px;
  }
`;
export const ModalContent = styled("div")`

  @media (min-width: 360px) {

  }

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {

  }
`;
export const ModalFooter = styled("div")`

  @media (min-width: 360px) {

  }

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {
    position:absolute;
    top:485px;
    width:100%;
    margin-left:auto;
    margin-right:auto;
    display:block;
    text-align:center;
  }
`;
export const ModalCloseButton = styled("button")`
width:50px;
margin-left:auto;
margin-right:20px;
margin-top:20px;

  @media (min-width: 360px) {

  }

  @media (min-width: 768px) {

  }

  @media (min-width: 1200px) {

  }
`;