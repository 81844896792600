import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { mainMenuState } from "../../config/state/uiState";
import { useAxiosRequestResourceDetails } from "../../hooks/useAxiosRequestResourceDetails.js";
import { theme } from "../../themes/greyscaleTheme";
import ResourceItemDetails from "../ResourceItemDetails";

import { PageSpinner, PageEmpty } from "../PagesStyle";

import { DetailsContent, DetailsList } from "../DetailsStyle";


export default function ResourceDetails(props) {
  let { id } = useParams();

  const resourceDetailsReducer = (state, action) => {
    switch (action.type) {
      case "RETURN_RESOURCE_DETAILS":
        return { ...state, resources: action.resources };
      case "FETCHING_RESOURCE_DETAILS":
        return { ...state, fetching: action.fetching };
      default:
        return state;
    }
  };
/*
  const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        return { ...state, page: state.page + 1 };
      default:
        return state;
    }
  };
  */
  const [menuState, setMenuState] = useRecoilState(mainMenuState);
 // const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  const [resourceData, resourceDispatch] = useReducer(resourceDetailsReducer, {
    resources: [],
    fetching: true,
  });

  useAxiosRequestResourceDetails(id, resourceDispatch);

  useEffect(() => {
    setMenuState({ activePath: "resources" });
  }, [setMenuState]);

  return (
    <section>
      <DetailsContent>
        <DetailsList>
          {resourceData.resources.map((resource, index) => {
            const { slug } = resource;
            return <ResourceItemDetails id={slug} key={index}/>;
          })}
        </DetailsList>
        {resourceData.fetching && (
          <PageSpinner>
            <img
              src={
                process.env.PUBLIC_URL +
                theme.themePath + theme.media.MiscImages.spinner
              }
              alt="Spinner"
            />
          </PageSpinner>
        )}
        {resourceData.resources.length === 0 && (
          <PageEmpty>Fetching...</PageEmpty>
        )}
      </DetailsContent>
    </section>
  );
}
