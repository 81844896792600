import React from "react";
import { useRecoilState } from "recoil";
import { menuOpenState } from "../../config/state/uiState";
import { Link } from "react-router-dom";


import {
  FooterContainer,
  ContactDetails,
  PrivacyPolicy,
  TermsOfUse,
  SiteMap,
} from "./MenuFooterStyle.js";

import { theme } from "../../themes/greyscaleTheme";

export default function MenuFooter(props) {
  const [menubarOpen,setMenubarOpen] = useRecoilState(menuOpenState);
  const footerLinkClick = (e) => {
    setTimeout(()=>setMenubarOpen({menuOpen: false}),300)
  }

  return (
<FooterContainer>
  <TermsOfUse>
    <Link onClick={footerLinkClick} to={`/terms`} name={`termsLink`}>
      Terms of Use
    </Link>
  </TermsOfUse>
  <PrivacyPolicy>
    <Link onClick={footerLinkClick} to={`/privacy`} name={`privacyLink`}>
      Privacy Policy
    </Link>
  </PrivacyPolicy>
  <SiteMap>
    <Link onClick={footerLinkClick} to={`/sitemap`} name={`sitemapLink`}>
      Site Map
    </Link>
  </SiteMap>
  <ContactDetails>
    <Link onClick={footerLinkClick} to={`/contact`} name={`contactLink`}>
      Contact Info
    </Link>
  </ContactDetails>
</FooterContainer>
  );
}
