import styled from "@emotion/styled";
//import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
import { theme } from "../../themes/greyscaleTheme";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const ContentContainer = styled("div")`
  position: absolute;
  background-color: ${(props) => props.bgColor};
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: -345px;
        left: 24px;
        width: 252px;
        height: 280px;
        border-radius: 12px 12px 12px 12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 355px;
    left: 37px;
    width: 420px;
    height: 281px;
    border-radius: 18px 18px 18px 18px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 244px;
    left: 74px;
    width: 595px;
    height: 402px;
    border-radius: 24px 24px 24px 24px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 198px;
    left: 80px;
    width: 654px;
    height: 440px;
    border-radius: 27px 27px 27px 27px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 198px;
    left: 80px;
    width: 654px;
    height: 440px;
    border-radius: 27px 27px 27px 27px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 198px;
    left: 80px;
    width: 654px;
    height: 440px;
    border-radius: 27px 27px 27px 27px;
  }
`;
export const ContentTitle = styled("div")`
  font-family: "ROG";
  color: ${theme.colors.yellow};
  display: inline;
  width: 100%;
  position: absolute;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 18px;
    top: 10px;
    left: 20px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 20px;
    top:15px;
    left:30px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 22px;
    top:15px;
    left:30px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 24px;
    top:15px;
    left:30px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 24px;
    top:15px;
    left:30px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 24px;
    top:15px;
    left:30px;
  }
`;
export const ContentGraphic = styled("div")`
  font-family: "Roboto";
  font-size: 18px;
  color: #ffffff;
  display: inline;
  display: flex;
  flex-direction: column;
  position: absolute;

  z-index: 1;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 138px;
    left: 112px;
    width: 60%;
    img {
      border-radius: 12px 12px 12px 12px;
    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 84px;
    left: 226px;
    width: 50%;
    img{
      border-radius: 18px 18px 18px 18px;
    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 84px;
    left: 282px;
    width: 55%;
    img {
      border-radius: 20px 20px 20px 20px;
    }
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 93px;
    left: 310px;
    width: 55%;
    img {
    border-radius: 27px 27px 27px 27px;
    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 93px;
    left: 310px;
    width: 55%;
    img {
    border-radius: 27px 27px 27px 27px;
    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 93px;
    left: 310px;
    width: 55%;
    img {
    border-radius: 27px 27px 27px 27px;
    }
  }
  
`;
export const ContentBody = styled("div")`
  z-index: 2;
  position: absolute;
  font-family: "Roboto";

  color: ${theme.colors.white};
  display: inline;
  width: 100%;
  display: flex;
  flex-direction: column;


  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
    top: 30px;
    left: 20px;
    width: 240px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 60%;
      }

    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 14px;
    top: 40px;
    left: 30px;
    width: 576px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 42%;
      }

    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 21px;
    top: 40px;
    left: 30px;
    width: 680px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 50%;
      }

    }
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 23px;
    top: 40px;
    left: 30px;
    width: 680px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 50%;
      }

    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 23px;
    top: 40px;
    left: 30px;
    width: 680px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 50%;
      }

    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 23px;
    top: 40px;
    left: 30px;
    width: 680px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 50%;
      }

    }
  }
`;
export const SimpleContainer = styled("div")`
  position: relative;
  top: 0px;
  left: 0px;
  display: block;
`;
export const TitleContainer = styled("div")`
  position: absolute;
  top: 72px;
  left: -359px;
  display: block;
`;
export const ImageContainer = styled("div")`
  position: relative;
  top: 0px;
  left: 0px;
  height: 100%;
  display: block;

  img {
    border-radius: 27px 27px 27px 27px;
    border: solid 5px #66ffff;
    position: absolute;

    /* Target the first image */
    &:first-child {
      /* Add specific styles for the first image */
      /* Example: border: 2px solid red; */
      position: absolute;
      top: 0px;
      left: -350px;
      width: 270px;
      height: 270px;
    }

    /* Target the second image */
    &:nth-child(2) {
      /* Add specific styles for the second image */
      /* Example: border: 2px solid blue; */
      top: 0px;
      left: -220px;
      width: 220px;
      height: 2;
      position: absolute;
    }

    /* Target the third image */
    &:nth-child(3) {
      /* Add specific styles for the third image */
      /* Example: border: 2px solid green; */
    }
  }
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
  }
`;
export const ResourceContent = styled("div")`
  font-family: "Roboto";
  font-size: 16px;
  color: ${theme.colors.white};
  display: inline;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top:30px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top:40px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top:40px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top:40px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top:40px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top:40px;
  }
`;
export const PostContent = styled("div")`
  font-family: "Roboto";
  font-size: 16px;
  color: ${theme.colors.white};
  display: inline;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top:30px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top:40px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top:40px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top:40px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top:40px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top:40px;
  }
`;
export const AboutContent = styled("div")`
  z-index: 2;
  position: absolute;
  font-family: "Roboto";

  color: ${theme.colors.white};
  display: inline;
  width: 100%;
  display: flex;
  flex-direction: column;


  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
    top: 30px;
    left: 20px;
    width: 280px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 90%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 90%;
      }

    }
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 14px;
    top: 40px;
    left: 30px;
    width: 400px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 100%;
      }

    }
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 21px;
    top: 40px;
    left: 30px;
    width: 570px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 100%;
      }

    }
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 23px;
    top: 40px;
    left: 30px;
    width: 630px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 100%;
      }

    }
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 23px;
    top: 40px;
    left: 30px;
    width: 630px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 100%;
      }

    }
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 23px;
    top: 40px;
    left: 30px;
    width: 630px;
    p{
    /* Target the first image */
    &:first-child {
        /* Add specific styles for the first image */
        /* Example: border: 2px solid red; */
        width: 100%;
      }

      /* Target the second image */
      &:nth-child(2) {
        /* Add specific styles for the second image */
        /* Example: border: 2px solid blue; */
        width: 100%;
      }

    }
  }
`;
