import React, {useRef, useEffect, useContext} from "react";
import { UserContext } from '../../lib/context';
import { useRecoilState } from "recoil";
import { theme } from "../../themes/greyscaleTheme";
import {
  basicUserState,
} from "../../config/state/uiState";
import {
  ProfileContainer,
  ProfileBlock
} from "./ProfileStyle.js";

export default function Profile(props) {
  console.log('load Profile');
  const {user, username} = useContext(UserContext);
  const profileRef = useRef(new Array(4));
  const [userState, setUserState] = useRecoilState(basicUserState);
  
  
  useEffect(() => {
    console.log('Profile User:\n', user);
    if(user.uid){
      profileRef.current["uid"]= user.uid;
    
    }
    if(user.email){
      profileRef.current["email"]= user.email;


    }
  if(user.displayName){
    profileRef.current["displayName"]= user.displayName;


  }else{
    profileRef.current["displayName"] = username;
  }



  if(user.photoURL !== null){
    profileRef.current["photoURL"]= user.photoURL;
  }else{
    profileRef.current["photoURL"]= `${theme.themePath}/large/user-details/user-default-photo.png`;
  }

  if(user.emailVerified ){
    profileRef.current["emailVerified"]= user.emailVerified;

  }

  setUserState({
    uid: profileRef.current["uid"],
    email: profileRef.current["email"],
    displayName: profileRef.current["displayName"],
    photoURL: profileRef.current["photoURL"],
    emailVerified: profileRef.current["emailVerified"]
  });

}, [user]);

  return(
    <ProfileContainer>
      <ProfileBlock>
      <table>
      <tr>
      <td>Photo:</td>
       <td colspan="2" align="center"><img alt="user" src={profileRef.current["photoURL"]} /></td>
      </tr>
 
      <tr>
      <td>Display Name:</td>
      <td>
      {username && username}</td>
      <td></td>
      </tr>

      <tr>
      <td colspan="3">
      <hr/>
      </td>
      </tr>

      <tr>
      <td>Id (uid):</td>
      <td>{profileRef.current["uid"]}</td>
      <td></td>
      </tr>

      <tr>
      <td>Name:</td>
      <td>{user && user.displayName}</td>
      <td></td>
      </tr>

      <tr>
      <td colspan="3">
      <hr/>
      </td>
      </tr>

      <tr>
      <td>Email:</td>
      <td>{profileRef.current["email"]= user.email}</td>
      <td></td>
      </tr>

      <tr>
      <td>Verified:</td>
      <td>{user.emailVerified? "Yes" : " No"}</td>
      <td></td>
      </tr>

      </table>
      </ProfileBlock>
    </ProfileContainer>
  );

}