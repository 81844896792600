import React, { useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import Button from "../MenuBlueButton";
import { menuOpenState } from "../../config/state/uiState";
import MenuFooter from "../MenuFooter/index.js";
import "./menuAnimation.css";
import {
  MenuContainer,
  MenuItems,
  MenuLeft,
  MenuRight,
  MenuCenter,
  MenuPrompt,
  MenuSteam,
  MenuDrip,
  MenuButtonHelpImg,
  MenuButton,
  MenuCopyright,
  MenuBG,
  MenuBGLeft,
  MenuBGCenter,
  MenuBGRight,
  MenuPromptHud,
} from "./MenuStyle.js";

import {
  mainMenuState,
  clickedMenuState,
  menuConfigRecoilState,
} from "../../config/state/uiState";
import { theme } from "../../themes/greyscaleTheme";

export default function MenuBlue() {
  const gaugeNeedleRef = useRef(false);
  const promptRef = useRef();
  const helpRefArray = [useRef(), useRef(), useRef(), useRef()];
  const [menuConfig, setMenuConfig] = useRecoilState(menuConfigRecoilState);
  const [menuState] = useRecoilState(mainMenuState);
  const [wasClicked, setWasClicked] = useRecoilState(clickedMenuState);
  const steamRef = useRef();
  const dripRef = useRef();
  const [menubarOpen,setMenubarOpen] = useRecoilState(menuOpenState);
  const toggleSteam = (toggle) => {
    if (toggle) {
      steamRef.current.style.visibility = "visible";
      dripRef.current.style.visibility = "hidden";
      dripRef.current.style.display = "none";
      gaugeNeedleRef.current.className = "needleMove";
    } else {
      steamRef.current.style.visibility = "hidden";
      dripRef.current.style.visibility = "visible";
      dripRef.current.style.display = "inline-block";
      gaugeNeedleRef.current.className = "needleStart";
    }
  };

  const toggleBtnState = () => {
    //console.log('Toggle Button ');
  };

  const setPrompt = (strPrompt) => {
    //console.log('setPrompt')
    promptRef.current.innerHTML = strPrompt;
  };



  useEffect(() => {}, []);

  return (
    <MenuContainer themePath={theme.themePath} name="menuContainer">
      {/* <MenuItems name="menuItems">   */}
        {/* <MenuCenter themePath={theme.themePath} name="menuCenter"> */}
        <MenuPromptHud></MenuPromptHud>
        <MenuBG>
            <MenuBGLeft themePath={theme.themePath}>  </MenuBGLeft>
            <MenuBGCenter themePath={theme.themePath}>  </MenuBGCenter>
            <MenuBGRight themePath={theme.themePath}>  </MenuBGRight>
        </MenuBG>
           <MenuPrompt ref={promptRef}>Explore the Menu!</MenuPrompt>
          <div id="gauge">
            <span className="speedometer"></span>
            <span className="needleStart" ref={gaugeNeedleRef}></span>
          </div>
          <MenuSteam ref={steamRef}>
            <img
              alt="steam"
              src={theme.themePath + theme.media.MiscImages.steam}
            />
          </MenuSteam>
          <MenuDrip ref={dripRef}>
            <img
              alt="drip"
              src={theme.themePath + theme.media.MiscImages.drip}
            />
          </MenuDrip>
          <MenuCopyright>©2024 COREYLINES.COM, All Rights Reserved</MenuCopyright>
          <MenuFooter></MenuFooter>
          <MenuLeft name="menuLeft">
          {theme &&
            theme.media.MenuImages.left_menu.buttons.map((button, index) => {
              let btnActive = menuState.activePath === button.name;
              return (
                <MenuButton
                  key={`${button.name}${index}`}
                  name={`${button.name}Button`}
                >
                  <Link
                    to={`/${button.name.toLowerCase()}`}
                    name={`${button.name}Link`}
                  >
                    <Button
                      isActive={btnActive}
                      id={`${button.name}Btn`}
                      text={button.name}
                      title={button.title}
                      index={index}
                      menuConfig={menuConfig}
                      btnOverImgArray={button.hoverStates}
                      btnClickedImg={button.clickedState}
                      btnOverAudio={button.hoverAudio}
                      clickedState={wasClicked}
                      setClickedState={setWasClicked}
                      handleMouseOverSteam={() => toggleSteam(true)}
                      handleMouseOutSteam={() => toggleSteam(false)}
                      handleSetPrompt={() => setPrompt(button.prompt)}
                      handleSetDefaultPrompt={() =>
                        setPrompt("Explore the Menu!")
                      }
                    />
                  </Link>
                  <MenuButtonHelpImg name={`${button.name}HelpButton`}>
                    <img
                      ref={helpRefArray[index]}
                      alt={button.name}
                      src={
                        process.env.PUBLIC_URL +
                        theme.themePath +
                        theme.media.MenuImages.menu_btn_info
                      }
                      onClick={() => setPrompt(button.prompt)}
                      onMouseOver={() => {
                        helpRefArray[index].current.src =
                          process.env.PUBLIC_URL +
                          theme.themePath +
                          theme.media.MenuImages.menu_btn_info_active;
                      }}
                      onMouseOut={() => {
                        helpRefArray[index].current.src =
                          process.env.PUBLIC_URL +
                          theme.themePath +
                          theme.media.MenuImages.menu_btn_info;
                      }}
                    />
                  </MenuButtonHelpImg>
                </MenuButton>
              );
            })
            }
        </MenuLeft>
        <MenuRight name="menuRight">
          {theme &&
            theme.media.MenuImages.right_menu.buttons.map((button, index) => {
              let btnActive = menuState.activePath === button.name;
              return (
                <MenuButton
                  key={`${button.name}${index}`}
                  name={`${button.name}Button`}
                >
                  <Link
                    to={`/${button.name.toLowerCase()}?q=${button.name.toLowerCase()}`}
                    name={`${button.name}Link`}
                  >
                    <Button
                      isActive={btnActive}
                      id={`${button.name}Btn`}
                      text={button.name}
                      title={button.title}
                      index={index}
                      menuConfig={menuConfig}
                      btnOverImgArray={button.hoverStates}
                      btnClickedImg={button.clickedState}
                      btnOverAudio={button.hoverAudio}
                      clickedState={wasClicked}
                      setClickedState={setWasClicked}
                      handleMouseOverSteam={() => toggleSteam(true)}
                      handleMouseOutSteam={() => toggleSteam(false)}
                      handleSetPrompt={() => setPrompt(button.prompt)}
                      handleSetDefaultPrompt={() =>
                        setPrompt("Explore the Menu!")
                      }
                    />
                  </Link>
                  <MenuButtonHelpImg name={`${button.name}HelpButton`}>
                    <img
                      ref={helpRefArray[index]}
                      alt={button.name}
                      src={
                        process.env.PUBLIC_URL +
                        theme.themePath +
                        theme.media.MenuImages.menu_btn_info
                      }
                      onClick={() => setPrompt(button.prompt)}
                      onMouseOver={() => {
                        helpRefArray[index].current.src =
                          process.env.PUBLIC_URL +
                          theme.themePath +
                          theme.media.MenuImages.menu_btn_info_active;
                      }}
                      onMouseOut={() => {
                        helpRefArray[index].current.src =
                          process.env.PUBLIC_URL +
                          theme.themePath +
                          theme.media.MenuImages.menu_btn_info;
                      }}
                    />
                  </MenuButtonHelpImg>
                </MenuButton>
              );
            })
          }
        </MenuRight>
    </MenuContainer>
  );
}
