import styled from "@emotion/styled";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

/*
//default

display:none;

//mobile portrait
@media (min-width: 360px) {
  //width is at least 360px AND the height is anything
  //small sized drop down menu
  //small sized toggle and search
  //small portal
}

//mobile landscape
@media (min-width: 768px) and (max-height: 620px) {

  //width is at least 768px AND the height is at at max 620px
  //medium sized drop down menu
  //medium sized toggle and search
  //small portal

}
//tablet portrait / landscape
@media (min-width: 768px) and (min-height: 621px) {

  //width is at least 768px OR the height is at least 621px
  //medium sized drop down menu
  //medium sized toggle and search
  //medium portal

}
//tablet landscape
@media (min-width: 1200px) and (max-height: 620px) {

  //width is at least 768px AND the height is at least 621px
  //large sized drop down menu
  //large sized toggle and search
  //medium sized portal

}

//desktop / laptop
@media (min-width: 1200px) and (min-height: 621px){
  //large sized drop down menu
  //large sized toggle and search
  //large sized portal
}
*/
export const ButtonContainer = styled("div")`
  margin-bottom: 0px;
  text-align: center;
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 100%;

`;

export const ButtonImg = styled("img")`
  position: relative;
  z-index: 10;


  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
     width: 143px;
    height: 25px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 180px;
    height: 25px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 200px;
    height: 27px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 216px;
    height: 30px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 216px;
    height: 30px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 216px;
    height: 30px;
  }
`;

export const ButtonText = styled("div")`
  position: relative;
  z-index: 0;
  font-family: "ROG";
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 180px;
    height: 25px;
    margin-top: -3px;
    line-height: 0px;
    font-size: 14px;
    top: -16px;
    left:8px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 180px;
    height: 25px;
    margin-top: -3px;
    line-height: 0px;
    font-size: 14px;
    top: -16px;
    left:13px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 200px;
    height: 27px;
    top: -29px;
    font-size: 14px;
    left:12px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 216px;
    height: 30px;
    top: -32px;
    font-size: 18px;
    left:14px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 216px;
    height: 30px;
    top: -32px;
    font-size: 18px;
    left:14px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 216px;
    height: 30px;
    top: -32px;
    font-size: 18px;
    left:14px;
  }
`;
