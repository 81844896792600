import styled from '@emotion/styled';
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/

export const SocialLinksContainer = styled('div')`
position: relative;
display: inline-block;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top: 134px;
  
}
//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top: 134px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top: 430px;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top: 430px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  top: 540px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  top: 540px;
}

`
export const WelcomeContentContainer = styled('div')`
  font-size:16px;
  margin-left:auto;
  margin-right:auto;
  text-align:center;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    
  }
  
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  
  }
  
  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  
  }
  
  //xxl 1400+
  @media (min-width:  ${xxl}px){
  
  }
`

