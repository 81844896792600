import { useEffect } from 'react';
import request from '../utils/request';
import { appConfig } from '../config/data/appConfig.js';

export const useAxiosRequestShareDetails = (slug, dispatch) => {

  const getData = async (requestURL) =>{
    dispatch({ type: 'FETCHING_SHARE_DETAILS', fetching: true })

    await request({
      method: 'get',
      url: `${requestURL}`
    })
    .then(shares => {
      dispatch({ type: 'RETURN_SHARE_DETAILS', shares });
      dispatch({ type: 'FETCHING_SHARE_DETAILS', fetching: false });
    })
    .catch(e => {
      console.log('FETCHING SHARE_DETAILS - ERROR');
      dispatch({ type: 'FETCHING_SHARE_DETAILS', fetching: false });
    })

  }

  useEffect(() => {
    //"shareDetails": "https://www.coreylines.com/byt3m3/wp-json/wp/v2/share?slug={{slug}}",
    const shareDetailsUrl = appConfig.apiUrls.shareDetails
    .replace('{{slug}}', slug);

    getData(shareDetailsUrl);

  }, [slug, dispatch])
}
