import styled from "@emotion/styled";
import { fadeIn } from "../../uix/animation/keyframes";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

import { Link } from "react-router-dom";

export const RouterLink = styled(Link)`
  position: relative;
`;

export const MenuContainer = styled("div")`
  margin: auto;
  padding: 0;
  position: relative;
  z-index: 100;
  height: 237px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top:264px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top:264px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: -170px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: -5px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: -5px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: -5px;
  }
`;

export const MenuItems = styled("div")`
  position: relative;
  display: block;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 264px;
    position: relative;
    left: -9px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 262px;
    position: relative;
    left: 0px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: -167px;
    left: 0px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left:auto;
    margin-right:auto;
    width: 100%;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    position: relative;
    left: 0px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left:auto;
    margin-right:auto;
    width: 100%;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    position: relative;
    left: 0px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left:auto;
    margin-right:auto;
    width: 100%;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    position: relative;
    left: 0px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left:auto;
    margin-right:auto;
    width: 100%;
  }
`;

export const MenuLeft = styled("div")`
  z-index:100;
  position: absolute;
  order: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    order: 1;
    top: 64px;
    width:200px;
    left:-17px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    order: 1;
    top: 64px;
    left:0px;
    width:220px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    order: 1;
    top: 53px;
    width:240px;
    left:1px
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    order: 1;
    width:240px;
    left: 1px;
    top: 53px;
  }  

  
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    order: 1;
    width:240px;
    left: 1px;
  top: 53px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    order: 1;
    width:240px;
    left: 1px;
  top: 53px;

  }
`;

export const MenuRight = styled("div")`
  position: relative;
  z-index:100;
  order: 3;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  overflow: hidden;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display: flex;
    flex-direction: column;
    order: 3;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display: flex;
    flex-direction: column;
    order: 3;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display: flex;
    flex-direction: column;
    order: 3;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display: flex;
    flex-direction: column;
    order: 3;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left:-340px;
    display: flex;
    flex-direction: column;
    order: 3;
    top:75px;
    //width:256px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left:-340px;
    display: flex;
    flex-direction: column;
    order: 3;
    top:75px;
    //width:256px;

  }
`;

export const MenuCenter = styled("div")`
  position:absolute;
  order: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  z-index: 99;
  display: flex;
  animation: ease 0.5s;
  transition: 0.5s;
  opacity: 1;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    left:calc(50% - 180px); 
    width:360px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 462px;
    left:calc(50% - 231px);
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-left:-5px;
    top:-5px; 
    left:calc(50% - 354px);
    width: 708px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-left:-5px;
    top:-5px; 
    left:calc(50% - 416px);
    width: 835px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-left:-5px;
    top:-5px; 
    left:calc(50% - 416px);
    width:835px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-left:-5px;
    top:-5px; 
    left:calc(50% - 416px);
    width:835px;
  }
`;

export const MenuBG = styled("div")`
  width:100%;
  position:relative;
  display:flex;
  flex-direction:row;
  animation: ease 0.5s;
  transition: 0.5s;
`;

export const MenuBGLeft = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  background-image: url("${(props) =>
  props.themePath}/large/menu/menu-bg/menu-bg-left.png");
  background-repeat: no-repeat;
  background-size: 170px 227px;
  width: 168px;
  height: 227px;
`;

export const MenuBGCenter = styled("div")` 
  animation: ease 0.5s;
  transition: 0.5s;
  background-image: url("${(props) =>
  props.themePath}/large/menu/menu-bg/menu-bg-center.png");
  background-size: 100% 227px;
  height: 227px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 140px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 358px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 428px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 428px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 428px;
  }
`;

export const MenuBGRight = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  background-image: url("${(props) =>
  props.themePath}/large/menu/menu-bg/menu-bg-right.png");
  background-repeat: no-repeat;
  background-size: 170px 227px;
  width: 168px;
  height: 227px;
`;

export const MenuButton = styled("div")`
  display: inline-block;
  position: relative;
  height: 100%;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575

  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 180px;
    height: 25px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 180px;
    height: 25px;
  }
  //medium 768-991

  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 27px;
    width:220px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 216px;
    height: 30px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 216px;
    height: 30px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    width: 216px;
    height: 30px;
  }
`;

export const MenuPromptHud = styled("div")`
 //default
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  color: ${(props) => props.theme.colors.applebarrelpoolblue};
  z-index: 0;
  text-align: center;
  overflow: auto;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-weight:bold;
  animation: ease 0.5s;
  transition: 0.5s;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
    margin: 6px;
    opacity: 1;
    background-image: url("${(props) => props.theme.themePath}/large/menu/prompt-hud.png");
    background-size: 210px 149px;
    top: 24px;
    left: 127px;
    width: 210px;
    height: 149px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 12px;
    margin: 6px;
    opacity: 1;
    background-image: url("${(props) => props.theme.themePath}/large/menu/prompt-hud.png");
    background-size: 320px 150px;
    top: 24px;
    left: 155px;
    width: 320px;
    height: 150px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 14px;
    margin: 6px;
    opacity: 1;
    background-image: url("${(props) => props.theme.themePath}/large/menu/prompt-hud.png");
    background-size: 427px 168px;
    top: 12px;
    left: 220px;
    width: 427px;
    height: 168px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 14px;
    margin: 6px;
    opacity: 1;
    background-image: url("${(props) => props.theme.themePath}/large/menu/prompt-hud.png");
    background-size: 472px 186px;
    top: 4px;
    left: 260px;
    width: 472px;
    height: 186px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 14px;
    margin: 6px;
    opacity: 1;
    background-image: url("${(props) => props.theme.themePath}/large/menu/prompt-hud.png");
    background-size: 472px 186px;
    top: 4px;
    left: 260px;
    width: 472px;
    height: 186px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    font-size: 14px;
    margin: 6px;
    opacity: 1;
    background-image: url("${(props) => props.theme.themePath}/large/menu/prompt-hud.png");
    background-size: 472px 186px;
    top: 4px;
    left: 260px;
    width: 472px;
    height: 186px;

  }
`;

export const MenuPrompt = styled("div")`
  //default
  display: flex;
  position: absolute;
  color: ${(props) => props.theme.colors.applebarrelpoolblue};
  z-index: 0;
  text-align: center;
  overflow: auto;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-weight:bold;
  animation: ease 0.5s;
  transition: 0.5s;
  border: solid 1px rgb(255,204,51,0.5);
    border-radius: 10px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: 64px;
    width: 123px;
    height: 82px;
    left: 167px;
    font-size: 14px;
    margin: 6px;
    opacity: 1;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 68px;
    width: 179px;
    height: 78px;
    left: 223px;
    font-size: 16px;
    margin: 6px;
    opacity: 1;
  
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 62px;
    width: 238px;
    height: 86px;
    left: 312px;
    font-size: 20px;
    margin: 6px;
    opacity: 1;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 63px;
    width: 264px;
    height: 90px;
    left: 361px;
    font-size: 22px;
    margin: 6px;
    opacity: 1;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 63px;
    width: 264px;
    height: 90px;
    left: 361px;
    font-size: 22px;
    margin: 6px;
    opacity: 1;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 63px;
    width: 264px;
    height: 90px;
    left: 361px;
    font-size: 22px;
    margin: 6px;
    opacity: 1;
  }
`;

export const MenuSteam = styled("div")`
animation: ease 0.5s;
  transition: 0.5s;
  display: block;
  visibility: hidden;
  position: absolute;
  top: 48px;
  width: 40px;
  height: 160px;

  img {
    width: 100%;
    height: 100%;
    display: inline;
    opacity: 0.6;
  }

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    visibility: hidden;
    left: 288px;
  }
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    left: 418px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: 636px;
  }
  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 706px;
   } 
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 706px;
  }
  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 706px;
  }
`;

export const MenuDrip = styled("div")`
animation: ease 0.5s;
  transition: 0.5s;
  display: block;
  visibility: visible;
  position: absolute;
  width: 14px;
  display: inline-block;
  visibility: visible;
  position: absolute;
  top: 53px;

  img {
    width: 100%;
    height: 200px;
    opacity: 0.6;
  }

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    left: 300px;
  }
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    left: 430px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    left: 648px;
  }
  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 719px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 719px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 719px;
  }
`;

export const MenuButtonHelpImg = styled("div")`
  //default
  display: block;
  position: absolute;
  animation: ease 0.5s;
  transition: 0.5s;
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    left: 161px;
    width: 20px;
    height: 20px;
    cursor: help;
    top: 2px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    left: 161px;
    width: 20px;
    height: 20px;
    cursor: help;
    top: 2px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
 
    top: 1px;
    left: 200px;
    width: 22px;
    height: 22px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    left: 215px;
    width: 25px;
    height: 25px;
    top: 1px;
    z-index: 100;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    left: 215px;
    width: 25px;
    height: 25px;
    top: 1px;
    z-index: 100;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    left: 215px;
    width: 25px;
    height: 25px;
    top: 1px;
    z-index: 100;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const MenuCopyright = styled("div")`
  //default
  display: flex;
  position: absolute;
  color: ${(props) => props.theme.colors.aquamarine};
  z-index: 0;
  font-family:"Roboto";
  font-size: 8px;
  text-align: center;
  overflow: auto;
  border-radius: 10%;
  align-items: center;
  justify-content: space-around;
  opacity: 0;
  animation: ease 0.5s;
  transition: 0.5s;
  font-weight: bold;
  opacity: 0.42;
  color:#66ffff;
  margin: 6px;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 200px;
    top: 172px;
    left: 69px;
    height: 27px;
    font-size: 9px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: 146px;
    width: 263px;
    justify-content: space-around;
    left: 181px;
    height: 16px;
    font-size: 9px;
    margin: 6px;

  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: 150px;
    width: 263px;
    justify-content: space-around;
    left: 301px;
    height: 16px;
    font-size: 10px;
    margin: 6px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: 155px;
    width: 263px;
    justify-content: space-around;
    left: 361px;
    height:16px;
    font-size: 11px;
    margin: 6px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: 155px;
    width: 263px;
    justify-content: space-around;
    left: 361px;
    height:16px;
    font-size: 11px;
    margin: 6px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: 155px;
    width: 263px;
    justify-content: space-around;
    left: 361px;
    height:16px;
    font-size: 11px;
    margin: 6px;

  }
`;

export const MenuFooter = styled("div")`
  font-family: 'ROG';
  position: absolute;
  display: flex;
  top: 204px;
  justify-content: space-around;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  width: 260px;
    left: calc(50% - 132px);
    font-size:8px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 400px;
    left: calc(50% - 200px);
    font-size:8px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 560px;
    left: calc(50% - 280px);
    font-size:10px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  width: 560px;
  left: calc(50% - 280px);
  font-size:10px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width: 560px;
    left: calc(50% - 280px);
    font-size:10px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  width: 560px;
    left: calc(50% - 280px);
    font-size:10px;
}

a{
  text-decoration:none;
  color:#ffcc33;
}
`

export const TermsOfUse = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`

export const PrivacyPolicy = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`

export const SiteMap = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`

export const ContactDetails = styled("div")`
display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  text-decoration:none;
  color:#ffcc33;
  width:100%;
`


/*  Break Point Summary 
breakpoints: {
  xs: 0,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/