import styled from "@emotion/styled";

export const LoginContainer = styled("div")`
display:flex;
flex-direction:column;
`

export const LoginBlock = styled("div")`
display:flex;
flex-direction:column;
`

export const LoginUsername = styled("div")`

`
export const LoginSignOut = styled("div")`

`

export const ProfileBlock = styled("div")`
display:flex;
flex-direction:column;
margin-right:auto;
margin-left:auto;

`
export const Profile = styled("div")`
border:solid 1px #990000;
width:50%;
`