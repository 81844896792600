import styled from "@emotion/styled";

export const Form = styled('div')`
width: 350px;
margin: 32px;
margin:auto;
`;

export const FullWidthTextInput = styled('input')`
display: block;
width: 100%;
border: 2px solid #ddd;
border-radius: 8px;
box-sizing: border-box;
font-size: 16px;
font-weight: bold;
outline: none;
padding: 16px;

::placeholder {
    color: #ddd;
}
`;

export const FullWidthButton = styled('button')`

  display: block;
  width: 100%;
  background-color: #ffcc33;
  border: none;
  border-radius: 8px;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  padding: 8px;
  text-transform: uppercase;

  :disabled {
      background-color: #ccc;
  }
`;

export const CreateAccountButton = styled('button')`
  position: relative;
  top: 104px;
  margin-top: -72px;
  display: block;
  width: 100%;
  background-color: #a4d22a;
  border: none;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  padding: 8px;
  text-transform: uppercase;

  :disabled {
      background-color: #ccc;
  }
`;

export const ErrorMessage = styled('button')`
background-color: #ee7777;
    border-radius: 8px;
    color: #fff;
    padding: 16px;
    text-align: center;
    width: 100%;
`;

export const Heading = styled('h1')`
    font-weight: bold;
    margin-top: 0px;
    padding-top: 16px;
    text-align: center;
`;

