import React, { useState, useRef, useEffect } from "react";
import {
  ElementContainer,
  ElementImg,
} from "./LinearImageAnimationElementStyle.js";

import { theme } from "../../themes/greyscaleTheme";
import { useWindowDimensions } from "../../app/WindowDimensionsProvider";
import { getPortalOffset } from "../../utils/PortalOffset";

export default function LinearImageAnimationElement(props) {
  const { width, height } = useWindowDimensions();
  let audioForward = null;
  let audioBack = null;
  if(props.forwardAudio){
   audioForward = new Audio(
    process.env.PUBLIC_URL + theme.themePath + props.forwardAudio
  );
  }

  if(props.backwardAudio){
   audioBack = new Audio(
    process.env.PUBLIC_URL + theme.themePath + props.backwardAudio
  );
  }
  const {centerWidth } = getPortalOffset(
    width,
    height
  );

  const imgRef = useRef();
  const imgIdxRef = useRef(0);
  const currentWidth = useRef();
  const [imgOverStack] = useState(props.imgArray);

  useEffect(() => {
    let intervalDown, intervalUp;
    const handleContract = () => {
      try {
        clearInterval(intervalDown);
        intervalUp = setInterval(() => {
          if (imgIdxRef.current === imgOverStack.length - 1) {
            imgIdxRef.current = imgOverStack.length - 1;
            imgRef.current.style.width = "0px";
            clearInterval(intervalUp);
          } else if (imgIdxRef.current < imgOverStack.length) {
            imgIdxRef.current += 1;

            currentWidth.current = imgRef.current.style.width.substr(
              0,
              imgRef.current.style.width.length - 2
            );
            let modifier = Number(centerWidth * 0.1);

            let delta = Number(currentWidth.current) - modifier;

            if (currentWidth.current < modifier) {
              delta = currentWidth.current % modifier;
            }

            if (currentWidth.current > modifier) {
              imgRef.current.style.width = delta + "px";
            }
          }
          imgRef.current.src =
            process.env.PUBLIC_URL + imgOverStack[imgIdxRef.current];
        }, 30);
      } catch(e) {
        console.log("MouseOver: ", e);
      }
    };

    const handleExpand = () => {
      try {
        clearInterval(intervalUp);
        intervalDown = setInterval(() => {
          if (imgIdxRef.current > 0) {
            imgIdxRef.current -= 1;

            currentWidth.current = imgRef.current.style.width.substr(
              0,
              imgRef.current.style.width.length - 2
            );
            let modifier = Number(centerWidth * 0.1);

            let delta = Number(currentWidth.current) + modifier;

            if (currentWidth.current <= Number(centerWidth)) {
              imgRef.current.style.width = delta + "px";
            }
          } else if (imgIdxRef.current === 0) {
            imgIdxRef.current = 0;
            imgRef.current.style.width = Number(centerWidth);
            clearInterval(intervalDown);
          }
          imgRef.current.src =
            process.env.PUBLIC_URL + imgOverStack[imgIdxRef.current];
        }, 30);
      } catch(e) {
        console.log("MouseOut: ",e)
      }
    };

    if (props.expand === "expand") {
      if(audioForward){
        audioForward.play();
      }
      handleExpand();
    } else if (props.expand === "contract") {
      if(audioBack){
        audioBack.play();
      }
      handleContract();
    }
  }, [props.expand, audioBack, audioForward, centerWidth, imgOverStack]);

  return (
    <ElementContainer>
      <ElementImg
        ref={imgRef}
        alt="Expander"
        src={process.env.PUBLIC_URL + imgOverStack[0]}
        style={{ width: "0px" }}
      />
    </ElementContainer>
  );
}
