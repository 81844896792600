import { useEffect } from 'react';
import request from '../utils/request';
import { appConfig } from '../config/data/appConfig.js';

export const useAxiosRequestResourceDetails = (slug, dispatch) => {

  const getData = async (requestURL) =>{
    dispatch({ type: 'FETCHING_RESOURCE_DETAILS', fetching: true })

    await request({
      method: 'get',
      url: `${requestURL}`
    })
    .then(resources => {
      dispatch({ type: 'RETURN_RESOURCE_DETAILS', resources });
      dispatch({ type: 'FETCHING_RESOURCE_DETAILS', fetching: false });
    })
    .catch(e => {
      //console.log('FETCHING RESOURCE_DETAILS - ERROR');
      dispatch({ type: 'FETCHING_RESOURCE_DETAILS', fetching: false });
    })

  }

  useEffect(() => {
    //"resourceDetails": "https://www.coreylines.com/byt3m3/wp-json/wp/v2/resources?slug={{slug}}",
    const resourceDetailsUrl = appConfig.apiUrls.resourceDetails
    .replace('{{slug}}', slug);

    getData(resourceDetailsUrl);

  }, [slug, dispatch])
}
