import React, {useEffect} from "react";
//import MyCombinator from "../../components/MyCombinator"
import ThreePortal from "../../components/ThePortal";
import { PageContent } from "../PagesStyle";
import MetaHead from "../../components/MetaHead";
import { WelcomeContentContainer, SocialLinksContainer } from "./PortalStyle";

export default function Portal(props, { path }) {
  console.log('load Welcome');
  const { size } = props;
  useEffect(() =>{
    console.log('init Welcome');
  },[]);

  return (
    <>
      <MetaHead
      page="Portal"
      title="Welcome to coreylines.com"
      description="Welcome to my personal collection of posts and resources."
      image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PageContent id="pageContent">
        <WelcomeContentContainer name="welcomeContentContainer">
          <ThreePortal size={size} name="threePortal" />
        </WelcomeContentContainer>


       
      </PageContent> 

              
    </>
  );
}
