import styled from "@emotion/styled";
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const TabletLeftBezel = styled("div")`
display:none;
background-position: center;
background-repeat: no-repeat;
margin-left:10px;;
margin-right:auto;
position: relative;
z-index:0;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_left_min}");
  display:block;
  left:-94px;
  top:187px;
  width: 175px;
  height: 128px;
  background-size: 175px 128px;
  margin-left:auto;
  margin-right:auto;
  //transform:rotate(3deg);
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_left_min}");
  display:block;
  left:-120px;
  top:0px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_left_skewed}");
  display:block;
  top:16px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_left_skewed}");
  display:block;
  top:11px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_left_skewed}");
  display:block;
  top:180px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;

}

//xxl 1400+
@media (min-width:  ${xxl}px){
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_left_skewed}");
  display:block;
  top:229px;
  width: 315px;
  height: 230px;
  background-size: 315px 230px;

}


`;
export const LeftScreen = styled("div")`
position:relative;
text-align: center;
display:inline-block;
z-index:10;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top: 14px;
  left: 41px;
  height: 75px;
  width:123px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top: -4px;
  left: 55px;
  height: 98px;
  width:165px;
}

//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top: 0px;
  left: 63px;
  height: 94px;
  width:158px;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top: 0px;
  left: 63px;
  height: 94px;
  width:158px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  top: 21px;
  left: 63px;
  height: 94px;
  width:158px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  width:218px;
  height:130px;
  top:26px;
  left:82px;
}
`;
export const TabletScreenContentLeft = styled("img")`
position:relative;
text-align:center;
display:inline-block;
z-index:10;
width:100%;
height:100%;


//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  border-radius 6px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  border-radius 6px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  clip-path: polygon(0 0, 100% 8%, 100% 95%, 0% 100%);
  border-radius 6px;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  clip-path: polygon(0 0, 100% 8%, 100% 95%, 0% 100%);
  border-radius 8px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  clip-path: polygon(0 0, 100% 8%, 100% 95%, 0% 100%);
  border-radius 12px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  clip-path: polygon(0 0, 100% 8%, 100% 95%, 0% 100%);
  border-radius 12px;
}
`;
export const LeftControls = styled("div")`

//default
position: relative;
opacity:0.7;
z-index: 150;


//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top: 124px;
  left: 56px;
  width: 100px;
  
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top: 120px;
  left: 56px;
  width: 100px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top: 124px;
  left: 56px;
  width: 100px;
  transform:rotate(-2deg);
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top: 124px;
  left: 56px;
  width: 100px;
  transform:rotate(-2deg);
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  top: 117px;
  left: 68px;
  width: 100px;
  transform:rotate(-2deg);
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  top: 163px;
  left: 93px;
  width: 130px;
  transform:rotate(-2deg);
}
`;