import React, { useEffect, useReducer, useRef, useState } from "react";
import MetaHead from "../../components/MetaHead";
import Cams from "../../components/Cams/index.js";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";
import { useRecoilState } from "recoil";
import { theme } from "../../themes/greyscaleTheme";
import { css } from "@emotion/react";
import { appConfig } from "../../config/data/appConfig.js";
import { useRequestList } from "../../hooks/useRequestList.js";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll.js";
import { fadeIn } from "../../uix/animation/keyframes";
import {pageReducer, listReducer} from "../reducer.js";
import { mainMenuState } from "../../config/state/uiState";
import { ListContainer, PageSpinner, PageEmpty } from "../PageListStyle.js";
import { PanelPageRoot} from "../PanelPageStyle";
import ShareItem from "../ShareItem";

export default function Shares({ path }) {
  const spinnerRef = useRef();
  let bottomBoundaryRef = useRef(null);
  
  const [menuState, setMenuState] = useRecoilState(mainMenuState);
  const [showHeader, setShowHeader] = useState(false);
  
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  const [sharesData, sharesDispatch] = useReducer(listReducer, {
    items: [],
    fetching: true,
  });

  useRequestList(
    pager, 
    sharesDispatch, 
    appConfig.apiUrls.shareList, 
    10
    );
  
  useInfiniteScroll(
    bottomBoundaryRef, 
    pagerDispatch
    );

  useEffect(() => {
    setMenuState({ activePath: "shares" });
  }, [setMenuState]);

  return (
    <main>
      <MetaHead
        page="Posts"
        title="Posts on CoreyLines.com"
        description="Shared posts about stuff and things..."
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PanelPageRoot id="PanelPageRoot">
        <Cams name="cams"/>
        <DisplayAdBanner name="displayBanner"/>
        <ListContainer>
          {sharesData.items.map((share, index) => {
            const { slug, acf, modified_gmt, date_gmt } = share;
            return (
              <div key={index}>
                {index >= 0 && (
                  <ShareItem
                    name={`ShareItem${index}`}
                    slug={slug}
                    acfTitle={acf.share_title}
                    acfCategory={acf.share_category}
                    modifiedGmt={modified_gmt}
                    dateGmt={date_gmt}
                    route={`share`}
                    index={index}
                  />
                )}
              </div>
            );
          })}
          <div style={{ height: "60px", display: "block" }}></div>
        </ListContainer>
        {sharesData.fetching && (
          <PageSpinner
            ref={spinnerRef}
            css={css`
              animation: ${fadeIn} 3s ease infinite;
            `}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                theme.themePath +
                theme.media.MiscImages.spinner
              }
              alt="Spinner"
            />
          </PageSpinner>
        )
        }
        {sharesData.items.length === 0 && <PageEmpty>Fetching...</PageEmpty>}
        {sharesData.items.length >= 0 && (
          <div
            id="page-bottom-boundary"
            style={{ border: "1px solid rgb(6, 6, 6, 0.0)" }}
            ref={bottomBoundaryRef}
          ></div>
        )}
      </PanelPageRoot>
    </main>
  );
}
