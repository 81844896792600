import { firestore, fireDoc } from '../../lib/firebase';
import { getDoc, writeBatch } from "firebase/firestore";
import { useContext, useState, useEffect, useCallback } from 'react';
import { UserContext } from '../../lib/context';
import debounce from 'lodash.debounce';
import Profile from '../Profile';
import {
  SignInForm,
  SignInUserFormContainer
} from './SignInUserFormStyle';
export function SignInUserForm(props) {
  const [formValue, setFormValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const {user, username} = useContext(UserContext);
  const [isSaved, setIsSaved] = useState(false);

  useEffect (() => {
    checkUsername(formValue);
  },[formValue]);

  const onChange = (e) => {
    const val = e.target.value.toLowerCase();
    const re = /^(?=[a-zA-Z0-9._]{3,15}$)(?!.*[_.]{2})[^_.].*[^_.]$/;

    // Only set form value if length is < 3 OR it passes regex
    if (val.length < 3) {
      setFormValue(val);
      setLoading(false);
      setIsValid(false);
    }

    if (re.test(val)) {
      setFormValue(val);
      setLoading(true);
      setIsValid(false);
    }
  }

const onSubmit = async (e) => {
  e.preventDefault();
  //console.log('Submit');
  const batch = writeBatch(firestore);

  const userDoc = fireDoc(firestore,`users`,user.uid);
  batch.set(userDoc, { username: formValue, photoURL: user.photoURL, displayName: formValue });

  const usernameDoc = fireDoc(firestore, `username`,formValue);
  batch.set(usernameDoc, { uid: user.uid });

  await batch.commit();
  setIsSaved(true);
  
  //username(formValue);
  //console.log('Submit - Commit');
  props.close();
}

function UsernameMessage({ username, isValid, loading }) {
  if (loading) {
    return <p>Checking...</p>;
  } else if (isValid) {
    return <p className="text-success">{username} is available!</p>;
  } else if (username && !isValid) {
    return <p className="text-danger">That username is taken!</p>;
  } else {
    return <p></p>;
  }
}


const checkUsername = useCallback(

debounce(async (username) => {
  if(username.length >= 3){
    //console.log(username);
    const ref = fireDoc(firestore, `username`, username);
    const { exists } = await (await getDoc(ref)).exists();
    //console.log('Firestore read executed!');
    setIsValid(!exists);
    setLoading(false);
  }
},500),
[]
);

  return (
      <SignInForm>
      {
        !username && (
          !isSaved && (
            <SignInUserFormContainer>
            <section>
            <h3>Choose Username</h3>
            <form onSubmit={onSubmit}>
            <input name="username" placeholder="username" value={formValue} onChange={onChange}></input>
            <UsernameMessage username={formValue} isValid={isValid} loading={loading} />
            <button type="submit" className="btn-green" disabled={!isValid}>
            Choose
            </button>

            <h3>Debug State</h3>
            <div>
              Username: {formValue}
              <br />
              Loading: {loading.toString()}
              <br />
              Username Valid: {isValid.toString()}
            </div>
            </form>
            </section>
            </SignInUserFormContainer>
          )
        )
      }
      {
        isSaved && (

            <UserContext.Provider value={{username:formValue}}>
            <SignInUserFormContainer>
                <Profile/>
            </SignInUserFormContainer>
            </UserContext.Provider>
          )
        
      }
      </SignInForm>
    );
  }