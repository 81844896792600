import React, { useEffect, useReducer, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import MetaHead from "../../components/MetaHead";
import Cams from "../../components/Cams/index.js";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";
import { theme } from "../../themes/greyscaleTheme";
import { css } from "@emotion/react";
import { appConfig } from "../../config/data/appConfig.js";
import { useRequestList } from "../../hooks/useRequestList.js";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll.js";
import { fadeIn } from "../../uix/animation/keyframes";
import {pageReducer, listReducer} from "../reducer.js";
import { mainMenuState } from "../../config/state/uiState";
import { PanelPageRoot} from "../PanelPageStyle";
import { ListContainer, PageSpinner, PageEmpty } from "../PageListStyle.js";

import ResourceItem from "../ResourceItem";

export default function Resources(props) {
  const spinnerRef = useRef();
  let bottomBoundaryRef = useRef(null);

  const [menuState, setMenuState] = useRecoilState(mainMenuState);
  const [showHeader, setShowHeader] = useState(false);

  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  const [resourceData, resourceDispatch] = useReducer(listReducer, {
    items: [],
    fetching: true,
  });
 
  useRequestList(
    pager,
    resourceDispatch,
    appConfig.apiUrls.resourcesListRaw,
    10
  );

  useInfiniteScroll(
    bottomBoundaryRef, 
    pagerDispatch
    );

  useEffect(() => {
    setMenuState({ activePath: "resources" });
  }, [setMenuState]);

  return (
    <main>
      <MetaHead
        page="Resources"
        title="Resources on CoreyLines.com"
        description="Useful things, helpful stuff, and other distractions"
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
       <PanelPageRoot id="PanelPageRoot">
        <Cams name="cams"/>
        <DisplayAdBanner name="displayBanner"/>
        <ListContainer name="ListContainer">
          {resourceData.items.map((resource, index) => {
            const { slug, acf, modified_gmt, date_gmt } = resource;
            return (
              <div key={index}>
                {index >= 0 && (
                  <ResourceItem
                    name={`ResourceItem${index}`}
                    slug={slug}
                    acfTitle={acf.resource_title}
                    acfCategory={acf.resource_category}
                    modifiedGmt={modified_gmt}
                    dateGmt={date_gmt}
                    route={`resource`}
                    index={index}
                  />
                )}
              </div>
            );
          })}
          <div style={{ height: "60px", display: "block" }}></div>
        </ListContainer>
        {resourceData.fetching && (
          <PageSpinner
            ref={spinnerRef}
            css={css`
              animation: ${fadeIn} 3s ease infinite;
            `}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                theme.themePath +
                theme.media.MiscImages.spinner
              }
              alt="Spinner"
            />
          </PageSpinner>
        )}
        {resourceData.items.length === 0 && <PageEmpty>Fetching...</PageEmpty>}
        {resourceData.items.length >= 0 && (
          <div
            id="page-bottom-boundary"
            style={{ border: "1px solid rgb(6, 6, 6, 0.0)" }}
            ref={bottomBoundaryRef}
          ></div>
        )}
      </PanelPageRoot>
    </main>
  );
}
