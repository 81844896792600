export const getPortalOffset = (width, height) =>{
  let canvasWidth = 0;
  let canvasHeight = 0;
  let centerWidth = 0;

  if (width >= 360) {
    canvasWidth = 264;
    canvasHeight = 187;
    centerWidth = 80;
  }
  
  if (width >= 768 && height <= 620) {
    canvasWidth = 508;
    canvasHeight = 373;
    centerWidth = 135;
  }
  
  if (width >= 768 && height > 620) {
    canvasWidth = 508;
    canvasHeight = 373;
    centerWidth = 135;
  }
  
  if (width >= 1200 && height <= 620) {
    canvasWidth = 508;
    canvasHeight = 373;
    centerWidth = 135;
  }
  
  if (width >= 1200 && height > 620) {
    canvasWidth = 680;
    canvasHeight = 497;
    centerWidth = 180;
  }
  return {canvasWidth, canvasHeight, centerWidth};
}