import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../app/ResponsiveLayout/ResponsiveBreakpoints";
import { theme } from "../themes/greyscaleTheme";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
const panel = keyframes`
  0% {background-position: 0% 50%; opacity:.4}
  50% {background-position: 200% 50%; opacity:.9}
  100% {background-position: 0% 50%; opacity:.4}
`;
const gradient = keyframes`
  0% {opacity:.3}
  50% {opacity:.1}
  100% {opacity:.3}
`;

export const PanelContainerBg = styled("div")`
  //background-image: url("${theme.themePath}/large/bg-tiles/page-bg-center.png");
  display: block;
  opacity: 0.7;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  //background-repeat: no-repeat;
  //background-position: center center;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    //background-size: 68% 68%;
    width: 68%;
    height: 68%;
    top: 844px;
    margin-top: -950px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    //background-size: 68% 68%;
    width: 68%;
    height: 68%;
    top: 844px;
    margin-top: -950px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 68% 68%;
    width: 68%;
    height: 68%;
    top: 844px;
    margin-top: -950px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    //background-size: 68% 68%;
    width: 68%;
    height: 68%;
    top: 844px;
    margin-top: -950px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    //background-size: 70% 70%;
    width: 70%;
    height: 70%;
    top: 844px;
    margin-top: -878px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    //background-size: 78% 78%;
    width: 78%;
    height: 78%;
    top: 844px;
    margin-top: -950px;
  }
`;
export const PanelContainerBgLeft = styled("div")`
  background-image: url("${theme.themePath}/large/bg-tiles/page-bg-001.png");
  display: block;
  opacity: 0.7;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-size: 327px 420px;
    width: 327px;
    height: 420px;
    top: 500px;
    margin-top: -534px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-size: 350px 530px;
    width: 350px;
    height: 530px;
    top: 844px;
    margin-top: -875px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 490px 650px;
    width: 690px;
    height: 650px;
    top: 844px;
    margin-top: -875px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: 640px 650px;
    width: 640px;
    height: 650px;
    top: 844px;
    margin-top: -875px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 418px;
    height: 1024px;
    //top: 844px;
    //margin-top: -875px;
  }
`;

export const PanelContainerBgRight = styled("div")`
  background-image: url("${theme.themePath}/large/bg-tiles/page-bg-002.png");
  display: block;
  opacity: 0.7;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-size: 327px 420px;
    width: 327px;
    height: 420px;
    top: 500px;
    margin-top: -534px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-size: 350px 530px;
    width: 350px;
    height: 530px;
    top: 844px;
    margin-top: -875px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: 490px 650px;
    width: 690px;
    height: 650px;
    top: 844px;
    margin-top: -875px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: 640px 650px;
    width: 640px;
    height: 650px;
    top: 844px;
    margin-top: -875px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-size: 1024px 418px;
    width: 418px;
    height: 1024px;
    top: 844px;
    margin-top: -875px;
  }
`;
export const PanelContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  z-index: 10;
  position: relative;
  background-color: rgba(0, 0, 0, 0.72); // #000000;
  border: solid 2px rgba(98, 233, 245, 1);
  margin-bottom: 20px;
  padding: 10px;
  //opacity: 0.92;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 0px;
    width: 80%;
    border-radius: 27px;
    top: -45px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 0px;
    width: 80%;
    border-radius: 27px;
    top: -105px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 0px;
    width: 80%;
    border-radius: 27px;
    top: -105px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 0px;
    width: 80%;
    border-radius: 27px;
    top: -105px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 0px;
    width: 60%;
    border-radius: 27px;
    top: -105px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 0px;
    width: 60%;
    border-radius: 27px;
    top: -105px;
  }
`;

export const PanelRow = styled("div")`
  flex-direction: row;
  display: flex;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //extra small 0-575

  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 5px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 5px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 10px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: 10px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: 15px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: 15px;
  }
`;

export const PanelFg = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  top: 0px;
`;

export const PanelBg = styled("div")`
  color: #bbe5ed;
  background: linear-gradient(270deg, transparent, #1f1f26, transparent);
  background-size: 200% 200%;
  animation: ${panel} 10s ease infinite;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  position: relative;
  display: block;
  margin-top: -364px;
  border-radius: 10px;
`;

export const PanelAd = styled("div")`
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    top: -45px;
    width: 320px;
    //margin-bottom: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    top: -30px;
    width: 400px;
    //margin-bottom: 85px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    top: -30px;
    width: 570px;
    //margin-bottom: 85px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    top: -76px;
    width: 590px;
    // margin-bottom: 38px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    top: -115px;
    width: 750px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    top: -115px;
    width: 750px;
  }

  img {
    position: relative;
    top: 0px;
    width: inherit;
  }
`;

export const WelcomePanel2 = styled.div``;
export const WelcomePanel3 = styled.div``;


