import React from 'react';
import { SocialLinksContainer } from './ProfileStyle.js';
import {
  DetailsCard,
  DetailsCardHeader,
  DetailsCardBody,
  DetailsCardTitle,
  DetailsCardDescription,
} from "../DetailsStyle";
import { PageList, PageCard, PageContent} from '../PagesStyle';
import SocialLinks from "../../components/SocialLinks";

const Profile = () => {
  return(
    <section>

    <PageContent>
    <PageList>
      <PageCard>
      <DetailsCard rowMod={0}>
      <DetailsCardHeader>
        <DetailsCardTitle
          dangerouslySetInnerHTML={{ __html: "Profile Page" }}
        />
      </DetailsCardHeader>
      <DetailsCardBody>
        <DetailsCardDescription>
        <form>
        <table>
          <tr>
            <td>
            Username:
            </td>
            <td>
            <input type="text" name="ProfileUsername" value="corey"></input>
            </td>
          </tr>
          <tr>
            <td>
            Email:
            </td>
            <td>
            <input type="text" name="profileEmail" value="lns.corey@gmail.com"></input>
            </td>
          </tr>
          <tr>
            <td>
              Display Name:
            </td>
            <td>
              <input type="text" name="profileDisplayName" value="Corey Lines"></input>
            </td>
          </tr>
          <tr>
            <td>
              Alternate Photo Url:
            </td>
            <td>
              <input type="text" name="profilePhotoUrl" value="http://"></input>
            </td>
          </tr>
          <tr>
            <td>
              About Me:
            </td>
            <td>
              <textarea cols="23" rows="10" name="profileAbout" maxlength="500"></textarea>
            </td>
        </tr>
        </table>
        </form>
        </DetailsCardDescription>
      </DetailsCardBody>
    </DetailsCard>

    </PageCard>
    <SocialLinksContainer>
    <SocialLinks></SocialLinks>
    </SocialLinksContainer>
    </PageList>
    </PageContent>
  </section>
  )
}

export default Profile;