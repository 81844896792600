import React from "react";

import {
  Expander,
  LeftEndExpander,
  CenterHExpander,
  RightEndExpander,
} from "./HorizontalDivideBottomStyle.js";

export default function HorizontalDivideBottom() {
  return (
    <Expander>
      <LeftEndExpander />
      <CenterHExpander />
      <RightEndExpander />
    </Expander>
  );
}
