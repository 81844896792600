import React, { useState, useRef, useEffect } from "react";
import { ButtonContainer, ButtonImg } from "./ButtonStyle.js";
import { theme } from "../../../themes/greyscaleTheme";
export default function Button(props) {
  const btnAudio = new Audio(
    process.env.PUBLIC_URL + theme.themePath + props.btnOverAudio
  );
  const imgRef = useRef();
  const imgIdxRef = useRef(0);
  const [imgOverStack] = useState(props.btnOverImgArray);
  const imgClicked = props.btnClickedImg;
  const mediaPath = props.mediaPath;
  const bgPath = props.bgPath;

  let intervalDown, intervalUp;

  useEffect(() => {}, []);

  const handleMouseOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      clearInterval(intervalDown);
      btnAudio.play();
      intervalUp = setInterval(() => {
        if (imgIdxRef.current === imgOverStack.length - 1) {
          imgIdxRef.current = imgOverStack.length - 1;
          clearInterval(intervalUp);
        } else if (imgIdxRef.current < imgOverStack.length) {
          imgIdxRef.current += 1;
        }
        imgRef.current.src =
          process.env.PUBLIC_URL +
          mediaPath +
          bgPath +
          imgOverStack[imgIdxRef.current];
      }, 100);
    } catch (e) {
      console.log("Button: handleMouseOver: Error:\n", e);
    }
  };

  const handleMouseOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      clearInterval(intervalUp);
      intervalDown = setInterval(() => {
        if (imgIdxRef.current > 0) {
          imgIdxRef.current -= 1;
        } else if (imgIdxRef.current === 0) {
          imgIdxRef.current = 0;
          clearInterval(intervalDown);
        }
        imgRef.current.src =
          process.env.PUBLIC_URL +
          mediaPath +
          bgPath +
          imgOverStack[imgIdxRef.current];
      }, 100);
    } catch (e) {
      console.log("Button: handleMouseOut: Error:\n", e);
    }
  };

  const handleClick = (e) => {
    //addItemClicked();
    clearInterval(intervalUp);
    clearInterval(intervalDown);
    imgRef.current.src =
      process.env.PUBLIC_URL + mediaPath + bgPath + imgClicked;
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      clearInterval(intervalDown);
      btnAudio.play();
      intervalUp = setInterval(() => {
        if (imgIdxRef.current === imgOverStack.length - 1) {
          imgIdxRef.current = imgOverStack.length - 1;
          imgRef.current.click();
          clearInterval(intervalUp);
          return true;
        } else if (imgIdxRef.current < imgOverStack.length) {
          imgIdxRef.current += 1;
        }
        imgRef.current.src =
          process.env.PUBLIC_URL +
          mediaPath +
          bgPath +
          imgOverStack[imgIdxRef.current];
      }, 100);
      // }
    } catch (e) {
      console.log("Button: handleTouchStart: Error:\n", e);
    }
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {

    } catch (e) {
      console.log("Button: handleTouchEnd: Error:\n", e);
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleTouchCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
const handleOnClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
  clearInterval(intervalUp);
  clearInterval(intervalDown);
  props.handleOnClick();
};
  return (
    <ButtonContainer id="btnBG">
      <ButtonImg
        ref={imgRef}
        onClick={handleOnClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchCancel={handleTouchCancel}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        alt={props.btnTxt}
        src={process.env.PUBLIC_URL + mediaPath + bgPath + imgOverStack[0]}
      />
    </ButtonContainer>
  );
}
