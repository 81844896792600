import styled from "@emotion/styled";
//import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../app/ResponsiveLayout/ResponsiveBreakpoints";
//import { theme } from "../themes/greyscaleTheme";

export const ListContainer = styled("div")`
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 85px;
    margin-left:22px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 60px;
    margin-left:10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 10px;
    margin-left: 14px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -80px;
    margin-left: 16px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -80px;
    margin-left: 20px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -80px;
    margin-left: 24px;
  }

`;

export const ListItemContainer = styled("div")`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  height: 85px;
  padding: 3px 10px 3px 3px;
  margin-bottom: 25px;
  border: rgba(102, 255, 255, .72) solid 3px;
  border-radius: 27px 27px 27px 27px;
  background-color: rgba(0,0,255, 0.1);
  background-repeat: no-repeat;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width:${xs-40}px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: ${sm-30}px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: ${md-20}px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: ${lg-50}px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width:  ${lg-50}px;
  }

//xxl 1400+
@media (min-width:  ${xxl}px){
  width: ${lg-50}px;

}

`;

export const ListItemType = styled("div")`
  position: absolute;
  display:flex;
  flex-direction: row;
  font-family: "ROG";
  align-items: center;
  justify-items: center;
  opacity: 0.27;
  align-content: space-between;
  justify-content: space-around;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
    width: 327px;
    top: 56px;
    height: 17px;
    left: 7px;
  }
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 442px;
    left: 67px;
    height: 18px;
    line-height: 14px;
    top: 56px;
    font-size: 14px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 620px;
    left: 75px;
    height: 18px;
    line-height: 16px;
    top: 56px;
    font-size: 16px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 18px;
    width: 249px;
    top: 66px;
    height: 20px;
    left: 346px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 18px;
    width: 249px;
    top: 66px;
    height: 20px;
    left: 346px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    font-size: 18px;
    width: 249px;
    top: 66px;
    height: 20px;
    left: 346px;
  }
`;

export const ListItemCategory = styled("div")`
  position: absolute;
  display: inline-block;
  animation: ease 0.5s;
  transition: 0.5s;
  width:0px;
  height: 0px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 50px;
    height: 50px;
    top: 40px;
    left: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 65px;
    height: 65px;
    top: 15px;
    left: 12px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 65px;
    height: 65px;
    top: 15px;
    left: 12px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 80px;
    height: 80px;
    top: 5px;
    left: 16px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 80px;
    height: 80px;
    top: 5px;
    left: 16px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 80px;
    height: 80px;
    top: 5px;
    left: 16px;
  }

  img {
    width: 100%;
  }
`;

export const ListItemTitle = styled("div")`
  position: absolute;
  display: inline-block;
  display: grid;
  align-items: center;
  height: 60px;
  text-align: center;
  color: ${(props) => props.theme.colors.yellow};

  a {
    text-decoration: none;
    text-weight: bold;
    color: ${(props) => props.theme.colors.yellow};
  }

  a:hover {
    color: ${(props) => props.theme.colors.powder_blue};
  }

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 16px;
    width: 320px;
    left: 7px;
    height:38px;
    line-height: 16px;
    top: 2px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 18px;
    width: 442px;
    left: 67px;
    height: 42px;
    line-height: 18px;
    top: 2px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 20px;
    width: 612px;
    left: 75px;
    height: 42px;
    line-height: 20px;
    top: 2px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 22px;
    font-weight: bold;
    top: 2px;
    width: 875px;
    left: 33px;
    line-height: 22px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 20px;
    font-weight: bold;
    top: 2px;
    width: 875px;
    left: 33px;
    line-height: 20px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    font-size: 20px;
    font-weight: bold;
    top: 2px;
    width: 875px;
    left: 33px;
    line-height: 20px;
  }
`;

export const ListItemTitleText = styled("div")`
  text-overflow: ellipsis;
`;

export const ListItemCategoryName = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  text-align: center;
  font-size: 12px;
  position: absolute;
  display: grid;
  align-items: center;
  text-transform: uppercase;
  font-family: 'ROG';
  opacity: 0.42;
  color: #66ffff;
  top: 58px;
  height: 12px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 327px;
    left: 7px;
    font-size: 12px;
    height: 24px;
    text-align:center;
    top:37px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 442px;
    left: 67px;
    height: 42px;
    line-height: 18px;
    top: 27px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    height: 18px;
    line-height: 18px;
    top: 42px;
    width: 620px;
    left: 75px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 16px;
    opacity: 0.42;
    width: 875px;
    left: 33px;
    top:48px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 16px;
    opacity: 0.42;
    width: 875px;
    left: 33px; 
    top:48px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    font-size: 16px;
    opacity: 0.42;
    width: 875px;
    left: 33px;
    top:48px;
  }

`;

export const ListItemPublishDate = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  position:absolute;
  color: ${(props) => props.theme.colors.powder_blue};
  display: grid;
  align-items: center;
  justify-items: center;

  ${'' /* display: flex;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
  flex-direction: row; */}

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 130px;
    top: 75px;
    left: 54px;
    font-size: 10px;
    height: 16px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 150px;
    top: 75px;
    left: 75px;
    font-size: 12px;
    height: 16px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 170px;
    top: 75px;
    left: 75px;
    font-size: 14px;
    height: 16px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 200px;
    top: 70px;
    left: 100px;
    font-size: 16px;
    height: 16px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 200px;
    top: 70px;
    left: 100px;
    font-size: 16px;
    height: 16px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 200px;
    top: 70px;
    left: 100px;
    font-size: 16px;
    height: 16px;
  }
`;

export const ListItemModifyDate = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  position:absolute;
  color: ${(props) => props.theme.colors.powder_blue};
  display: grid;
  align-items: center;
  justify-items: center;
  ${'' /* display: flex;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
  flex-direction: row; */}
  
  //extra small 360-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 130px;
    top: 75px;
    left: 192px;
    font-size: 10px;
    height: 16px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 150px;
    top: 75px;
    left: 361px;
    font-size: 12px;
    height: 16px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 170px;
    top: 75px;
    left: 551px;
    font-size: 14px;
    height: 16px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 200px;
    top: 70px;
    left: 660px;
    font-size: 16px;
    height: 16px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 200px;
    top: 70px;
    left: 660px;
    font-size: 16px;
    height: 16px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 200px;
    top: 70px;
    left: 660px;
    font-size: 16px;
    height: 16px;;
  }
`;

export const ListContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  padding-left: 10px;
`;

export const ListFilter = styled("div")`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 5px;
  visibility: hidden;
`;

export const ListItemColumn = styled("div")`
  display: flex;
  flex-direction: column;

`;

export const ListItemImgContainer = styled("div")`
animation: ease 0.5s;
  transition: 0.5s;
  width: 100%;
  display: inline-block;
  text-align: center;


  a {
    display: inline-block;
  }

  @media (min-width: 768px) {
    max-width: 150px;
    width: 150px;
      }

  @media (min-width: 1200px) {
    max-width: 175px;
    width: 175px;

  }
`;

export const ListItemImg = styled("img")`
  max-width: 4rem;
  max-height: 2rem;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: 5rem;
    max-height: 3rem;
  }
`;

export const ResourcesEmpty = styled("div")`
  text-align: center;
  font-family: "ROG";
  font-size: 14px;
  display: block;
  height: 50vh;
  margin: auto;
  padding: 70px 0;
`;




/*  Break Point Summary 
breakpoints: {
  xs: 360,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/