import React, { useReducer } from "react";
import MetaHead from "../../components/MetaHead";
import { useAxiosRequestResourceDetails } from "../../hooks/useAxiosRequestResourceDetails.js";
import { getCategoryImage, getCategoryAlt } from "../../utils/getCategoryImage";
import { theme } from "../../themes/greyscaleTheme";
//import ExternalLinkIcon from "../../uix/icons/ExternalLinkIcon";
import Cams from "../../components/Cams";
import { PanelPageRoot, PanelFlexContainer } from "../PanelPageStyle";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";
import {
  DetailsCard,
  DetailsCardTitle,
  DetailsCardDescription,
  DetailsCardCategoryName,
  DetailsCardCategoryImage,
  DetailsCardUrl,
  DetailsCardPublishDate,
  DetailsCardModifyDate,
  DetailsTitleLink,
  DetailsInfo,
  DetailsCardType,
} from "../DetailsStyle";

export default function ResourceItemDetails(props) {
  const { id } = props;

  const resourceDetailsReducer = (state, action) => {
    switch (action.type) {
      case "RETURN_RESOURCE_DETAILS":
        return { ...state, resources: action.resources };
      case "FETCHING_RESOURCE_DETAILS":
        return { ...state, fetching: action.fetching };
      default:
        return state;
    }
  };

  /*
  const pageReducer = (state, action) => {
    switch (action.type) {
      case "ADVANCE_PAGE":
        return { ...state, page: state.page + 1 };
      default:
        return state;
    }
  };
*/
  //const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });
  const [resourceData, resourceDispatch] = useReducer(resourceDetailsReducer, {
    resources: [],
    fetching: true,
  });
  useAxiosRequestResourceDetails(id, resourceDispatch);

  return (
    <article>
      {resourceData.resources.map((resource, index) => {
        const { acf, modified_gmt, date_gmt } = resource;
        console.log("date_gmt",date_gmt);
        console.log("modified_gmt",modified_gmt);
        return (
          <>
          <MetaHead
            page="Resource Item Details"
            title={`A Resource from CoreyLines.com`}
            description={`${acf.resource_title}`}
            image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
            />
            <PanelPageRoot id="PanelPageRoot">
              <Cams />
              <DisplayAdBanner/>
              <PanelFlexContainer>
              <DetailsCard key={index}>
              <DetailsCardTitle name="title"> 
                <DetailsTitleLink href={acf.resource_url} target="_blank" rel="noreferrer"
                    dangerouslySetInnerHTML={{ __html: acf.resource_title }}
                >
                 
                </DetailsTitleLink>
                </DetailsCardTitle> 
                {/* <ExternalLinkIcon width="24" height="24" fill="#ffcc33" /> */}
                {/* {acf.resource_image && (
                  <DetailsCardUrlImage name="resource-img">
                    <img
                      src={`${acf.resource_image}`}
                      alt={`${getCategoryAlt(acf.resource_category)}`}
                    />
                  </DetailsCardUrlImage>
                )} */}
                <DetailsCardCategoryName name="category-name"
                      dangerouslySetInnerHTML={{
                        __html: getCategoryAlt(acf.resource_category),
                      }}
                />
                <DetailsCardType name="category-type">
                  Resource
                </DetailsCardType>
                <DetailsInfo/>
                
                
                <DetailsCardUrl name="url">
                  <a
                    href={acf.resource_url}
                    target="_blank"
                    rel="noreferrer"
                    dangerouslySetInnerHTML={{ __html: acf.resource_url }}
                  />
                </DetailsCardUrl>

                <DetailsCardDescription name="description">
                  <div id="label">Details:</div>
                  <div
                    dangerouslySetInnerHTML={{ __html: acf.resource_description }}
                  />
                </DetailsCardDescription>
                <DetailsCardCategoryImage name="category-img">
                      <img
                        src={`${process.env.PUBLIC_URL}${
                          theme.themePath
                        }/large/content-categories/${getCategoryImage(
                          acf.resource_category
                        )}`}
                        alt={`${getCategoryAlt(acf.resource_category)}`}
                      />
                </DetailsCardCategoryImage>
                <DetailsCardModifyDate name="modify-date"
                    dangerouslySetInnerHTML={{
                      __html: `Updated: ${new Date(modified_gmt).toDateString()}`,
                    }}
                ></DetailsCardModifyDate>
                <DetailsCardPublishDate name="publish-date"
                    dangerouslySetInnerHTML={{
                      __html: `Listed: ${new Date(date_gmt).toDateString()}`,
                    }}
                ></DetailsCardPublishDate>
              </DetailsCard>
            </PanelFlexContainer>
          </PanelPageRoot>
          </>  
        );
      })}
    </article>
    
  );
}
