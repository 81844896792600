import styled from "@emotion/styled";
/*
//default

display:none;

//mobile portrait
@media (min-width: 360px) {
  //width is at least 360px AND the height is anything
  //small sized drop down menu
  //small sized toggle and search
  //small portal
}

//mobile landscape
@media (min-width: 768px) and (max-height: 620px) {

  //width is at least 768px AND the height is at at max 620px
  //medium sized drop down menu
  //medium sized toggle and search
  //small portal

}
//tablet portrait / landscape
@media (min-width: 768px) and (min-height: 621px) {

  //width is at least 768px OR the height is at least 621px
  //medium sized drop down menu
  //medium sized toggle and search
  //medium portal

}
//tablet landscape
@media (min-width: 1200px) and (max-height: 620px) {

  //width is at least 768px AND the height is at least 621px
  //large sized drop down menu
  //large sized toggle and search
  //medium sized portal

}

//desktop / laptop
@media (min-width: 1200px) and (min-height: 621px){
  //large sized drop down menu
  //large sized toggle and search
  //large sized portal
}
*/
export const ButtonContainer = styled("div")`
  margin-bottom: 0px;

  @media (min-width: 768px) {
    margin-bottom: -1px;
    z-index:100;
    position:relative;
    top:-22px;
    left:-45px;
    display:block;
    width:66px;
    height:66px;
  }

  @media (min-width: 1200px) {
    margin-bottom: -1px;
    z-index:100;
    position:relative;
    top:-22px;
    left:-45px;
    display:block;
    width:66px;
    height:66px;
  }
`;

export const ButtonImg = styled("img")`
  cursor:hand;
  //width:152px;

  //width: 244px;
  //height: 36px;

  @media (min-width: 320px) {
    //width: 260px;
    //height: 39px;
  }

  @media (min-width: 768px) {
   // width: 300px;
   // height: 40px;
  }

  @media (min-width: 1200px) {
    display:block;
    width: 66px;
    height: 66px;
    border-radius:33px;

    z-index:110;
  }
`;
