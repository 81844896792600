import React, {useRef, useEffect, useContext} from "react";
import SignIn from '../SignIn';
import SignOut from '../SignOut';
import { SignInUserForm } from "../SignInUserForm/index.js";
import Profile from "../Profile/index.js";
import { UserContext } from '../../lib/context';
import {
  LoginContainer,
  LoginBlock,
  LoginUsername,
  LoginSignOut,
  ProfileBlock
} from "./LoginProfileStyle.js";

export default function LoginProfile(props) {
  const {user, username} = useContext(UserContext);
  return(
    <LoginContainer>
      
      {user ?
          !username ?
            <LoginBlock>
              <SignInUserForm close={props.close} />
              <SignOut />
            </LoginBlock>
          :
            <ProfileBlock>
              <Profile />
              <SignOut />
            </ProfileBlock>
        :
          <SignIn />
      }

    </LoginContainer>
  );
}