import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { menuOpenState } from "../../../config/state/uiState";
import { ForwardButtonImg } from "./ForwardButtonStyle.js";

import { theme } from "../../../themes/greyscaleTheme";

export default function ForwardButton(props) {
  const forwardBtnRef = useRef();
  const imgForwardDefault = "/large/menu-toggle/toggle_forward_default.png";
  const imgForwardOver = "/large/menu-toggle/toggle_forward_over.png";

  const history = useNavigate();
 
  const goForward = () => {
    history(1);
  };

  useEffect(() => {

  }, []);

  return (
    <ForwardButtonImg
      ref={forwardBtnRef}
      alt="Navigate Forward"
      onTouchStart={(e) => e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgForwardOver }
      onTouchEnd={(e) =>e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgForwardDefault }
      onMouseOver={(e) =>  e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgForwardOver }
      onMouseOut={(e) => e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgForwardDefault }
      onClick={(e) => goForward()}
      src={process.env.PUBLIC_URL + theme.themePath + imgForwardDefault}
    />
  );
}
