import { atom } from "recoil";
import {theme} from "../../themes/greyscaleTheme";
import { menuOffsetIs } from "../../utils/MenuOffsetIs";

export const hudState = atom({
  key: "hudStateKey",
  default: {
    hudVisible: false,
  },
});
export const hudData = atom({
  key: "hudDataKey",
  default: {
    "title":"test",
    "content":"testing content",
    "author":"testing author",
    "url":"http://www.coreylines.com"
  },
});
export const appDebugState = atom({
  key: "appDebug",
  default: {
    debugMode: false,
  },
});

export const appViewState = atom({
  key: "appView",
  default: {
    activeView: "none",
  },
});

export const mainMenuState = atom({
  key: "mainMenu",
  default: {
    activePath: "welcome",
  },
});

export const menuConfigRecoilState = atom({
  key: "configMenu",
  default: {
    configState: [0,1,0,0],
  },
}); 

export const appMenuState = atom({
  key: "appMenu",
  default: {
    menuOpen: false,
  },
});

export const clickedMenuState = atom({
  key: "clickedMenu",
  default: {
    clickedItems: ["welcome", "about", "shares", "resources"],
  },
});
export const contentHeightState = atom({
  key: "contentHeight",
  default: 0,
});
export const menuOpenState = atom({
  key: "menuOpen",
  default: {
    menuOpen: false,
  },
});
export const footerOpenState = atom({
  key: "footerOpen",
  default: {
    footerOpen: false,
  },
});
export const searchOpenState = atom({
  key: "searchOpen",
  default: {
    searchOpen: false,
  },
});

export const menuTogglePromptState = atom({
  key: "showMenuTogglePrompt",
  default: {
    showMenuTogglePrompt: true,
  },
});

export const mainSearchState = atom({
  key: "mainSearch",
  default: {
    activeSearch: "",
    lastSearch: "",
    searchType:"",
    searchStartDate: null,
    searchEndDate: null, 
    searchGo: null
  },
});

export const searchTypeState = atom({
  key: "searchType",
  default: {
    selectedType: "any",
  },
});

export const searchSortState = atom({
  key: "searchSort",
  default: {
    selectedSort: "desc",
  },
});

export const searchByState = atom({
  key: "searchBy",
  default: {
    selectedBy: "date",
  },
});

export const searchStartDateState = atom({
  key: "searchStartDate",
  default: {
    selectedStartDate: new Date(`1/1/${new Date().getFullYear()}`),
  },
});

export const searchEndDateState = atom({
  key: "searchEndDate",
  default: {
    selectedEndDate: new Date(),
  },
});

export const isCachingState = atom({
  key: "cachingState",
  default: {
    isCaching: true,
  },
});

export const basicUserState = atom({
  key: "basicUser",
  default: {
      uid: "0",
      email: "you@email.com",
      displayName: "Unauthorized",
      photoURL: "/themes/greyscaleTheme/large/user-details/user-unknown.png",
      emailVerified: false,
      bio: "Enter your bio",
  },
});

export const withImagesArray= atom({
  key: "withImagesArray",
  default: [],
});

export const withVideosArray = atom({
  key: "withVideosArray",
  default: [],
});


