import styled from "@emotion/styled";

export const FooterLinks = styled("div")`
`;

export const CopyrightText = styled("div")`
  font-family: "ROG";
  color: #ffffff;
  font-size: 14px;
  display: block;
  margin-top: 6px;

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }

  h1 {
    font-size: 14px;
    display: inline;
  }
`;

export const MediaPanelSocialLinks = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //width:100%;

  //mobile portrait
  @media (min-width: 360px) {
    width: 19rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -24px;
  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {
    width: 40rem;
    margin-bottom: 4px;
    margin-top: -30px;
  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    width: 40rem;
    margin-bottom: 4px;
    margin-top: -30px;
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    width: 42rem;
    margin-bottom: 4px;
    margin-top: -30px;
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    width: 53rem;
    margin-bottom: 0px;
    margin-top: -45px;
  }

`;

export const SocialLink = styled("div")`
  margin-left: auto;
  margin-right: auto;

  background-image: url("${props => props.theme.themePath}/large/footer/footer-link-panel.png");
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  text-align:center;
  //mobile portrait
  @media (min-width: 360px) {
    img {
      margin-top: 5px;
      width: 20px;
      height: 20px;
    }
  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {
    background-size: 35px 35px;
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;

    img {
      width: 25px;
      height: 25px;
    }
  }
  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {
    background-size: 35px 35px;
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;

    img {
      width: 25px;
      height: 25px;
    }
  }
  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {
    background-size: 35px 35px;
    margin-top: auto;
    margin-bottom: auto;
    width: 35px;
    height: 35px;

    img {
      width: 25px;
      height: 25px;
    }
  }
  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
    background-size: 45px 45px;
    width: 45px;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    

    img {
      width: 35px;
      height: 35px;
    }
  }
`;
