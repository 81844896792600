
import React from 'react';

import {
    PanelContainer,
    PanelContent,
    PanelToggleButton
} from'./RightPanelStyle.js';

export default function RightPanel() {
    return (
        <PanelContainer>
        <PanelContent>Content</PanelContent>
        <PanelToggleButton>JavaScript</PanelToggleButton>
        </PanelContainer>
    );
}