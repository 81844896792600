import React from "react";

import {
AdBanner
} from "./DisplayAdBannerStyle.js";

import { theme } from "../../themes/greyscaleTheme";

export default function DisplayAdBanner(props) {
    return (
        <AdBanner name="AdBanner">
            <img
            src={
            process.env.PUBLIC_URL +
            theme.themePath +
            "/large/page-welcome/panel-ad-placeholder.png"
            }
            alt="Ad Placeholder"
        />
        </AdBanner>
    );
}