import styled from "@emotion/styled";
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const TabletRightBezel = styled("div")`
background-position: center;
background-repeat: no-repeat;
margin-left:auto;
margin-right:10px;
position: relative;
z-index:0;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_right_min}");
  display:block;
  top:58px;
  left:98px;
  width: 175px;
  height: 128px;
  background-size: 175px 128px;
  //transform: rotate(5deg);
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_right_min}");
  display:block;
  top:-169px;
  left:120px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;
  transform: rotate(6deg);
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_right_skewed}");
  display:block;
  top:-382px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;

}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_right_skewed}");
  display:block;
  top:-328px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_right_skewed}");
  display:block;
  top:-160px;
  width: 233px;
  height: 170px;
  background-size: 233px 170px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  background-image: url("${props => props.theme.themePath}${props => props.theme.media.TabletImages.tablet_small_screen_right_skewed}");
  display:block;
  top:-211px;
  width: 315px;
  height: 230px;
  background-size: 315px 230px;
}

`;

export const RightScreen = styled("div")`
position:relative;
padding:30px;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  display:inline-block;
  z-index:10;
  width: 124px;
  height: 74px;
  top: -17px;
  right: 19px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  display:inline-block;
  z-index:10;
  width:164px;
  height:99px;
  top:-34px;
  right:16px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  display:inline-block;
  z-index:10;
  width:159px;
  height:94px;
  top:-31px;
  right:19px;
}

//large 992-1199
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  display:inline-block;
  z-index:10;
  width:159px;
  height:94px;
  top:-31px;
  right:19px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  width:159px;
  height:94px;
  top:-9px;
  right:19px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  width:215px;
  height:124px;
  top:2px;
  right:15px;
}

`;

export const TabletScreenContentRight = styled("img")`
position:relative;
display:inline-block;
z-index:10;
text-align:center;
width:100%;
height:100%;

//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  border-radius:6px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  border-radius:6px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 95%);
  border-radius:6px;
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 95%);
  border-radius:8px;
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 95%);
  border-radius:12px;
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 95%);
border-radius:12px;
}


`;

export const RightControls = styled("div")`

//default
position: relative;
display: inline-block;
opacity:0.7;
z-index: 150;


//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  top: 145px;
  left: 60px;
  width: 100px;
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  top: 120px;
  left: 50px;
  width: 100px;
}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  top: 117px;
  left: 37px;
  width: 100px;
  transform: rotate(2deg);
}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  top: 117px;
  left: 37px;
  width: 100px;
  transform: rotate(2deg);
}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  top: 117px;
  left: 37px;
  width: 100px;
  transform: rotate(2deg);
}

//xxl 1400+
@media (min-width:  ${xxl}px){
  top: 159px;
  left: 75px;
  width: 130px;
  transform: rotate(2deg);
}

`;