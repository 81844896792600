import styled from "@emotion/styled";
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//default
  

  //mobile portrait
  @media (min-width: 360px) { 

  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {

  }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {

  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {

  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {

  }
*/
export const TabletControlsBlock = styled("div")`
  //default
  z-index:1;
  
  padding:1px;
  display:flex;
  flex-direction:row;
  justify-content:center;
  
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    border-radius: 10px;
    width: 120px;
    height: 20px;
  }
  
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    border-radius: 10px;
    width: 120px;
    height: 20px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    border-radius: 10px;
    width: 120px;
    height: 20px;
  }
  
  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    border-radius: 10px;
    width: 120px;
    height: 20px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    border-radius: 10px;
    width: 120px;
    height: 20px;
  }
  
  //xxl 1400+
  @media (min-width:  ${xxl}px){
    border-radius: 12px;
    width: 140px;
    height: 24px;
  }

`;
// export const TabletControlsButton = styled("img")`
// //default

// opacity: 0.3;
// //extra small 0-575
// @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
//   width:13px;
//   height:13px;
// }

// //small 576-767
// @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
//   width:13px;
//   height:13px;
// }
// //medium 768-991
// @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
//   width:14px;
//   height:14px;
// }

// //large 992-1999
// @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
//   width:14px;
//   height:14px;
// }
// //xl 1200-1399
// @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
//   width:14px;
//   height:14px;
// }

// //xxl 1400+
// @media (min-width:  ${xxl}px){
//   width:18px;
//   height:18px;
// }

// `;