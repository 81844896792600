import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";

import { useRecoilState } from "recoil";

import { ButtonContainer, ButtonImg, ButtonText} from "./MenuButtonStyle.js";

import { theme } from "../../themes/greyscaleTheme/index.js";

import { menuConfigRecoilState } from "../../config/state/uiState.js";

import { menuOpenState } from "../../config/state/uiState";

export default function MenuBlueButton(props) {

  const {
  isActive,
  id,
  text,
  title,
  index,
  btnOverImgArray,
  btnClickedImg,
  btnOverAudio,
  clickedState,
  setClickedState,
  handleMouseOverSteam,
  handleMouseOutSteam,
  handleSetPrompt,
  handleSetDefaultPrompt
  } = props

  const btnAudio = useMemo(
    () =>{
      return new Audio(process.env.PUBLIC_URL + theme.themePath + btnOverAudio);
    },[btnOverAudio]
  );

  // const btnAudio = new Audio(
  //   process.env.PUBLIC_URL + theme.themePath + btnOverAudio
  // );

  const imgRef = useRef();
  const imgIdxRef = useRef(0);
  const [imgOverStack] = useState(btnOverImgArray);
  const [menuConfig, setMenuConfig] = useRecoilState(menuConfigRecoilState);
  //const [menuBarOpen, setMenubarOpen] = useRecoilState(menuOpenState);
  const [menuConfigState, setMenuConfigState] = useState(menuConfig);
  //let intervalDown, intervalUp;
  const intervalDown = useRef();
  const intervalUp = useRef();  
  const [menubarOpen,setMenubarOpen] = useRecoilState(menuOpenState);
  
  const openButton = useCallback(
   () => {
    try {
      handleSetPrompt();
      clearInterval(intervalDown);
      btnAudio.play();
      handleMouseOverSteam();
      intervalUp.current = setInterval(() => {
        if (imgIdxRef.current === imgOverStack.length - 1) {
          imgIdxRef.current = imgOverStack.length - 1;
          handleMouseOutSteam();
          clearInterval(intervalUp);
        } else if (imgIdxRef.current < imgOverStack.length) {
          imgIdxRef.current += 1;
        }
        imgRef.current.src =
          process.env.PUBLIC_URL +
          theme.themePath +
          imgOverStack[imgIdxRef.current];
      }, 100);
    } catch (e) {
      console.log("Button: handleMouseOver: Error:\n", e);
    }
},[btnAudio, handleMouseOutSteam, handleMouseOverSteam, handleSetPrompt, imgOverStack]);

const shutButton = useCallback(
  () =>{
    try {
      clearInterval(intervalUp.current);
      intervalDown.current = setInterval(() => {
        if (imgIdxRef.current > 0) {
          imgIdxRef.current -= 1;
        } else if (imgIdxRef.current === 0) {
          imgIdxRef.current = 0;
          clearInterval(intervalDown.current);
        }
        imgRef.current.src =
          process.env.PUBLIC_URL +
          theme.themePath +
          imgOverStack[imgIdxRef.current];
      }, 100);
    } catch (e) {
    console.log("Button: handleMouseOut: Error:\n", e);
    }
  },[imgOverStack]);


  const handleMouseOver = (e) => {
    e.stopPropagation();
    openButton();
  };

  const handleMouseOut = (e) => {
   // e.preventDefault();
    e.stopPropagation();
    shutButton();
    handleSetDefaultPrompt();
    handleMouseOutSteam();
  };

  const addItemClicked = () => {
    setClickedState({
      clickedItems: clickedState.clickedItems.filter(
        (item) => item !== text.toLowerCase()
      ),
    });
  };

  const handleClick = (e) => {
    addItemClicked();
    
    clearInterval(intervalUp.current);
    imgRef.current.src =
      process.env.PUBLIC_URL + theme.themePath +  btnClickedImg;
    setMenubarOpen({menuOpen: false});
      //console.log("menuConfig.configState: ", menuConfig.configState)
  };

  const handleTouchStart = (e) => {
    e.stopPropagation();
    handleSetPrompt();
    try {
      
      clearInterval(intervalDown.current);
      btnAudio.play();
      intervalUp.current = setInterval(() => {
        if (imgIdxRef.current === imgOverStack.length - 1) {
          imgIdxRef.current = imgOverStack.length - 1;
          imgRef.current.click();
          handleMouseOutSteam();
          //shutButton();
          clearInterval(intervalUp);
          return true;
        } else if (imgIdxRef.current < imgOverStack.length) {
          imgIdxRef.current += 1;
          handleMouseOverSteam();
        }
        imgRef.current.src =
          process.env.PUBLIC_URL +
          theme.themePath +
          imgOverStack[imgIdxRef.current];
      }, 100);
    } catch (e) {
      console.log("Button: handleTouchStart: Error:\n", e);
    }
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      handleSetPrompt();
      handleMouseOutSteam();
      setTimeout(shutButton, 1100);
    } catch (e) {
      console.log("Button: handleTouchEnd: Error:\n", e);
    }
  };

  const handleTouchMove = (e) => {
    e.stopPropagation();
  };

  const handleTouchCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
  };

  useEffect(() => {
    //console.log("menuConfig.configState: ", menuConfigState.configState);
    if(menuConfigState.configState.indexOf(index) !== 1){
      //console.log('unwind my animation');
    }

  }, [menuConfigState.configState, index, shutButton]);



  return (
    <ButtonContainer id={`${id}Bg`} name={`${id}Bg`}>
      <ButtonImg
        id={`${id}Img`}
        ref={imgRef}
        onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onTouchCancel={handleTouchCancel}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        alt={title}
        src={process.env.PUBLIC_URL + theme.themePath + imgOverStack[0]}
      />
      <ButtonText>{`${title}`}</ButtonText>
    </ButtonContainer>
  );
}
