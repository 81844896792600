import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from './ProtectedRoute';
import Welcome from "../../pages/Welcome";
import Shares from "../../pages/Shares";
import ShareDetails from "../../pages/ShareDetails";
import About from "../../pages/About";
import Terms from "../../pages/Terms";
import Privacy from "../../pages/Privacy";
import SiteMap from "../../pages/SiteMap";
import Contact from "../../pages/Contact";
import Resources from "../../pages/Resources";
import ResourceDetails from "../../pages/ResourceDetails";
//import ResourceItemDetails from '../../pages/ResourceItemDetails';
import SearchResults from "../../pages/SearchResults";
import SignUp from "../../pages/SignUp";
import Profile from "../../pages/Profile";
import { useAuth } from '../../hooks/useAuth';
import Portal from '../../pages/Portal';

const RouterControl = () => {
  const {isLoading, user} = useAuth();
  return(
    <Routes>
    {/* <Route
      exact
      path="/"
      render={(props) => <Welcome />}
    /> */}

    {/* <Route
      exact
      path="/welcome"
      render={(props) => <Welcome />}
    /> */}
    <Route path="/terms" element={<Terms/>}></Route>
    <Route path="/privacy" element={<Privacy/>}></Route>
    <Route path="/contact" element={<Contact/>}></Route>
    <Route path="/sitemap" element={<SiteMap/>}></Route>
    <Route path="/about" element={<About/>}></Route>
    <Route path="/portal" element={<Portal/>}></Route>
    <Route path="/share/:id" element={<ShareDetails/>}></Route>
    <Route path="/share" element={<Shares/>}></Route>
    <Route path="/resource/:id" element={<ResourceDetails/>}></Route>
    <Route path="/resource" element={<Resources/>}></Route>
    <Route path="/search" element={<SearchResults/>}></Route>
    <Route path="/signup" element={<SignUp/>}></Route>
    {/* <ProtectedRoute isAuthed={!!user} isLoading={isLoading} path="/profile" component={Profile}></ProtectedRoute> */}
    <Route path="/*" element={<Welcome/>}></Route>
    {/* <Route
      path="/*"
      render={(props) => <Welcome />}
    /> */}
  </Routes>
  )
}

export default RouterControl;