import styled from "@emotion/styled";

export const PromptFragment = styled("div")`
  position: relative;

  display: none;
  flex-direction: row;
  align-items: baseline;
  height: 100%;
  @media (min-width: 360px), (max-height: 620px) {
    top: -22px;
    left: -15px;
    margin-bottom: -64px;
    height: 65px;
  }

  @media (min-width: 768px) and (min-height: 621px) {
    top: -26px;
    left: -15px;
    margin-bottom: -40px;
    height: 74px;
  }

  @media (min-width: 1200px) {
    top: -30px;
    left: -15px;
    margin-bottom: -74px;
    height: 80px;
  }
`;
export const PromptArrow = styled("div")`
  background-image: url("${props => props.theme.themePath}/large/menu-toggle/menu-toggle-arrow.svg");
  background-repeat: no-repeat;
  display: flex;
  @media (min-width: 360px), (max-height: 620px) {
    width: 42px;
    height: 62px;
    background-size: 42px 62px;
  }

  @media (min-width: 768px) and (min-height: 621px) {
    width: 52px;
    height: 72px;
    background-size: 52px 72px;
  }

  @media (min-width: 1200px) {
    width: 62px;
    height: 82px;
    background-size: 62px 82px;
  }
`;
export const PromptText = styled("div")`
  display: flex;
  color: ${(props) => props.theme.colors.powder_blue};
  margin-top: auto;
  margin-bottom: 15px;
  @media (min-width: 360px), (max-height: 620px) {
    font-size: 10px;
  }

  @media (min-width: 768px) and (min-height: 621px) {
    font-size: 11px;
  }

  @media (min-width: 1200px) {
    font-size: 12px;
  }
`;
