import React, {useState} from 'react';
import { useRecoilState } from "recoil";
import HorizontalDivide from "../../components/HorizontalDivideBottom";
import { PageContent } from "../PagesStyle";

import {
  basicUserState
} from "../../config/state/uiState";

import {
  SignUpContainer,
  SignUpForm,
  SignUpFormContainer,
} from "./SignUpStyle.js";


const SignUp = () => {

  const [userState, setUserState] = useRecoilState(basicUserState);
  const [displayName, setDisplayName] = useState(userState.displayName);
  const [photoUrl, setPhotoUrl] = useState(userState.photoURL);
  const [bio, setBio] = useState(userState.bio);
  
  const handleUserDetailsInput = e => {
    //console.log({"displayName": displayName, "photoURL": photoUrl, "bio": bio})
      setUserState({"displayName": displayName, "photoURL": photoUrl, "bio": bio});
  };

  return(
    <>
    <PageContent>
      <h2>Sign Up</h2>
      <SignUpContainer>
      
        
        <SignUpFormContainer>
          <SignUpForm>
              <input value={displayName} type="text" onChange={e => setDisplayName(e.target.value)} contentEditable  placeholder="Display Name" />
              <input value={photoUrl} type="text"  onChange={e => setPhotoUrl(e.target.value)}  placeholder="Photo Url" />
              <textarea value={bio} onChange={e => setBio(e.target.value)} placeholder="User Bio" />
              <input type="button" value="Submit" onClick={handleUserDetailsInput} />
          </SignUpForm>
        </SignUpFormContainer>


      </SignUpContainer>
    </PageContent>
    <HorizontalDivide/>
    </>
  
  )
}

export default SignUp;