import React, {useReducer} from 'react';
import MetaHead from "../../components/MetaHead";
//import { Link } from "react-router-dom";
import { useAxiosRequestShareDetails } from '../../hooks/useAxiosRequestShareDetails.js';
import { theme } from "../../themes/greyscaleTheme";
import { getCategoryImage, getCategoryAlt } from '../../utils/getCategoryImage';

import {
  DetailsCard,
  DetailsCardTitle,
  DetailsCardDescription,
  DetailsCardCategoryName,
  DetailsCardUrl,
  DetailsCardPublishDate,
  DetailsCardModifyDate,
  DetailsTitleLink,
  DetailsInfo,
  DetailsCardType,
  DetailsCardReferences,
  DetailsCardCategoryImage
} from '../ShareDetailsStyle';
import Cams from "../../components/Cams";
import { PanelPageRoot, PanelFlexContainer } from "../PanelPageStyle";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";

export default function ShareItemDetails(props) {
  const { id } = props;

  const shareDetailsReducer = (state, action) => {
    switch (action.type) {
      case 'RETURN_SHARE_DETAILS':
        return { ...state, shares: action.shares }
      case 'FETCHING_SHARE_DETAILS':
        return { ...state, fetching: action.fetching }
      default:
        return state;
    }
  }

  // const pageReducer = (state, action) => {
  //   switch (action.type) {
  //     case 'ADVANCE_PAGE':
  //       return { ...state, page: state.page + 1 }
  //     default:
  //       return state;
  //   }
  // }

  //const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 })
  const [shareData, shareDispatch] = useReducer(shareDetailsReducer, { shares: [], fetching: true, })
  useAxiosRequestShareDetails(id, shareDispatch);

  return(
    <article>
      {shareData.shares.map((share, index) => {
        const { slug, acf, modified_gmt, date_gmt  } = share;
        return (
          <>
          <MetaHead
            page="Share Item Details"
            title={`Posted on CoreyLines.com`}
            description={`${acf.share_title}`}
            image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
            />
            <PanelPageRoot id="PanelPageRoot">
              <Cams />
              <DisplayAdBanner/>
              <PanelFlexContainer>
              
              <DetailsCard key={index}>
              <DetailsInfo/>
              <DetailsTitleLink>
                <DetailsCardTitle dangerouslySetInnerHTML={{__html: acf.share_title}} />
              </DetailsTitleLink>
              
                {/* <DetailsCardCategoryImage src={`${process.env.PUBLIC_URL}${theme.themePath}/large/content-categories/${getCategoryImage(acf.share_category)}`} alt={`${getCategoryAlt(acf.share_category)}`} /> */}

                
                <DetailsCardCategoryName dangerouslySetInnerHTML={{__html: getCategoryAlt(acf.share_category)}} />
                <DetailsCardType name="category-type">
                  Post
                </DetailsCardType>
                
                <DetailsCardDescription >
                <div dangerouslySetInnerHTML={{__html: acf.share_content}}/>
                </DetailsCardDescription>
                { acf.share_references && (
                <DetailsCardReferences >
                <div id="label">Source Material / References:</div> 
                <div dangerouslySetInnerHTML={{__html: acf.share_references}}/>
                </DetailsCardReferences>
                )}
                { acf.share_url && (
                  <DetailsCardUrl>
                    {/* <div id="label">Reference Url:</div> */}
                    <a href={acf.share_url} rel="noreferrer" target="_blank" dangerouslySetInnerHTML={{__html: acf.share_url}} />
                  </DetailsCardUrl>
                )}
                <DetailsCardCategoryImage name="category-img">
                      <img
                        src={`${process.env.PUBLIC_URL}${
                          theme.themePath
                        }/large/content-categories/${getCategoryImage(
                          acf.share_category
                        )}`}
                        alt={`${getCategoryAlt(acf.share_category)}`}
                      />
                </DetailsCardCategoryImage>
                <DetailsCardPublishDate dangerouslySetInnerHTML={{__html: `Listed: ${new Date(date_gmt).toDateString()}`}}></DetailsCardPublishDate>
                <DetailsCardModifyDate dangerouslySetInnerHTML={{__html: `Updated: ${new Date(modified_gmt).toDateString()}`}}></DetailsCardModifyDate>

              
            </DetailsCard>
            </PanelFlexContainer>
          </PanelPageRoot>
          </>  
          )
        })}
    </article>
  );
}