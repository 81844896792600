import styled from '@emotion/styled';
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";

/*  Break Point Summary 
breakpoints: {
  xs: 0,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/
export const PlayerButtonBase = styled("div")`


  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    display:inline-block;
    width:18px;
    height:18px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    display:inline-block;
    width:18px;
    height:18px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    display:inline-block;
    width:18px;
    height:18px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    display:inline-block;
    width:18px;
    height:18px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    display:inline-block;
    width:18px;
    height:18px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    display:inline-block;
    width:20px;
    height:20px;
    margin-left:4px;
  }

`;

export const PlayerButtonImg = styled("img")`
//mobile portrait
  width:100%;
  height:100%;
  @media (min-width: 360px) { 

  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {

  }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {

  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {

  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {
  }
`;

export const PlayerButtonHint = styled("span")`
//mobile portrait
  @media (min-width: 360px) { 

  }

  //mobile landscape
  @media (min-width: 768px) and (max-height: 620px) {

  }

  //tablet portrait / landscape
  @media (min-width: 768px) and (min-height: 621px) {

  }

  //tablet landscape
  @media (min-width: 1200px) and (max-height: 620px) {

  }

  //desktop / laptop
  @media (min-width: 1200px) and (min-height: 621px) {

  }
`;