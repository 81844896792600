import { auth, defaultAuthProvider } from '../firebase';

export const signIn = async (back, email, password) => {
  //try{
    //console.log('signIn: ', email);
    //console.log('password: ', password);
    //console.log(auth);
    const result = await defaultAuthProvider(auth, email, password);
    return{};
 //}catch (e){
 //   throw new Error('Error signing in');
 // }
}