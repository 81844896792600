import styled from "@emotion/styled";
import {xs, sm, md, lg, xl, xxl} from "../../app/ResponsiveLayout/ResponsiveBreakpoints";
/*
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}
*/
export const ElementContainer = styled("div")`
margin-bottom: 0px;
  margin-bottom: -1px;
  width: 100%;
  text-align: center;
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

  // margin-bottom: 0px;
  // margin-bottom: -1px;
  // width: 100%;
  // text-align: center;
  // @media (min-width: 768px) {
  // }

  // @media (min-width: 1200px) {
  //   margin-bottom: -1px;
  //   width: 100%;
  //   text-align: center;
  // }
`;

export const ElementImg = styled("img")`
  height: 100%;
  width: 0px;
  transition-property: width;
  will-change: width;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
  }
  
  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
  
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
  
  }
  
  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
  
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
  
  }
`;
