// Import Emotion Styled
import styled from '@emotion/styled';
import {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  } from "../../../app/ResponsiveLayout/ResponsiveBreakpoints";

// Create a custom image component with Emotion Styled
export const BackButtonImg = styled.img`
  // Set the normal image as the source
  width:100%;
  height:100%;
  animation: ease 0.5s;
  transition: 0.5s;
`;