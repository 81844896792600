import {firebaseConfig} from '../config/firebase/firebaseConfig';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc } from 'firebase/firestore';
//import 'firebase/firestore';
//import 'firebase/storage';
import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollectionData} from 'react-firebase-hooks/firestore';

let app = null;

if (!app) {
  app = initializeApp(firebaseConfig);
}

export const auth = getAuth();
export const authListener = onAuthStateChanged; 
export const firestore = getFirestore(app);
export const fireDoc = doc;
//export const storage = firebase.storage();
export const googleAuthProvider = new GoogleAuthProvider();
export const popupSignIn = signInWithPopup;

export const defaultAuthProvider = signInWithEmailAndPassword;
