import { auth } from '../firebase';

export const getCurrentUser = async () => {
  try{
    const user = auth.currentUser;
    
    if(!user) return null;

    return {
      id: user.uid,
    };

  }catch(e){
    throw new Error('Error while getCurrentUser')
  }

}