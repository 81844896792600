import React, { useRef, useEffect } from "react";
import { useRecoilState } from "recoil";
import { menuOpenState } from "../../../config/state/uiState";
import { BurgerButtonImg } from "./BurgerButtonStyle.js";

import { theme } from "../../../themes/greyscaleTheme";

export default function BurgerButton(props) {
  const [menubarOpen, setMenubarOpen] = useRecoilState(menuOpenState);
  const menuBtnRef = useRef();
  const imgBurgerDefault = "/large/menu-toggle/toggle_burger_default.png";
  const imgBurgerOver = "/large/menu-toggle/toggle_burger_over.png";
  const imgCloseDefault = "/large/menu-toggle/toggle_close_default.png";
  const imgCloseOver = "/large/menu-toggle/toggle_close_over.png";

  const toggleMenu = () => {
    if (menubarOpen.menuOpen) {
      //menuBtnRef.current.style.width =  "calc(100%)";
      //menuBtnRef.current.style.height = "calc(100%)";
      setMenubarOpen({ menuOpen: false });
    } else {
      //menuBtnRef.current.style.width =  "calc(50%)";
      //menuBtnRef.current.style.height = "calc(50%)";
      setMenubarOpen({ menuOpen: true });
    }
  };

  useEffect(() => {
    //If the menu is closed
    if (menubarOpen.menuOpen === false) {
      //set the menu button to the open image
      menuBtnRef.current.src =
        process.env.PUBLIC_URL + theme.themePath + imgBurgerDefault;
    } else {
      //set the menu button to the close image
      menuBtnRef.current.src =
        process.env.PUBLIC_URL + theme.themePath + imgCloseDefault;
    }
  }, [menubarOpen.menuOpen, menuBtnRef]);

  return (
    <BurgerButtonImg
      ref={menuBtnRef}
      alt="Open Menu"
      onTouchStart={(e) => {
        !menubarOpen.menuOpen
          ? (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgBurgerDefault)
          : (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgCloseDefault);
      }}
      onTouchEnd={(e) =>
        !menubarOpen.menuOpen
          ? (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgBurgerDefault)
          : (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgCloseDefault)
      }
      onMouseOver={(e) =>
        !menubarOpen.menuOpen
          ? (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgBurgerOver)
          : (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgCloseOver)
      }
      onMouseOut={(e) =>
        !menubarOpen.menuOpen
          ? (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgBurgerDefault)
          : (e.currentTarget.src =
              process.env.PUBLIC_URL + theme.themePath + imgCloseDefault)
      }
      onClick={(e) => toggleMenu()}
      src={process.env.PUBLIC_URL + theme.themePath + imgBurgerDefault}
    />
  );
}
