import React from "react";
import { theme } from "../../themes/greyscaleTheme";
import MetaHead from "../../components/MetaHead";
import Cams from "../../components/Cams";
import { PageSpinner, PageEmpty } from "../PagesStyle";
import { PanelPageRoot, PanelPageContainer, PanelBorderContainer, PanelPageContent } from "../PanelPageStyle.js";
import { PanelAd } from "../PanelStyle.js";

export default function Contact(props, { path }) {
  return (
    <section>
      <MetaHead
        page="Contact"
        title="About Corey Lines"
        description="Contact Information"
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PanelPageRoot>
      <Cams/>
        <PanelAd>
          <img
            src={
              process.env.PUBLIC_URL +
              theme.themePath +
              "/large/page-welcome/panel-ad-placeholder.png"
            }
            alt="Ad Placeholder"
          />
        </PanelAd>
        <PanelPageContainer name="PanelPageContainer">
          <PanelBorderContainer name="PanelBorderContainer">
            <PanelPageContent name="PanelPageContent">  
              <h2>Contact Information</h2>
              <p>Email:</p>
              <p>Facebook:</p>
              <p>Instagram:</p>
              <p>LinkedIn:</p>
              <p>GitHub:</p>
              <p>YouTube:</p>
            </PanelPageContent>
          </PanelBorderContainer>
        </PanelPageContainer>
      </PanelPageRoot>
    </section>
  );
}
