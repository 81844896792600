import { auth, authListener } from '../firebase';

export const addAuthListener =  (callback) => {
  try{
    const onChange = (user) => {
      if(user){
        callback({});
      } else{
        callback(null);
      }
    }

    return authListener(auth, onChange);

  }catch(e){
    throw new Error('Error addAuthListener: ', e)
  }
}