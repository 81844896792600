import { useWindowDimensions } from '../WindowDimensionsProvider';

const ResponsiveLayout = ({renderSmall, renderMedium, renderLarge }) => {
  const { width, height } = useWindowDimensions();

  if(width <= 767){
    return (renderSmall("small"));
  }

  if(width > 767 && width <= 1199){
    if(height < 620){
      return (renderSmall());
    }else if(height >= 620){
      return (renderMedium());
    }
  }

  if(width >= 1200){
    if(height <= 620){
      return (renderMedium());
    }else{
      return (renderLarge());
    }
  }

  return null;
}

export default ResponsiveLayout;
