import { useState, useEffect, useCallback } from "react";
import request from "../utils/request";

export const useRequestList = (data, dispatch, apiUrl, itemCount) => {

  
  const getData = useCallback(async (requestURL, dispatch) => {
    dispatch({ type: "FETCHING_LIST", fetching: true });

    await request({
      method: "get",
      url: `${requestURL}`,
    })
    .then((items) => {
        dispatch({ type: "STACK_LIST", items });
        dispatch({ type: "FETCHING_LIST", fetching: false });
    })
    .catch((e) => {
      console.log("FETCHING_LIST - ERROR");
      dispatch({ type: "FETCHING_LIST", fetching: false });
    });
  },[]);

  useEffect(() => {
      let listUrl = apiUrl;

      if(apiUrl.includes("{{page}}")){
        listUrl = listUrl.replace("{{page}}", data.page)
      }
      
      listUrl = listUrl.replace("{{numResults}}", itemCount);

      console.log("listUrl: ",listUrl)
      getData(listUrl, dispatch);

  }, [data, getData, apiUrl, itemCount]);

};
