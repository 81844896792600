import styled from "@emotion/styled";
//import { keyframes } from "@emotion/react";
import {
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
} from "../app/ResponsiveLayout/ResponsiveBreakpoints";
//import { theme } from "../themes/greyscaleTheme";

export const ListContainer = styled("div")`
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    margin-top: 85px;
    margin-left:22px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    margin-top: 60px;
    margin-left:10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    margin-top: 5px;
    margin-left: 14px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    margin-top: -30px;
    margin-left: 16px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    margin-top: -80px;
    margin-left: 20px;
  }

  //xxl 1400+
  @media (min-width: ${xxl}px) {
    margin-top: -80px;
    margin-left: 24px;
  }

`;

export const ListItemContainer = styled("div")`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  height: 85px;
  padding: 3px 10px 3px 3px;
  margin-bottom: 25px;
  border: rgba(102, 255, 255, .72) solid 3px;
  border-radius: 27px 27px 27px 27px;
  background-color: rgba(255,201,65, 0.1);
  background-repeat: no-repeat;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    background-size: ${xs-25}px 100%;
    width:${xs-30}px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    background-size: ${sm-30}px 100%;
    width: ${sm-30}px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    background-size: ${md-20}px 100%;
    width: ${md-20}px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    background-size: ${lg-50}px 100%;
    width: ${lg-50}px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    background-size: ${lg-50}px 100%;
    width:  ${lg-50}px;
  }

//xxl 1400+
@media (min-width:  ${xxl}px){
background-size: ${lg-50}px 100%;
  width: ${lg-50}px;

}

`;

export const ListItemType = styled("div")`
  position: absolute;
  display:flex;
  flex-direction: row;
  font-family: "ROG";
  align-items: center;
  justify-items: center;
  opacity: 0.13;
  align-content: space-between;
  justify-content: space-around;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
    width: 115px;
    top: 30px;
    height: 17px;
    left: 243px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size:14px;
    width: 175px;
    top: 25px;
    height: 16px;
    left: 569px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 20px;
    width: 934px;
    top: 62px;
    height: 20px;
    left: 7px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 20px;
    width: 934px;
    top: 62px;
    height: 20px;
    left: 7px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    font-size: 20px;
    width: 934px;
    top: 62px;
    height: 20px;
    left: 7px;
  }
`;

export const ListItemCategory = styled("div")`
  position: absolute;
  display: inline-block;
  animation: ease 0.5s;
  transition: 0.5s;
  width: 60px;
  height: 60px;
  top:20px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 50px;
    height: 50px;
    top: 30px;
    left: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 55px;
    height: 55px;
    top: 28px;
    left: 12px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 60px;
    height: 60px;
    top: 26px;
    left:14px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 65px;
    height: 65px;
    top: 14px;
    left: 16px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 65px;
    height: 65px;
    top: 14px;
    left: 16px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 65px;
    height: 65px;
    top: 14px;
    left: 16px;
  }

  img {
    width: 100%;
  }
`;

export const ListItemTitle = styled("div")`
  position: absolute;
  display: inline-block;
  display: grid;
  align-items: center;
  height: 60px;
  text-align: center;
  color: ${(props) => props.theme.colors.yellow};

  a {
    text-decoration: none;
    text-weight: bold;
    color: ${(props) => props.theme.colors.yellow};
  }

  a:hover {
    color: ${(props) => props.theme.colors.powder_blue};
  }

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    font-size: 12px;
    width: 225px;
    left: 53px;
    line-height: 14px;
    top: 15px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    font-size: 14px;
    width: 420px;
    left: 62px;
    line-height: 12px;
    top: 14px;

    
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    font-size: 16px;
    width: 612px;
    left: 70px;
    line-height: 18px;
    top: 14px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 20px;
    font-weight: bold;
    top: 15px;
    width: 875px;
    left: 33px;
    line-height: 20px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 20px;
    font-weight: bold;
    top: 15px;
    width: 875px;
    left: 33px;
    line-height: 20px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    font-size: 20px;
    font-weight: bold;
    top: 15px;
    width: 934px;
    left: 7px;
    line-height: 20px;
  }
`;

export const ListItemTitleText = styled("div")`
  text-overflow: ellipsis;
`;

export const ListItemCategoryName = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  position: absolute;
  display: grid;
  align-items: center;
  text-transform: uppercase;
  font-family: 'ROG';
  opacity: 1;
  color: #66ffff;
  top: 100px;
  height: 12px;

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 236px;
    left: 48px;
    font-size: 10px;
    height: 24px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 382px;
    left: 81px;
    height: 24px;
    font-size: 12px;
  }

  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 719px;
    left: 15px;
  }

  //large 992-1199
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    font-size: 14px;
    opacity: 1;
    width: 875px;
    left: 33px;
  }

  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    font-size: 14px;
    opacity: 1;
    width: 875px;
    left: 33px; 
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    font-size: 14px;
    opacity: 1;
    width: 875px;
    left: 33px;
  }

`;

export const ListItemPublishDateLabel = styled("div")`
animation: ease 0.5s;
  transition: 0.5s;
  position:absolute;
  color: #ffffff;
  opacity: 0.72;
  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 150px;
    top: 72px;
    height: 18px;
    left: 76px;
    font-size: 10px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 150px;
    top: 83px;
    height: 18px;
    left: 114px;
    font-size: 10px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 150px;
    top: 78px;
    height: 18px;
    left: 162px;
    font-size: 13px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 150px;
    top: 78px;
    height: 18px;
    left: 200px;
    font-size: 13px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 150px;
    top: 78px;
    height: 18px;
    left: 200px;
    font-size: 13px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 150px;
    top: 78px;
    height: 18px;
    left: 200px;
    font-size: 13px;
  }
`;


export const ListItemPublishDate = styled("div")`
animation: ease 0.5s;
  transition: 0.5s;
  position:absolute;
  color: #66ffff;
  ${'' /* display: flex;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
  flex-direction: row; */}

  //extra small 0-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 80px;
    top: 83px;
    left: 76px;
    font-size: 10px;
    height: 22px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 80px;
    top: 83px;
    left: 146px;
    font-size: 10px;
    height: 22px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 236px;
    top: 78px;
    height: 18px;
    left: 204px;
    font-size: 13px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 236px;
    top: 78px;
    height: 18px;
    left: 254px;
    font-size: 13px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 236px;
    top: 78px;
    height: 18px;
    left: 254px;
    font-size: 13px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 236px;
    top: 78px;
    height: 18px;
    left: 254px;
    font-size: 13px;
  }
`;

export const ListItemModifyDateLabel = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  position:absolute;
  color: #ffffff;
  opacity: 0.72;
  ${'' /* align-content: space-between;
  justify-content: space-around;
  align-items: center;
  flex-direction: row; */}
  
  //extra small 360-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 130px;
    top: 72px;
    left: 221px;
    font-size: 10px;
    height: 12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 130px;
    top: 83px;
    left: 319px;
    font-size: 10px;
    height: 12px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 150px;
    top: 78px;
    height: 18px;
    left: 441px;
    font-size: 13px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 150px;
    top: 78px;
    height: 18px;
    left: 590px;
    font-size: 13px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 150px;
    top: 78px;
    height: 18px;
    left: 590px;
    font-size: 13px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 150px;
    top: 78px;
    height: 18px;
    left: 590px;
    font-size: 13px;
  }
`;

export const ListItemModifyDate = styled("div")`
  animation: ease 0.5s;
  transition: 0.5s;
  position:absolute;
  color: #66ffff;
  ${'' /* display: flex;
  align-content: space-between;
  justify-content: space-around;
  align-items: center;
  flex-direction: row; */}
  
  //extra small 360-575
  @media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {
    width: 130px;
    top: 83px;
    left: 191px;
    font-size: 10px;
    height: 12px;
  }

  //small 576-767
  @media (min-width: ${sm}px) and (max-width: ${String(md)}px) {
    width: 130px;
    top: 83px;
    left: 361px;
    font-size: 10px;
    height: 12px;
  }
  //medium 768-991
  @media (min-width: ${md}px) and (max-width: ${String(lg)}px) {
    width: 236px;
    top: 78px;
    height: 18px;
    left: 495px;
    font-size: 13px;
  }

  //large 992-1999
  @media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {
    width: 236px;
    top: 78px;
    height: 18px;
    left: 653px;
    font-size: 13px;
  }
  //xl 1200-1399
  @media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {
    width: 236px;
    top: 78px;
    height: 18px;
    left: 653px;
    font-size: 13px;
  }

  //xxl 1400+
  @media (min-width:  ${xxl}px){
    width: 236px;
    top: 78px;
    height: 18px;
    left: 653px;
    font-size: 13px;
  }
`;

export const ListContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  padding-left: 10px;
`;



export const ListFilter = styled("div")`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 5px;
  visibility: hidden;
`;

export const ListItemColumn = styled("div")`
  display: flex;
  flex-direction: column;

`;


export const ListItemImgContainer = styled("div")`
animation: ease 0.5s;
  transition: 0.5s;
  width: 100%;
  display: inline-block;
  text-align: center;


  a {
    display: inline-block;
  }

  @media (min-width: 768px) {
    max-width: 150px;
    width: 150px;
      }

  @media (min-width: 1200px) {
    max-width: 175px;
    width: 175px;

  }
`;

export const ListItemImg = styled("img")`
  max-width: 4rem;
  max-height: 2rem;
  display: inline-block;
  @media (min-width: 768px) {
    max-width: 5rem;
    max-height: 3rem;
  }
`;



export const ResourcesEmpty = styled("div")`
  text-align: center;
  font-family: "ROG";
  font-size: 14px;
  display: block;
  height: 50vh;
  margin: auto;
  padding: 70px 0;
`;




/*  Break Point Summary 
breakpoints: {
  xs: 360,
  sm: 576,
  med: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
},

export const TestMenuContainer = styled("div")`
//extra small 0-575
@media (min-width: ${xs}px) and (max-width: ${String(sm)}px) {

}

//small 576-767
@media (min-width: ${sm}px) and (max-width: ${String(md)}px) {

}
//medium 768-991
@media (min-width: ${md}px) and (max-width: ${String(lg)}px) {

}

//large 992-1999
@media (min-width: ${lg}px) and (max-width: ${String(xl)}px) {

}
//xl 1200-1399
@media (min-width: ${xl}px) and (max-width: ${String(xxl)}px) {

}

//xxl 1400+
@media (min-width:  ${xxl}px){

}

`;
*/