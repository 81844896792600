import styled from "@emotion/styled";

export const SignUpContainer = styled("div")`
  display:flex;
  border:solid 1px #ffffff;

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`;

export const SignUpDisplayImage = styled("div")`

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`;


export const SignUpFormContainer = styled("div")`
display:flex;
flex-direction:column;
  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`;


export const SignUpForm = styled("form")`
  display:flex;
  flex-direction:column;
  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
`;
