import styled from "@emotion/styled";

/*
export const AboutEntry = styled("div")`
  display: flex;
  flex-direction: column-reverse;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    flex-direction: ${(props) => (props.rowMod === 1 ? "row-reverse" : "row")};
    width: 42rem;
  }
  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
    width: 53rem;
  }
`;

export const AboutImg = styled("div")`
  text-align: center;
  display: block;
  width: 20rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media (min-width: 768px) {
    margin-left: ${(props) => (props.rowMod === 1 ? "10px" : "auto")};
    margin-right: ${(props) => (props.rowMod === 1 ? "auto" : "10px")};
    margin-top: 45px;
    flex-direction: column;
    width: 21rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
    width: 26rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;
*/
export const SocialLinksContainer = styled('div')`
position: absolute;
padding-bottom:15px;

//mobile portrait
@media (min-width: 360px) {

  top: 329px;
  left: 0px;
}

//mobile landscape
@media (min-width: 768px) and (max-height: 620px) {
  top: 629px;
  left: 19px;
}
//tablet portrait / landscape
@media (min-width: 768px) and (min-height: 621px) {
  top: 633px;
  left: 22px;
}
//tablet landscape
@media (min-width: 1200px) and (max-height: 620px) {
  top: 742px;
  left: 237px;
}
//desktop / laptop
@media (min-width: 1200px) and (min-height: 621px){
  top: 1040px;
  left: 139px;
}
`