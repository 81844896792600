import React, { useEffect} from 'react';
import axios from 'axios';
import { appConfig } from '../../config/data/appConfig.js';

export const withImagesComponent = (Component) => {
  return props => {
    const imageList = [];
    
    useEffect(() => {
      (async () => {
            const response = await axios.get(appConfig.apiUrls.withImagesList);
            response.data.map((image, index) => {
              imageList[index] = {
                "title": image.acf.title,
                "sourceUrl": image.acf.url,
                "description": image.acf.description,
                "altText": image.acf.alt_text,
                "thumbnailPath": image.acf.image.sizes["thumbnail"],
                "thumbnailWidth": image.acf.image.sizes["thumbnail-width"],
                "thumbnailHeight": image.acf.image.sizes["thumbnail-height"],
                "mediumPath": image.acf.image.sizes.medium,
                "mediumWidth": image.acf.image.sizes["medium-height"],
                "mediumHeight": image.acf.image.sizes["medium-height"],
                "largePath": image.acf.image.sizes.large,
                "largeWidth": image.acf.image.sizes["large-height"],
                "largeHeight": image.acf.image.sizes["large-height"],
              };
            });
        })();
    },[]);

    return <Component {...props} images={imageList} />
  }
}