import React, { useRef, useReducer } from "react";
import { Link } from "react-router-dom";
import { useRequestList } from "../../hooks/useRequestList.js";
import { appConfig } from "../../config/data/appConfig.js";
import MetaHead from "../../components/MetaHead";
import Cams from "../../components/Cams/";
import DisplayAdBanner from "../../components/DisplayAdBanner/index.js";
import ContentBox from "../../components/ContentBox";
import { theme } from "../../themes/greyscaleTheme";
import { fadeIn } from "../../uix/animation/keyframes";
import { css } from "@emotion/react";
import { PageSpinner, PageEmpty } from "../../pages/PagesStyle";
import { PanelPageRoot, PanelFlexContainer} from "../PanelPageStyle";
import { ContentContainer, ContentTitle, ContentBody, ContentGraphic, ResourceContent, PostContent, AboutContent} from "./WelcomeStyle";
import { MiniListItem, ListContent, ListContainer, ListItemTitle } from "../../components/MiniList/MiniListStyle";
import {pageReducer, listReducer} from "../reducer.js";

export default function Welcome(props, { path }) {
  const spinnerRef = useRef();
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 });

  const [resourceData, resourceDispatch] = useReducer(listReducer, {
    items: [],
    fetching: true,
  });

  const [postData, postDispatch] = useReducer(listReducer, {
    items: [],
    fetching: true,
  });

  useRequestList(
    pager,
    resourceDispatch,
    appConfig.apiUrls.resourcesListRaw,
    8
  );
  useRequestList(
    pager, 
    postDispatch, 
    appConfig.apiUrls.shareList, 
  8);

  return (
    <>
      <MetaHead
        page="Welcome"
        title="Welcome to coreylines.com"
        description="Welcome to my personal collection of posts and resources."
        image="https://www.coreylines.com/themes/greyscaleTheme/preview.jpg?13-10-21"
      />
      <PanelPageRoot id="PanelPageRoot">
        {/* {<AnimatedBackground />} */}
        <Cams />
        <DisplayAdBanner/>
        <PanelFlexContainer>
        <ContentBox>
            <ContentContainer bgColor={`rgba(102,255,255, 0.1)`}>
              <ContentTitle>Welcome</ContentTitle>
              <ContentBody>
                <p>
                  Welcome to my collection of resources and posts. 
                </p>
                <p>
                  As a technology enthusiast, I am deeply passionate about a wide range of topics, 
                  spanning science, technology, and society.<br/><br/>From philosophy to sociology, and from programming to quality engineering.<br/><br/>Expect to find information on a variety of
                  topics...
                </p>
              </ContentBody>
              <ContentGraphic>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    theme.themePath +
                    "/large/page-welcome/avatar.png"
                  }
                  alt="Corey"
                />
              </ContentGraphic>
            </ContentContainer>
        </ContentBox>
        <ContentBox>
          <ContentContainer bgColor={`rgba(0,0,255, 0.1)`}>
          <ContentTitle>Resources</ContentTitle>
          <ResourceContent name="WelcomeResources">
                  <ListContent>
                    {resourceData.items.length === 0 && (
                      <PageEmpty>Fetching...</PageEmpty>
                    )}
                    <ListContainer>
                      {resourceData.items.map((item, index) => {
                        const { slug, acf, modified_gmt, date_gmt } = item;
                        return (
                          <MiniListItem key={index} id={`ListRow${index}`}>
                            <ListItemTitle>
                              <Link to={`/resource/${slug}`}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: acf.resource_title,
                                  }}
                                />
                              </Link>
                            </ListItemTitle>
                          </MiniListItem>
                        );
                      })}
                      <MiniListItem>
                        <ListItemTitle>
                          <Link to={`/resource/`}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: "more...",
                              }}
                            />
                          </Link>
                        </ListItemTitle>
                      </MiniListItem>
                    </ListContainer>
                    {resourceData.fetching && (
                      <PageSpinner
                        ref={spinnerRef}
                        css={css`
                          animation: ${fadeIn} 3s ease infinite;
                        `}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/themes/greyscaleTheme/spinner/new-spinner.gif"
                          }
                          alt="Spinner"
                        />
                      </PageSpinner>
                    )}
                    {resourceData.items.length === 0 && (
                      <PageEmpty>Fetching...</PageEmpty>
                    )}
                  </ListContent>
          </ResourceContent>
          </ContentContainer>
        </ContentBox>
        <ContentBox>
          <ContentContainer bgColor={`rgba(255,0,0, 0.1)`}>
            <ContentTitle>Posts</ContentTitle>
            <PostContent name="WelcomePosts">
                <ListContent>
                  {postData.items.length === 0 && (
                    <PageEmpty>Fetching...</PageEmpty>
                  )}
                  <ListContainer>
                    {postData.items.map((item, index) => {
                      const { slug, acf, modified_gmt, date_gmt } = item;
                      return (
                        <MiniListItem 
                          key={index} 
                          id={`ListRow${index}`}
                          bgColor={props.bgColor}>
                          <ListItemTitle>
                            <Link to={`/share/${slug}`}>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: acf.share_title,
                                }}
                              />
                            </Link>
                          </ListItemTitle>
                        </MiniListItem>
                      );
                    })}
                    <MiniListItem>
                      <ListItemTitle>
                        <Link to={`/share/`}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: "more...",
                            }}
                          />
                        </Link>
                      </ListItemTitle>
                    </MiniListItem>
                  </ListContainer>
                  {postData.fetching && (
                    <PageSpinner
                      ref={spinnerRef}
                      css={css`
                        animation: ${fadeIn} 3s ease infinite;
                      `}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          theme.themePath +
                          theme.media.MiscImages.spinner
                        }
                        alt="Spinner"
                      />
                    </PageSpinner>
                  )}
                  {postData.items.length === 0 && (
                    <PageEmpty>Fetching...</PageEmpty>
                  )}
                </ListContent>
            </PostContent>
            </ContentContainer>
        </ContentBox>
        <ContentBox>
        <ContentContainer bgColor={`rgba(102,255,255,0.1)`}>
        <ContentTitle>About</ContentTitle>
            <AboutContent>
              <p>
              Whether a fellow technology enthusiast or a curious reader, I hope you find value in what I share. Utimately it is a collection of information that I find useful, so I share it in case others find it helpful also.
              
              <br/><br/>I personally oversee every aspect of this website; from design to database management, to content, code and hosting. If you encounter any issues or discover bugs, don’t hesitate to reach out.
              </p>
              <p>
              Your feedback and suggestions are always welcome.<br/><br/>Thank you!
              </p>
            </AboutContent>
          </ContentContainer>
        </ContentBox>
        </PanelFlexContainer> 
      </PanelPageRoot>
      
    </>
  );
}
