import React from "react";
import Helmet from "react-helmet";

export default function MetaHead(props) {
  const { title, description, image, url } = props
  return(
    <Helmet>
      {/* Essential tags*/}
      <title>{title}</title>
      <meta name="description" content={description}></meta>
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={description}></meta>
      <meta property="og:image" content={image}></meta>
      <meta property="og:url" content={url}></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta property="og:site_name" content={description}></meta>
      <meta property="fb:app_id" content="527429944900797"></meta>

      {/* Non Essential tags, but recommended
      <meta property="og:site_name" content="Welcome to CoreyLines.com"></meta>
      <meta name="twitter:image:alt" content="Alt text for image"></meta>
      */}
      {/* Non Essential tags, but needed for analytics
      <meta property="fb:app_id" content="your_app_id" ></meta>
      <meta name="twitter:site" content="@website-username"></meta>
    */}
    </Helmet>
  )
}