import React, { useRef } from "react";
import { SearchButtonImg } from "./SearchButtonStyle.js";

import { theme } from "../../../themes/greyscaleTheme";

export default function SearchButton(props) {
  const forwardBtnRef = useRef();
  const imgSearchDefault = "/large/menu-toggle/toggle_search_default.png";
  const imgSearchOver = "/large/menu-toggle/toggle_search_over.png";

  return (
    <SearchButtonImg
      ref={forwardBtnRef}
      alt="Search Site"
      onTouchStart={(e) => e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgSearchOver }
      onTouchEnd={(e) =>e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgSearchDefault }
      onMouseOver={(e) =>  e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgSearchOver }
      onMouseOut={(e) => e.currentTarget.src = process.env.PUBLIC_URL + theme.themePath + imgSearchDefault }
      src={process.env.PUBLIC_URL + theme.themePath + imgSearchDefault}
    />
  );
}
